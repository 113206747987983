import React, { useEffect, useRef, useState, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useLocation } from "@reach/router";

import Credits from "../Credits/Credits";
import { Error404Content } from "../../pages/Error404/Error404";
import useGameState from "../../hooks/useGameState";
import make_sketch from "../../scripts/make_sketch";
import { easing } from "../../styles";

import JoiningBoard from "./gameBoard/JoiningBoard.jsx";
import PlayingBoard from "./gameBoard/PlayingBoard.jsx";
import GameOverBoard from "./gameBoard/GameOverBoard.jsx";

const DefaultWrapper = styled.div`
    cursor: ${(props) => (props.highlighting ? "pointer" : "inherit")};
    width: ${(props) => (props.shouldShrink ? "calc(100% - 380px)" : "100%")};
    background: white;
    flex: 1;
`;

const DelayedWrapper = styled.div`
    height: 100%;
    width: ${(props) => (props.shouldShrink ? "calc(100vw - 380px)" : "100vw")};
    transition: 0.3s width ${easing.easeOutCubic};
    position: relative;
    overflow: hidden;
`;

const StaticWrapper = styled.div``;

const ErrorContainer = styled.div`
    margin-top: 50px;
    padding: 0px 15px 15px;
    width: 100%;
`;

const GameBoard = ({ isSandbox, isRender, room, Wrapper }) => {
    const wrapperRef = useRef();
    const gameState = useGameState();
    const { sidebarActive } = gameState;
    const [sketch, setSketch] = useState(null);
    const BoardWrapper = Wrapper || DefaultWrapper;
    const { pathname } = useLocation();

    useEffect(() => {
        const initialState = {
            wrapper: wrapperRef.current,
            isSandbox,
            pathname,
            room,
        };

        const newSketch = make_sketch(initialState);
        setSketch(() => newSketch); // setState requires this weird formatting because of lazy initialization w functions
    }, [Wrapper, pathname, isSandbox, room]);

    const boardProps = {
        sketch,
        isSandbox,
        gameState,
        wrapperRef,
        isRender,
    };

    const renderPhase = useCallback(
        (phase) => {
            switch (phase) {
                case "join":
                    return <JoiningBoard gameState={gameState} />;
                case "game_over":
                    return <GameOverBoard {...boardProps} />;
                default:
                    return <PlayingBoard {...boardProps} />;
            }
        },
        [boardProps]
    );

    const AnimatedWrapper = !isSandbox ? DelayedWrapper : StaticWrapper;
    const shouldShrink =
        sidebarActive && gameState.roomInfo.phase === "playing";

    return (
        <BoardWrapper
            ref={wrapperRef}
            highlighting={gameState.highlightedPlayer !== null}
            shouldShrink={shouldShrink}
        >
            <AnimatedWrapper shouldShrink={shouldShrink}>
                {gameState.badRoom ? (
                    <ErrorContainer>
                        <Error404Content badRoom />
                    </ErrorContainer>
                ) : (
                    renderPhase(gameState.roomInfo.phase)
                )}
                {!isSandbox && !isRender && <Credits />}
            </AnimatedWrapper>
        </BoardWrapper>
    );
};

GameBoard.propTypes = {
    Wrapper: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    isSandbox: PropTypes.bool,
    isRender: PropTypes.bool,
    room: PropTypes.string, // eslint-disable-line
};

GameBoard.defaultProps = {
    isSandbox: false,
};

export default GameBoard;
