import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Link } from "@reach/router";

import { colors } from "../../../styles";

const ContentWrapper = styled.div`
    background: white;
    text-align: center;
    padding: 0 20px;
    width: 100%;
    max-width: 440px;
    margin: 90px auto;

    @media screen and (min-width: 768px) {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

const CodeSpan = styled.span`
    font-family: monospace;
    display: inline-block;
    background: ${colors.grayLight};
`;

const PlayersWrapper = styled.div`
    border: 1px solid black;
    margin: 0 auto;
    padding: 18px;
    margin: 36px auto;
`;

const PlayersHeader = styled.h3`
    margin: 0 0 12px;
`;

const PlayerName = styled.p`
    color: ${(props) => props.color};
    font-weight: bold;
    font-size: 14px;
    margin: 4px 0 0;
    font-size: 18px;
`;

const JoiningBoard = ({ gameState }) => {
    const { players } = gameState;

    return (
        <ContentWrapper>
            <h1>New game start!</h1>
            <p>
                This is the join phase. Once at least two players have joined,
                the admin may begin the game at any time.
            </p>
            <PlayersWrapper>
                <PlayersHeader>Players in the game:</PlayersHeader>
                {players && players.length > 0
                    ? players.map((player) => {
                        const color = `hsl(${player.hue}, ${
                            player.hue < 170 ? "60%" : "90%"
                        }, 50%)`;

                        return (
                            <PlayerName color={color} key={player.id}>
                                {player.name}
                            </PlayerName>
                        );
                    })
                    : "None. Rally the troops!"}
            </PlayersWrapper>
            <h3>What now?</h3>
            <p>
                <strong>Players: </strong>to join the game, enter this command
                in Discord: <CodeSpan>/join</CodeSpan>
            </p>
            <p>
                <strong>Admin: </strong>to start the game, enter this command in
                Discord: <CodeSpan>/start</CodeSpan>
            </p>
            <p>
                Read the <Link to="/rules">Rules</Link> for more info.
            </p>
        </ContentWrapper>
    );
};

JoiningBoard.propTypes = {
    gameState: PropTypes.object,
};

export default JoiningBoard;
