import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/core";

const flash = keyframes`
    to { opacity: 1.0; } 
`;

const BarContainer = styled.div`
    width: 100%;
    height: 5px;
    margin: 4px 0px;
    color: ${(props) => props.color};
    border: 1px solid ${(props) => props.color};
    position: relative;
`;

const Progress = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: ${(props) => (props.progress / props.maximum) * 100}%;
    height: 100%;
    background-color: ${(props) => props.color};
    z-index: 2;
    opacity: 0.2;
    animation: ${flash} 1s cubic-bezier(0.5, 0.5, 0.5, 1) infinite alternate;
`;

const PrevProgress = styled(Progress)`
    opacity: 1;
    z-index: 3;
`;

const ProgressBar = ({ prevProgress, progress, maximum, color }) => {
    return (
        <BarContainer color={color}>
            <PrevProgress
                color={color}
                progress={prevProgress}
                maximum={maximum}
            />
            <Progress
                color={color}
                progress={progress}
                maximum={maximum}
            />
        </BarContainer>
    );
};

ProgressBar.propTypes = {
    color: PropTypes.string,
    prevProgress: PropTypes.number,
    progress: PropTypes.number,
    maximum: PropTypes.number,
};

export default ProgressBar;
