import React from "react";
import styled from "@emotion/styled";

const Wrapper = styled.div`
    margin: 0 0 30px;
`;

const H1 = styled.h1`
    margin: 0 0 5px;
`;

const Header = () => {
    return (
        <Wrapper>
            <H1>Sandbox</H1>
        </Wrapper>
    );
};

export default Header;
