import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import Nav from "../Nav/Nav";

const DefaultWrapper = styled.div`
    background: white;
    width: 100%;
    min-height: 100vh;
`;

const Content = styled.main`
    padding: 60px 20px 0;

    @media screen and (min-width: 768px) {
        padding-top: 70px;
    }
`;

const Page = ({ children, wrapContent, Wrapper }) => {
    const PageWrapper = Wrapper || DefaultWrapper;
    return (
        <PageWrapper>
            <Nav />
            {wrapContent ? <Content>{children}</Content> : children}
        </PageWrapper>
    );
};

Page.propTypes = {
    Wrapper: PropTypes.object,
    wrapContent: PropTypes.bool,
};

Page.defaultProps = {
    wrapContent: true,
};

export default Page;
