import territory_manager from "./territory_manager.js";

const supply_id = "REF";
const supply_id_old = "SUP";

function set_turn_data(gs, grid_info) {
    console.log(`set map: ${gs.base_map_data.length}`);

    // Players

    // move all of the base player data into the array just for this turn
    gs.players = [...gs.base_player_data];

    // add the stats to the player data
    gs.players.forEach((player, i) => {
        player.stats = {};

        gs.player_stats.forEach((stat) => {
            if (player.id == stat.id) {
                player.stats = stat;
            }
        });
    });

    // This no longer matters now that players cannot join mid-game
    // if we did not find stats for a given player, that means they were not in the game and we can bump them
    // for (let i = gs.players.length - 1; i >= 0; i--) {
    //     if (gs.players[i] && gs.players[i].stats == null) {
    //         gs.players.splice(i, 1);
    //     }
    // }

    // sort on being alive, resigned and score
    gs.players.sort(function (a, b) {
        if (!a.stats) {
            return -1;
        }

        if (
            (a.stats.is_dead || a.resigned) == (b.stats.is_dead || b.resigned)
        ) {
            if (a.stats.victory_points == b.stats.victory_points) {
                return b.stats.territory_count - a.stats.territory_count;
            }
            return b.stats.victory_points - a.stats.victory_points;
        }
        return a.stats.is_dead || a.resigned ? 1 : -1;
    });

    // Territories

    if (gs.base_map_data.length == 0) {
        console.log("No map data, can't establish territories.");
        gs.no_map_data = true;
        return;
    }

    // console.log(base_map_data)
    gs.territories = [];
    for (let i = 0; i < gs.base_map_data.length; i++) {
        const base_ter = territory_manager.new_territory_from_json(
            gs.base_map_data[i],
            grid_info.hexes
        ); // TODO: this could probably just happen once when you first get the base territories
        const cur_ter = territory_manager.get_territory_from_id(
            base_ter.id,
            gs.map_data
        );
        const player = get_player_from_id(cur_ter.owner, gs.players);
        const order = territory_manager.get_order_for_territory(
            base_ter.id,
            gs.orders
        );

        // combine the base territory and cur_territory info
        const this_ter = {
            ...base_ter,
            ...cur_ter,
        };

        this_ter.order = order;
        this_ter.player = player;

        // make sure all of the hexes in here know who they belong to
        for (let h = 0; h < this_ter.hexes.length; h++) {
            this_ter.hexes[h].territory = this_ter;
        }

        gs.territories.push(this_ter);
    }

    //go through all territories and if they have an overseas connection, mark it
    gs.territories.forEach((ter) => {
        if (ter.oversea_neighbor_id) {
            ter.oversea_neighbor = territory_manager.get_territory_from_id(
                ter.oversea_neighbor_id,
                gs.territories
            );
        }
    });

    // sorting the raw orders data alphabetically by territory and then by invalid and then by timestamp so newer orders are first
    gs.orders.sort(function (a, b) {
        if (a.territory == b.territory) {
            // return b.timestamp._seconds - a.timestamp._seconds
            if (a.valid == b.valid) {
                return b.timestamp._seconds - a.timestamp._seconds;
            }
            return a.valid ? 1 : -1;
        }
        if (a.territory > b.territory) {
            return 1;
        }
        return -1;
    });

    // go through and kill any invalid orders that exceed a maximum so player's can't flood the sidebar with bad orders
    const max_invalid_order_count = 2;
    let last_order_ter = "";
    let cur_invalid_count = 0;
    for (let i = gs.orders.length - 1; i >= 0; i--) {
        if (gs.orders[i].territory != last_order_ter) {
            last_order_ter = gs.orders[i].territory;
            cur_invalid_count = 0;
        }

        if (gs.orders[i].valid == false) {
            console.log("order invalid flag "+gs.orders[i].invalid_flag)
            //don't show replaced orders
            if (gs.orders[i].invalid_flag == "replaced"){
                gs.orders.splice(i, 1);
            }
            else{
                cur_invalid_count++;
                if (cur_invalid_count > max_invalid_order_count) {
                    gs.orders.splice(i, 1);
                }
            }
        }
    }

    // orders require some more info. This must be done after all territories have been otherwise setup
    for (let i = 0; i < gs.territories.length; i++) {
        const { order } = gs.territories[i];
        if (order != null) {
            if (order.valid) {
                if (order.failed_hold) {
                    let ter = territory_manager.get_territory_from_id(
                        order.territory,
                        gs.territories
                    );
                    ter.was_conflict = true;
                }

                if (order.orig_type == "move") {
                    order.target_ter = territory_manager.get_territory_from_id(
                        order.move_target,
                        gs.territories
                    );

                    order.source_ter = territory_manager.get_territory_from_id(
                        order.territory,
                        gs.territories
                    );

                    //if a move order failed, we still want to mark that there was conflict
                    if (
                        !order.was_successful &&
                        !order.would_have_swapped_places
                    ) {
                        order.target_ter.was_conflict = true;
                    }

                    //if it went through, have the territory know about it
                    if (order.was_successful) {
                        order.target_ter.successful_move_order = order;
                    }
                }

                if (order.orig_type == "support") {
                    if (order.support_type == "hold") {
                        order.supported_ter = territory_manager.get_territory_from_id(
                            order.supported_territory,
                            gs.territories
                        );
                    }

                    if (order.support_type == "move") {
                        order.is_supply_support = false;
                        let supported_ter = territory_manager.get_territory_from_id(
                            order.support_source,
                            gs.territories
                        );
                        if (order.support_source == supply_id || order.support_source == supply_id_old) {
                            supported_ter = territory_manager.get_territory_from_id(
                                order.support_target,
                                gs.territories
                            );
                            order.is_supply_support = true;
                        }
                        const target_ter = territory_manager.get_territory_from_id(
                            order.support_target,
                            gs.territories
                        );

                        order.supported_ter = supported_ter;
                        order.target_ter = target_ter;
                    }
                }
            }
        }
    }

    //check for supply orders and store them in the appropriate territory so we can show the right icons
    gs.orders.forEach((order) => {
        if (order.supply_order) {
            // console.log("SUPPLY ORDER");
            // console.log(order);
            let target_ter = territory_manager.get_territory_from_id(
                order.move_target,
                gs.territories
            );

            //make sure we actually found something
            if (target_ter != null){
                target_ter.supply_order = order;

                //if it failed, mark that there was conflict
                if (!order.was_successful && !order.invalid_reason){
                    target_ter.was_conflict = true;
                }
            }

        }
    });

    // store the turn num
    gs.cur_turn = gs.territories[0].turn_num;

    if (gs.first_load) {
        gs.first_load = false;
        //gs.newest_turn_num = gs.territories[0].turn_num;
        gs.newest_turn_num = gs.room_info.cur_turn;
    }
    
    // set the turn num at the top of the page
    // TODO: this should be react - I think you can kill this
    // let turn_num_html = "";
    // if (!gs.is_sandbox) {
    //     if (gs.cur_turn > 0) {
    //         turn_num_html +=
    //             "<a onclick='load_first_turn();return false' href='linkasnormal'><<</a>  <a onclick='load_prev_turn();return false' href='linkasnormal'><</a> ";
    //     } else {
    //         turn_num_html += "<<  < ";
    //     }
    // }
    // turn_num_html += `Turn: ${gs.territories[0].turn_num} `;
    // if (!gs.is_sandbox) {
    //     if (gs.cur_turn < gs.newest_turn_num) {
    //         turn_num_html +=
    //             "<a onclick='load_next_turn();return false' href='linkasnormal'>></a>  <a onclick='load_last_turn();return false' href='linkasnormal'>>></a>";
    //     } else {
    //         turn_num_html += ">  >>";
    //     }
    // }
    // $("#turn_num_text").html(turn_num_html);

    gs.need_to_print_order_text = true;
}

function get_player_from_id(id, players) {
    if (players) {
        for (let i = 0; i < players.length; i++) {
            if (players[i].id == id) {
                return players[i];
            }
        }
    }
    return null;
}

export default {
    set_turn_data,
    get_player_from_id,
};
