import data_pull from "./data_pull.js";
import draw_map from "./draw_map.js";
import dom_manipulation from "./dom_manipulation.js";
import hex_manager from "./hex_manager.js";
import territory_manager from "./territory_manager.js";
import data_process from "./data_process.js";
import ui_bridge from "./ui_bridge.js";

//mousing over stuff
let mouse_info = {
    //highlighting
    highlighted_player: null,
    prev_highlighted_player: null,
    highlighted_territory: "NONE",
    last_mouse_hex: null,
    //mouse
    mouse_over_timer: 0,
    mouse_over_time_to_center_map: 0.3, //should this be here?
    cur_moused_over_player: null,
    last_centered_player: null,
};

// animations
let winner_color = null;

// time info
let has_time_info = false;
let next_res_time = null;

let prev_millis = 0;
let delta_time = 0;

let sandbox_resolve_timer = 0;
let show_grid_info_timer = 90; // seconds

// moving around
const canvas_center = { x: 0, y: 0 };
const cam_pos = { x: 0, y: 0 };
let zoom = 1;
const mouse_grip_pnt = { x: 0, y: 0 };

//pinch zooming
let start_pinch_dist = 0;
let cur_pinch_dist = 0;
let start_cam_zoom = zoom;

// camera animation
let doing_cam_anim = false;
const cam_anim_time = 1;
let cam_anim_timer = 0;
let cam_start_pos;
let cam_target_pos;
let cam_start_zoom;
let cam_target_zoom;

let can_drag_map = false;

const max_zoom = 1.6;
let min_zoom = 0.2;

let mouse_on_canvas = false;

const map_movement_timer_reduction = 0.2;

// creating a div element to trigger screenshots
let screenshot_timer = 10; // frames

// dealing with mobile
let on_mobile = false;

//THIS STUFF DISABLES PINCH ZOOM - THERE IS DEF A BETTER WAY TO DO IT
//TODO: remove this nonsense
document.addEventListener("gesturestart", function (e) {
    e.preventDefault();
    // special hack to prevent zoom-to-tabs gesture in safari
    document.body.style.zoom = 0.99;
});
document.addEventListener("gesturechange", function (e) {
    e.preventDefault();
    // special hack to prevent zoom-to-tabs gesture in safari
    document.body.style.zoom = 0.99;
});
document.addEventListener("gestureend", function (e) {
    e.preventDefault();
    // special hack to prevent zoom-to-tabs gesture in safari
    document.body.style.zoom = 0.99;
});

function set_p5_functions({
    p,
    icons,
    game_state,
    grid_info,
    props,
    finished_with_load_function,
    file_drop_function,
}) {
    p.preload = () => {
        let path = "/pic/";

        //don't need to load a font if we're using the default serif font
        //icons.font = p.loadFont(`${path}font/Unna-Bold.ttf`);

        icons.army_hold = p.loadImage(`${path}army/army_hold.png`);
        icons.army_move = p.loadImage(`${path}army/army_move.png`);
        icons.army_move_fail = p.loadImage(`${path}army/army_moveFail.png`);
        icons.army_deployed = p.loadImage(`${path}army/army_deployed.png`);
        icons.army_victory = p.loadImage(`${path}army/army_victory.png`);
        icons.army_defeat = p.loadImage(`${path}army/army_defeated.png`);
        icons.army_support = p.loadImage(`${path}army/army_support.png`);
        icons.army_support_cut = p.loadImage(`${path}army/army_supportCut.png`);
        icons.army_build = p.loadImage(`${path}army/army_build.png`);
        icons.army_farm = p.loadImage(`${path}army/farm_success.png`);
        icons.army_farm_cut = p.loadImage(`${path}army/farm_fail.png`);

        icons.attack_overlay = p.loadImage(`${path}overlays/attackOverlay.png`);
        icons.bounce_overlay = p.loadImage(`${path}overlays/bounce.png`);

        icons.dead_icon = p.loadImage(`${path}overlays/tag_armyRemoved.png`);   //kill me

        icons.farm_icon = p.loadImage(`${path}icons/farm.png`);
        icons.supply_point_icon = p.loadImage(
            `${path}icons/supplyPointIdle.png`
        );
        icons.supply_point_success = p.loadImage(
            `${path}icons/supplyPointSuccess.png`
        );
        icons.supply_point_fail = p.loadImage(
            `${path}icons/supplyPointFail.png`
        );

        //arrays of patterns
        icons.stamp_patterns = [];
        icons.stamp_farm_patterns = [];

        //normal patterns
        icons.stamp_patterns.push( p.loadImage(`${path}patterns/angles_plain.png`) );
        icons.stamp_patterns.push( p.loadImage(`${path}patterns/diamonds_plain.png`) );
        icons.stamp_patterns.push( p.loadImage(`${path}patterns/dots_plain.png`) );
        icons.stamp_patterns.push( p.loadImage(`${path}patterns/exes_plain.png`) );
        icons.stamp_patterns.push( p.loadImage(`${path}patterns/feathers_plain.png`) );
        icons.stamp_patterns.push( p.loadImage(`${path}patterns/horz_plain.png`) );
        icons.stamp_patterns.push( p.loadImage(`${path}patterns/marsh_plain.png`) );
        icons.stamp_patterns.push( p.loadImage(`${path}patterns/trees_plain.png`) );

        icons.stamp_farm_patterns.push( p.loadImage(`${path}patterns/angles_farm.png`) );
        icons.stamp_farm_patterns.push( p.loadImage(`${path}patterns/diamonds_farm.png`) );
        icons.stamp_farm_patterns.push( p.loadImage(`${path}patterns/dots_farm.png`) );
        icons.stamp_farm_patterns.push( p.loadImage(`${path}patterns/exes_farm.png`) );
        icons.stamp_farm_patterns.push( p.loadImage(`${path}patterns/feathers_farm.png`) );
        icons.stamp_farm_patterns.push( p.loadImage(`${path}patterns/horz_farm.png`) );
        icons.stamp_farm_patterns.push( p.loadImage(`${path}patterns/marsh_farm.png`) );
        icons.stamp_farm_patterns.push( p.loadImage(`${path}patterns/trees_farm.png`) );

        //premium patterns
        icons.stamp_patterns.push( p.loadImage(`${path}patterns_premium/80s_plain.png`) );
        icons.stamp_patterns.push( p.loadImage(`${path}patterns_premium/animal_plain.png`) );
        icons.stamp_patterns.push( p.loadImage(`${path}patterns_premium/eyeballs_plain.png`) );
        icons.stamp_patterns.push( p.loadImage(`${path}patterns_premium/invaders_plain.png`) );
        icons.stamp_patterns.push( p.loadImage(`${path}patterns_premium/metallic_plain.png`) );
        icons.stamp_patterns.push( p.loadImage(`${path}patterns_premium/money_plain.png`) );
        icons.stamp_patterns.push( p.loadImage(`${path}patterns_premium/smileys_plain.png`) );
        icons.stamp_patterns.push( p.loadImage(`${path}patterns_premium/cats_plain.png`) );

        icons.stamp_farm_patterns.push( p.loadImage(`${path}patterns_premium/80s_farm.png`) );
        icons.stamp_farm_patterns.push( p.loadImage(`${path}patterns_premium/animal_farm.png`) );
        icons.stamp_farm_patterns.push( p.loadImage(`${path}patterns_premium/eyballs_farm.png`) );
        icons.stamp_farm_patterns.push( p.loadImage(`${path}patterns_premium/invaders_farm.png`) );
        icons.stamp_farm_patterns.push( p.loadImage(`${path}patterns_premium/metalic_farm.png`) );
        icons.stamp_farm_patterns.push( p.loadImage(`${path}patterns_premium/money_farm.png`) );
        icons.stamp_farm_patterns.push( p.loadImage(`${path}patterns_premium/smileys_farm.png`) );
        icons.stamp_farm_patterns.push( p.loadImage(`${path}patterns_premium/cats_farm.png`) );

        //other patterns
        icons.stamp_pattern_unclaimed = p.loadImage(`${path}patterns/MidC.png`);
        icons.stamp_pattern_ocean = p.loadImage(`${path}patterns/ocean.png`);

    };

    p.setup = () => {
        const { wrapper } = props;

        // Get size of wrapper div, default to window
        const defaultRect = {
            width: window.innerWidth,
            height: window.innerHeight,
        };
        const wrapperRect = wrapper
            ? wrapper.getBoundingClientRect()
            : defaultRect;

        const canv = p.createCanvas(wrapperRect.width, wrapperRect.height);

        canv.mouseOver(mouse_enter_canvas);
        canv.mouseOut(mouse_leave_canvas);

        canv.drop(file_drop_function);

        canv.drawingContext.miterLimit = 2;

        canvas_center.x = p.width / 2;
        canvas_center.y = p.height / 2;

        p.background(255, 255, 255);
    };

    p.windowResized = () => {
        const { wrapper } = props;

        // Get size of wrapper div, default to window
        const defaultRect = {
            width: window.innerWidth,
            height: window.innerHeight,
        };
        const wrapperRect = wrapper
            ? wrapper.getBoundingClientRect()
            : defaultRect;

        console.log(`resize. Are you on mobile: ${on_mobile}`);
        if (!on_mobile) {
            console.log("wrapper width: "+wrapperRect.width)
            p.resizeCanvas(wrapperRect.width, wrapperRect.height);
            canvas_center.x = p.width / 2;
            canvas_center.y = p.height / 2;
            set_min_zoom(grid_info, p);
        }
    };



    p.draw = () => {
        const { setHighlightedPlayer } = ui_bridge.getGameState();
        const { setHighlightedTerritory } = ui_bridge.getGameState();
        p.background(255, 255, 255);
        //p.background(0);

        delta_time = (p.millis() - prev_millis) / 1000.0;
        prev_millis = p.millis();

        if (game_state.is_sandbox) {
            sandbox_resolve_timer--;
        }

        

        if (game_state.no_map_data) {
            p.textAlign(p.CENTER, p.CENTER);
            p.textSize(20);
            let message = `No map data found. Has this game been started?`;
            if (game_state.room_id != null) {
                message = `No map data found for room "${game_state.room_id}". Has this game been started?`;
            }
            p.text(message, p.width / 2, p.height / 4);
        } 
        else if (game_state.bad_room_id) {
            p.textAlign(p.CENTER, p.CENTER);
            p.textSize(20);
            let message = `No game room specified`;
            if (game_state.room_id != null) {
                message = `No game found for room "${game_state.room_id}".`;
            }
            message += `\nCheck the URL and try again`;
            p.text(message, p.width / 2, p.height / 4);
        } 
        else if (game_state.data_has_loaded) {
            screenshot_timer--; //used for render mode
            p.push();
            p.translate(canvas_center.x, canvas_center.y);
            p.scale(zoom, zoom);
            p.translate(-cam_pos.x, -cam_pos.y);
            draw_map.draw_map({
                is_sandbox: game_state.is_sandbox,
                fbos: grid_info.fbos,
                screenshot_timer: screenshot_timer,
                sandbox_resolve_timer: sandbox_resolve_timer,
                p: p,
            });

            p.pop();
            

            //TESTING not doing this!
            //pull_map_in_bounds(grid_info, p, props);

            //snapping back to the center tile if we scroll to far
            if (cam_pos.x > grid_info.fbos.base.width){
                cam_pos.x -= grid_info.fbos.base.width;
            }
            if (cam_pos.y > grid_info.fbos.base.height){
                cam_pos.y -= grid_info.fbos.base.height;
            }
            if (cam_pos.x < 0){
                cam_pos.x += grid_info.fbos.base.width;
            }
            if (cam_pos.y < 0){
                cam_pos.y += grid_info.fbos.base.height;
            }

            if (game_state.need_to_print_order_text) {
                console.log("printing orders");
                dom_manipulation.print_orders({
                    game_state: game_state,
                    mouse_info: mouse_info,
                });
                game_state.need_to_print_order_text = false;
            }

            if (game_state.players) {
                game_state.players.forEach((player) => {
                    if (player === mouse_info.highlighted_player) {
                        setHighlightedPlayer(player);
                    }
                });
            }

            if (game_state.territories){
                game_state.territories.forEach( (ter) => {
                    if (ter.id === mouse_info.highlighted_territory){
                        setHighlightedTerritory(ter);
                    }
                })
            }


            // showing the time
            if (has_time_info) {

                const cur_time = new Date();
                const time_diff = msToTime(Math.abs(cur_time - next_res_time));
                let time_text = `Next order resolution in ${time_diff}`;
                if (game_state.room_info){
                    if (game_state.room_info.performance_mode){
                        time_text = `I stop accepting orders in ${time_diff}`;
                    }
                }

                if (cur_time > next_res_time) {
                    time_text = "ORDERS RESOLVED. REFRESH PAGE!";

                    //if we're just a little past, print some text
                    if (cur_time - next_res_time < 60000){
                        time_text = "RESOLVING ORDERS..."
                    }

                    if (Math.floor(p.millis() / 1000.0) % 2 == 0) {
                        time_text = `<b>${time_text}</b>`;
                    }
                }

                if (game_state.room_info && game_state.room_info.paused) {
                    time_text = "Next order resolution: PAUSED";
                }

                let countdown_holder = document.querySelector("#countdown_text");
                if (countdown_holder){
                    document.querySelector("#countdown_text").innerHTML = time_text;
                } 
            }

            // showing some info about how to control the map
            if (
                show_grid_info_timer > 0 &&
                !game_state.is_sandbox &&
                props.pathname != "/render"
            ) {
                show_grid_info_timer -= delta_time;
                let text_prc = 1;
                const fade_time = 1;
                if (show_grid_info_timer < fade_time) {
                    text_prc = show_grid_info_timer / fade_time;
                }
                if (text_prc < 0) text_prc = 0;
                p.textAlign(p.LEFT);
                p.textSize(14);
                p.stroke(0, 255 * text_prc);
                p.strokeWeight(2);
                p.fill(255, 255 * text_prc);
                let message = "Click and drag to move map, scroll to zoom";
                if (on_mobile) {
                    message = "drag to move map, pinch to zoom";
                }
                p.text(message, 20, p.height - 30);
            }

            //if this is sandbox of a normal game, display that:
            if (game_state.is_sandbox && game_state.room_id != "sandbox") {
                p.textAlign(p.LEFT);
                p.textSize(18);
                let alpha = 55 + 200 * (Math.sin(p.millis() / 1000) + 1) * 0.5;
                p.stroke(0, alpha);
                p.strokeWeight(2);
                p.fill(255, 0, 0, alpha);
                p.text(
                    "Sandbox mode. Refresh for current map",
                    20,
                    p.height - 30
                );
            }

        } 

        if(game_state.still_loading) {
            p.textAlign(p.CENTER, p.CENTER);
            p.textSize(20);
            p.stroke(255);
            p.strokeWeight(6);
            p.fill(0, 0, 0);
            let load_prc = Math.floor((game_state.loading_progress / 6) * 100);
            let loading_message = `Loading room with ID "${game_state.room_id}"... ${load_prc}%`;
            if (game_state.data_has_loaded){
                p.textSize(30);
                loading_message = `Loading turn... ${load_prc}%`;
            }
            
            p.text(loading_message, p.width / 2, p.height / 4);
        }

        // updating the camera animation if we're in one
        if (doing_cam_anim) {
            cam_anim_timer += delta_time;
            const prc = cam_anim_timer / cam_anim_time;

            cam_pos.x = (1.0 - prc) * cam_start_pos.x + prc * cam_target_pos.x;
            cam_pos.y = (1.0 - prc) * cam_start_pos.y + prc * cam_target_pos.y;

            zoom = (1.0 - prc) * cam_start_zoom + prc * cam_target_zoom;

            if (cam_anim_timer >= cam_anim_time) {
                doing_cam_anim = false;
                cam_pos.x = cam_target_pos.x;
                cam_pos.y = cam_target_pos.y;
                zoom = cam_target_zoom;
            }
        }

        // console.log("doing: "+doing_cam_anim+"  timer: "+cam_anim_timer)

        // p.textFont(icons.font);
        // p.fill(0);
        // p.text("HI🧙‍♂️II!", 200 ,200)

        // testing frame rate
        if (false) {
            p.textAlign(p.LEFT, p.TOP);
            p.noStroke(0);
            p.fill(255, 0, 0);
            p.textSize(12);
            p.text(`FPS: ${Math.round(p.frameRate())}`, p.width - 100, 15);
        }
    };

    p.touchStarted = (event) => {
        on_mobile = true;

        //dragging
        if (event.touches.length == 1) {
            //no dragging from the side bar
            const { sidebarActive } = ui_bridge.getGameState();
            if (sidebarActive) {
                can_drag_map = false;
                return;
            }

            if (
                event.touches[0].screenX >= 0 &&
                event.touches[0].screenX <= p.width &&
                event.touches[0].screenY >= 0 &&
                event.touches[0].screenY <= p.height
            ) {
                can_drag_map = true;
                mouse_grip_pnt.x = event.touches[0].screenX;
                mouse_grip_pnt.y = event.touches[0].screenY;
            }
        }

        //pinching
        if (event.touches.length == 2) {
            let start_pos_a = {
                x: event.touches[0].pageX,
                y: event.touches[0].pageY,
            };
            let start_pos_b = {
                x: event.touches[1].pageX,
                y: event.touches[1].pageY,
            };
            start_pinch_dist = p.dist(
                start_pos_a.x,
                start_pos_a.y,
                start_pos_b.x,
                start_pos_b.y
            );
            cur_pinch_dist = start_pinch_dist;
            start_cam_zoom = zoom;
        }
    };

    p.touchMoved = (event) => {

        const { setHighlightedPlayer } = ui_bridge.getGameState();
        mouse_info.highlighted_player = null;
        setHighlightedPlayer(null);

        if (can_drag_map && event.touches.length > 0) {
            cam_pos.x += (mouse_grip_pnt.x - event.touches[0].screenX) / zoom;
            cam_pos.y += (mouse_grip_pnt.y - event.touches[0].screenY) / zoom;
            mouse_grip_pnt.x = event.touches[0].screenX;
            mouse_grip_pnt.y = event.touches[0].screenY;

            show_grid_info_timer -= map_movement_timer_reduction;
        }

        if (event.touches.length == 2 && !game_state.is_sandbox) {
            let cur_pos_a = {
                x: event.touches[0].pageX,
                y: event.touches[0].pageY,
            };
            let cur_pos_b = {
                x: event.touches[1].pageX,
                y: event.touches[1].pageY,
            };

            cur_pinch_dist = p.dist(
                cur_pos_a.x,
                cur_pos_a.y,
                cur_pos_b.x,
                cur_pos_b.y
            );
            let pinch_dist = cur_pinch_dist - start_pinch_dist;

            zoom = start_cam_zoom + pinch_dist * 0.005;
            if (zoom < min_zoom) {
                zoom = min_zoom;
                show_grid_info_timer -= map_movement_timer_reduction;
            }
            if (zoom > max_zoom) {
                zoom = max_zoom;
                show_grid_info_timer -= map_movement_timer_reduction;
            }
        }
    };

    p.touchEnded = () => {
        can_drag_map = false;
    };

    let click_time = null;

    p.mouseClicked = () => {
        const {
            setCenteredPlayer,
            setHighlightedPlayer,
        } = ui_bridge.getGameState();
        const milli_since_mousedown = Date.now() - click_time;

        // Check if the mousedown was short enough to be a "click" and not a "drag"
        if (milli_since_mousedown < 100) {
            // Center a player if the mouse is highlighting one
            if (game_state.players) {
                game_state.players.forEach((player) => {
                    if (
                        player === mouse_info.highlighted_player &&
                        !on_mobile
                    ) {
                        setCenteredPlayer(player);
                    } else {
                        setHighlightedPlayer(null);
                    }
                });
            }
        }
    };

    p.mousePressed = (e) => {
        click_time = Date.now();

        // Don't listen to events if we're clicking outside canvas
        const isClickingCanvas =
            e.srcElement && e.srcElement.classList.contains("p5Canvas");

        if (isClickingCanvas && p.mouseButton === p.LEFT) {
            if (
                p.mouseX >= 0 &&
                p.mouseX <= p.width &&
                p.mouseY >= 0 &&
                p.mouseY <= p.height
            ) {
                // //no dragging from the side bar
                // const { sidebarActive } = ui_bridge.getGameState();
                // if (sidebarActive) {
                //     can_drag_map = false;
                //     return;
                // }

                can_drag_map = true;
                mouse_grip_pnt.x = p.mouseX;
                mouse_grip_pnt.y = p.mouseY;
            }
        }
    };

    p.mouseReleased = () => {
        can_drag_map = false;
    };

    p.mouseDragged = () => {
        if (p.mouseButton === p.LEFT && can_drag_map) {
            cam_pos.x += (mouse_grip_pnt.x - p.mouseX) / zoom;
            cam_pos.y += (mouse_grip_pnt.y - p.mouseY) / zoom;

            mouse_grip_pnt.x = p.mouseX;
            mouse_grip_pnt.y = p.mouseY;

            show_grid_info_timer -= map_movement_timer_reduction;
        }
    };

    p.mouseWheel = (event) => {
        if (!mouse_on_canvas) return;
        if (game_state.is_sandbox) return;

        zoom -= event.delta * 0.002;
        if (zoom < min_zoom) {
            zoom = min_zoom;
            show_grid_info_timer -= map_movement_timer_reduction;
        }
        if (zoom > max_zoom) {
            zoom = max_zoom;
            show_grid_info_timer -= map_movement_timer_reduction;
        }
    };

    p.mouseMoved = () => {
        if (!mouse_on_canvas) return;
        if (game_state.data_has_loaded) {
            const { setHighlightedPlayer } = ui_bridge.getGameState();
            const { setHighlightedTerritory } = ui_bridge.getGameState();

            // Unhightlight on mousemove
            if (!mouse_info.highlighted_player) {
                setHighlightedPlayer(null);
            }
            if (!mouse_info.highlighted_territory){
                setHighlightedTerritory(null);
            }

            let touched_something = false;

            //I don't think these transforations were serving any purpose
            // p.translate(canvas_center.x, canvas_center.y);
            // p.scale(zoom, zoom);
            // p.translate(-cam_pos.x, -cam_pos.y);

            let adjusted_x = p.mouseX;
            let adjusted_y = p.mouseY;
            adjusted_x -= canvas_center.x;
            adjusted_y -= canvas_center.y;
            adjusted_x /= zoom;
            adjusted_y /= zoom;
            adjusted_x += cam_pos.x;
            adjusted_y += cam_pos.y;

            //get a mouse position for each tile in our grid
            let mice = []
            for (let c=-1; c<=1; c++){
                for (let r=-1; r<=1; r++){
                    mice.push({
                        x: adjusted_x + grid_info.fbos.base.width * c,
                        y: adjusted_y + grid_info.fbos.base.height * r
                    })
                }
            }

            const { hex_cols } = grid_info;
            const { hex_rows } = grid_info;
            const { hexes } = grid_info;
            for (let x = 0; x < hex_cols; x++) {
                for (let y = 0; y < hex_rows; y++) {
                    //let mouse_inside = hex_manager.pnt_inside_hex( [adjusted_x, adjusted_y], hexes[x][y])
                    let mouse_inside = false
                    let test_val = null         //just used for printing out the mouse location
                    mice.forEach(mouse =>{
                        if (hex_manager.pnt_inside_hex( [mouse.x, mouse.y], hexes[x][y])){
                            mouse_inside = true
                            test_val = {x:mouse.x,y:mouse.y}
                        }
                    });
                    hex_manager.pnt_inside_hex( [adjusted_x, adjusted_y], hexes[x][y])
                    if ( mouse_inside) {
                        if (mouse_info.last_mouse_hex != hexes[x][y]) {
                            mouse_info.last_mouse_hex = hexes[x][y];
                            mouse_info.highlighted_player = null;
                            
                            // let testo_center = hex_manager.get_hex_center(hexes[x][y])
                            //console.log("you moused on "+x+" , "+y +"    "+test_val.x+" , "+test_val.y)

                            if (hexes[x][y].territory != null) {
                                //if an old turn is loading, the map data might be null, so check that first
                                if (game_state.map_data!=null){
                                    const ter = territory_manager.get_territory_from_id(
                                        hexes[x][y].territory.id,
                                        game_state.map_data
                                    );

                                    if (ter.owner != "NONE") {
                                        const owning_player = data_process.get_player_from_id(
                                            ter.owner,
                                            game_state.players
                                        );
                                        mouse_info.highlighted_player = owning_player;
                                    }
                                    mouse_info.highlighted_territory =
                                        hexes[x][y].territory.id;
                                }
                            }
                        }
                        touched_something = true;
                    }
                }
            }
            if (!touched_something) {
                mouse_info.highlighted_player = null;
                mouse_info.highlighted_territory = "NONE";
                mouse_info.last_mouse_hex = null;
            }
        }
    };

    p.keyPressed = () => {
        // TODO: work more on getting this to function
        // if (key == 'S'){
        // 	save_map_image()
        // }
    };

    //trying to tie up network activity while the map is being rendered so that the API flash bot doesn't think the page is done loading
    p.load_lock = (p, url) =>{
        p.loadImage(url, img => {
            console.log("load lock screenshot timer: "+screenshot_timer)
            if(screenshot_timer > 0){
                p.load_lock(p, url);
            }
        });
    }
}



function set_time_info(time_info, cur_room_info) {
    has_time_info = true;
    next_res_time = new Date();
    let padding = 60; // in seconds

    //in performance/fast mode, we want to show when we'll stop accepting orders
    if(cur_room_info){
        if (cur_room_info.performance_mode) padding = -30;
    }

    next_res_time.setSeconds(
        next_res_time.getSeconds() + time_info.seconds + padding
    );

    console.log(`next is ${next_res_time}`);
}

function mouse_enter_canvas() {
    mouse_on_canvas = true;
}
function mouse_leave_canvas() {
    mouse_on_canvas = false;
    mouse_info.highlighted_player = null;
}

// this is called in set_map and in drawing_orders
function center_cam({ player_id, grid_info, p, props }) {
    const any_player = player_id == null;
    const { hex_cols } = grid_info;
    const { hex_rows } = grid_info;
    const { hexes } = grid_info;

    //figure out the claimed hexes that frame the map
    let top_left_hex_pos = null;
    let bot_right_hex_pos = null;

    if (any_player) {
        top_left_hex_pos = { x: hexes[0][0].x, y: hexes[0][0].y };
        bot_right_hex_pos = {
            x: hexes[hex_cols - 1][hex_rows - 1].x,
            y: hexes[hex_cols - 1][hex_rows - 1].y,
        };
    } else {
        for (let x = 0; x < hex_cols; x++) {
            for (let y = 0; y < hex_rows; y++) {
                const hex = hexes[x][y];
                if (hex.territory != null) {
                    if (hex.territory.owner == player_id) {
                        if (top_left_hex_pos == null)
                            top_left_hex_pos = { x: hex.x, y: hex.y };
                        if (bot_right_hex_pos == null)
                            bot_right_hex_pos = { x: hex.x, y: hex.y };

                        if (hex.x < top_left_hex_pos.x)
                            top_left_hex_pos.x = hex.x;
                        if (hex.x > bot_right_hex_pos.x)
                            bot_right_hex_pos.x = hex.x;
                        if (hex.y < top_left_hex_pos.y)
                            top_left_hex_pos.y = hex.y;
                        if (hex.y > bot_right_hex_pos.y)
                            bot_right_hex_pos.y = hex.y;
                    }
                }
            }
        }
    }

    // if we didn't find any, just set it to be the center of the map
    if (top_left_hex_pos == null || bot_right_hex_pos == null) {
        let padding = 20;
        top_left_hex_pos = {
            x: Math.max(Math.floor(hex_cols / 2) - padding, 0),
            y: Math.max(Math.floor(hex_rows / 2) - padding, 0),
        };
        bot_right_hex_pos = {
            x: Math.min(Math.floor(hex_cols / 2) + padding, hex_cols - 1),
            y: Math.min(Math.floor(hex_rows / 2) + padding, hex_rows - 1),
        };
    }

    const top_left_pos = hex_manager.get_hex_center(
        hexes[top_left_hex_pos.x][top_left_hex_pos.y]
    );
    const bot_right_pos = hex_manager.get_hex_center(
        hexes[bot_right_hex_pos.x][bot_right_hex_pos.y]
    );
    // const top_left_pos = hex_manager.get_hex_center(
    //     hexes[ hexes[0][0].x][ hexes[0][0].y]
    // );
    // const bot_right_pos = hex_manager.get_hex_center(
    //     hexes[hexes[hex_cols-1][hex_rows-1].x][hexes[hex_cols-1][hex_rows-1].y]
    // );

    cam_target_pos = { x: 0, y: 0 };
    cam_target_pos.x = (top_left_pos.x + bot_right_pos.x) / 2;
    cam_target_pos.y = (top_left_pos.y + bot_right_pos.y) / 2;

    // get the zoom
    set_min_zoom(grid_info, p);

    const total_dist = {
        x: bot_right_pos.x - top_left_pos.x,
        y: bot_right_pos.y - top_left_pos.y,
    };

    const padding = 80; // 100;

    const zoom_x = (p.width - padding) / total_dist.x;
    const zoom_y = (p.height - padding) / total_dist.y;

    cam_target_zoom = Math.min(zoom_x, zoom_y);
    //console.log("initial target zoom "+cam_target_zoom)
    if (any_player) {
        cam_target_zoom *= 0.9;
        // console.log("any player zoom: "+cam_target_zoom)
    } else {
        cam_target_zoom *= 0.4;
        // console.log("specific player zoom: "+cam_target_zoom)
    }
    if (cam_target_zoom < min_zoom) {
        //console.log("we hit min")
        cam_target_zoom = min_zoom;
    }

    // console.log("min zoom "+min_zoom)
    // console.log("target zoom "+cam_target_zoom)

    // start an animation
    doing_cam_anim = true;
    cam_anim_timer = 0;
    cam_start_pos = cam_pos;
    cam_start_zoom = zoom;
}

function set_min_zoom(grid_info, p) {
    const { hexes } = grid_info;

    if (hexes) {
        // figure out the min zoom (the most we'll let them zoom out)
        const padding = 300;    //200
        const top_left_pos = hex_manager.get_hex_center(hexes[0][0]);
        const bot_right_pos = hex_manager.get_hex_center(
            hexes[grid_info.hex_cols - 1][grid_info.hex_rows - 1]
        );

        const total_dist = {
            x: bot_right_pos.x - top_left_pos.x,
            y: bot_right_pos.y - top_left_pos.y,
        };
        // console.log("total dist ")
        // console.log(total_dist)
        // console.log("p.width "+p.width)
        const zoom_x = (p.width - padding) / total_dist.x;
        const zoom_y = (p.height - padding) / total_dist.y;

        min_zoom = Math.min(zoom_x, zoom_y);
    }

    if (min_zoom < 0) min_zoom = 0.01; //when using local emulator, this function can be called before p.width has a value, which gives us negative results
    console.log("min zoom " + min_zoom);
}

//THIS IS NOT USED IF YOU ARE DOING INFINITE SCROLL
function pull_map_in_bounds(grid_info, p, props) {
    if (can_drag_map) return;
    const { hexes } = grid_info;
    const top_left_pos = hex_manager.get_hex_center(hexes[0][0]);
    const bot_right_pos = hex_manager.get_hex_center(
        hexes[grid_info.hex_cols - 1][grid_info.hex_rows - 1]
    );

    let hard_top = top_left_pos.y;
    let hard_bottom = bot_right_pos.y;

    //not worrying about X right now
    //top_left_pos.x += p.width*0.5
    //top_left_pos.x -= cam_pos.x*zoom
    top_left_pos.y += p.height * 0.5;
    top_left_pos.y -= cam_pos.y * zoom;

    bot_right_pos.y = top_left_pos.y + (hard_bottom - hard_top) * zoom;

    let push_power = 0.08;
    let padding = 100;
    if (top_left_pos.y > padding) {
        let dist = top_left_pos.y - padding;
        cam_pos.y += dist * push_power;
    }

    if (bot_right_pos.y < p.height - padding) {
        let dist = p.height - padding - bot_right_pos.y;
        cam_pos.y -= dist * push_power;
    }
}

function get_delta_time() {
    return delta_time;
}

// https://stackoverflow.com/questions/19700283/how-to-convert-time-milliseconds-to-hours-min-sec-format-in-javascript/33909506
function msToTime(duration) {
    let seconds = Math.floor((duration / 1000) % 60);
    let minutes = Math.floor((duration / (1000 * 60)) % 60);
    let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    // Add leading zeroes
    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    seconds = seconds < 10 ? `0${seconds}` : seconds;

    return `${hours}:${minutes}:${seconds}`;
}

function map_pos_to_screen_pos(map_pos) {
    let adjusted_x = map_pos.x;
    let adjusted_y = map_pos.y;
    adjusted_x -= cam_pos.x;
    adjusted_y -= cam_pos.y;
    adjusted_x *= zoom;
    adjusted_y *= zoom;
    adjusted_x += canvas_center.x
    adjusted_y += canvas_center.y;
    return {x:adjusted_x, y:adjusted_y};
}

export default {
    set_p5_functions,
    set_time_info,
    mouse_enter_canvas,
    mouse_leave_canvas,
    center_cam,
    set_min_zoom,
    get_delta_time,
    map_pos_to_screen_pos
};
