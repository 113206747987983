import draw_map from "./draw_map.js";
import hex_manager from "./hex_manager.js";
import data_pull from "./data_pull.js";
import data_process from "./data_process.js";
import sketch from "./sketch.js";
import sandbox from "./sandbox.js";
import ui_bridge from "./ui_bridge.js";

export default (p, initialProps = {}) => {
    if (!p) {
        return null;
    }

    let firstLoad = true;

    //all icon images will live in here
    let icons = {};

    //structure for drawing the map
    let grid_info = {
        cell_size: 10,
        border_padding: 60,
        hex_cols: 0, //cols and rows gets set in hex_manager.setup_hexes
        hex_rows: 0,
        hexes: null,
        points: null,
        fbos: {}, //base, highlight, ui
    };

    //TODO: this gets the turn if you include something like "&turn=3" in the URL
    //but it sets that turn as the max value and does not let you step forward to future turns
    //the next turn button is always greyed out
    //game_state.newest_turn_num is being set though
    //we can remove this if it's too much of a hassle
    let turn_to_load = -1;
    if (getUrlVars().turn){
        turn_to_load = getUrlVars().turn;
        console.log("loading specific turn:"+turn_to_load)
    }
    

    //storing the data that makes up the game
    let game_state = {
        room_id: initialProps.room || getUrlVars().room,
        room_info: null,
        base_map_data: null,
        map_data: null,
        territories: [],
        base_player_data: null,
        player_stats: null,
        player: null,
        orders: null,
        data_has_loaded: false,
        loading_progress: 0,
        cur_turn: -1,
        newest_turn_num: -1,
        first_load: true,
        is_sandbox: initialProps.isSandbox,
        need_to_print_order_text: false, //TODO: do you need this? Seems clunky
        bad_room_id: false,
    };

    console.log(`ROOM: ${game_state.room_id}`);

    //this gets called by data_pull once it has everything
    function finished_with_load() {
        console.log("running finished_with_load");
        console.log("sandbox: " + game_state.is_sandbox);

        //when using the emulator, sometimes the DB stuff comes back before p5 is setup. If that happens we should pause here for a second
        if (p._renderer == null) {
            game_state.data_has_loaded = false;
            console.log("retrying because p5 is not ready")
            setTimeout(finished_with_load, 500); // try again in 500 milliseconds
            return;
        }
        game_state.data_has_loaded = true;

        //setup the hexes if this is our first run
        if (grid_info.hexes == null) {
            hex_manager.set_hex_size({
                grid_info: grid_info,
                base_map_data: game_state.base_map_data,
            });

            grid_info.points = hex_manager.create_points(grid_info);

            grid_info.hexes = hex_manager.create_hexes(grid_info);

            //create the FBOs
            const pnt_cols = grid_info.points.length;
            const pnt_rows = grid_info.points[0].length;
            let map_size = grid_info.points[pnt_cols - 1][pnt_rows - 1];
            map_size.x += grid_info.border_padding;
            map_size.y += grid_info.border_padding;

            console.log("tile size: "+icons.stamp_pattern_ocean.width)
            console.log("origo "+map_size.x + " , "+map_size.y);

            //make sure both values are whole numbers
            map_size.x = Math.floor(map_size.x);
            map_size.y = Math.floor(map_size.y);
            //grow until we'll tile cleanly
            let tiling_bg_size = icons.stamp_pattern_ocean.width;
            while (map_size.x % tiling_bg_size != 0){
                map_size.x++;
            }
            while (map_size.y % tiling_bg_size != 0){
                map_size.y++;
            }

            grid_info.fbos = draw_map.create_fbos({
                map_size: map_size,
                p: p,
            });
        }

        // set the info for the turn
        data_process.set_turn_data(game_state, grid_info);

        if(initialProps.pathname === "/render"){
            console.log("LOAD LOCK BABEY")
            p.load_lock(p, `/pic/patterns_premium/80s_farm.png`);
        }

        //draw into the FBOs
        draw_map.reset_map_canvas({
            app_state: ui_bridge.getGameState(),
            game_state: game_state,
            icons: icons,
            fbos: grid_info.fbos,
            p: p,
            props: initialProps,
        });

        //center the cam
        if (firstLoad){
            sketch.center_cam({
                player_id: null,
                grid_info: grid_info,
                p: p,
            });
        }

        // Hand room data back to UI app
        if (firstLoad) {
            ui_bridge.init(game_state);
            firstLoad = false;
        } else {
            ui_bridge.update(game_state);
        }

        sandbox.setup(game_state, finished_with_load);
    }

    // Callbacks on React prop changes
    p.myCustomRedrawAccordingToNewPropsHandler = (props) => {
        // Handle updates from UI state
        const config = {
            game_state,
            icons,
            fbos: grid_info.fbos,
            grid_info,
            p,
            loadCallback: finished_with_load,
        };
        ui_bridge.handleNewProps(props, config);
    };

    // get everything from the backend
    data_pull.start_initial_load(game_state, finished_with_load, turn_to_load);

    //setup the P5 skecth
    sketch.set_p5_functions({
        p,
        icons,
        game_state,
        grid_info,
        props: initialProps,
        finished_with_load_function: finished_with_load,
        file_drop_function: file_drop,
    });

    // https://html-online.com/articles/get-url-parameters-javascript/
    function getUrlVars() {
        const vars = {};
        const parts = window.location.href.replace(
            /[?&]+([^=&]+)=([^&]*)/gi,
            function (m, key, value) {
                vars[key] = value;
            }
        );
        return vars;
    }

    //used to test out different icons
    // https://p5js.org/examples/dom-drop.html
    function file_drop(file) {
        console.log("got something");
        console.log(file);

        //this is dumb as hell, but just using createImg would return an image that would display but not tint
        //some of this logic from https://jsfiddle.net/defenestrated/zc03gm3g/32/
        if (file.type === "image") {
            let pic = p.createImg(file.data, "", "", function () {
                let pg = p.createGraphics(pic.width, pic.height);
                pg.clear();
                pg.image(pic, 0, 0);
                let img = pg.get();

                if (file.name.startsWith("army")) {
                    icons.army_icon = img;
                }
                if (file.name.startsWith("cross")) {
                    icons.cross_pic = img;
                }
                if (file.name.startsWith("farm")) {
                    icons.farm_icon = img;
                }
                if (file.name.startsWith("dead")) {
                    icons.dead_icon = img;
                }
                if (file.name.startsWith("sup_left")) {
                    icons.sup_icon_left = img;
                }
                if (file.name.startsWith("sup_right")) {
                    icons.sup_icon_right = img;
                }
                if (file.name.startsWith("supply_point")) {
                    icons.supply_point_icon = img;
                }

                //img_to_replace = pg.get()

                finished_with_load();
            });
        }
    }
};
