function new_hex(hx, hy, points) {
    const hex = {
        points: [],
        neighbors: [],
        neighbors_full: [], // this is for drawing borders accurately and could be better done
        territory: null,
        x: hx,
        y: hy
    };

    hex.points.push(points[hx * 2][(hy + 1) * 2 - (hx % 2)]); // A
    hex.points.push(points[hx * 2 + 1][(hy + 1) * 2 - (hx % 2) - 1]); // B
    hex.points.push(points[hx * 2 + 2][(hy + 1) * 2 - (hx % 2) - 1]); // C
    hex.points.push(points[hx * 2 + 3][(hy + 1) * 2 - (hx % 2)]); // D
    hex.points.push(points[hx * 2 + 2][(hy + 1) * 2 - (hx % 2) + 1]); // E
    hex.points.push(points[hx * 2 + 1][(hy + 1) * 2 - (hx % 2) + 1]); // F

    return hex;
}

function set_hex_size({grid_info, base_map_data}){
    // figure out the bottom right
    let hex_cols = 0;
    let hex_rows = 0;
    for (let i = 0; i < base_map_data.length; i++) {
        const this_ter = base_map_data[i];
        for (let h = 0; h < this_ter.hexes.length; h++) {
            const hex = this_ter.hexes[h];
            if (hex.x > hex_cols) hex_cols = hex.x;
            if (hex.y > hex_rows) hex_rows = hex.y;
        }
    }
    // a bit of padding
    hex_cols++;
    hex_rows++;

    //store it
    grid_info.hex_cols = hex_cols
    grid_info.hex_rows = hex_rows
}

function create_points(grid_info){
    let pnt_cols = grid_info.hex_cols * 2 + 5;
    let pnt_rows = grid_info.hex_rows * 2 + 5;

    //making sure the map size matches the tiling background image
    // let tiling_background_size = 100
    // console.log("origo "+(grid_info.border_padding + (pnt_cols-1) * 10 ));

    // while (pnt_cols % 10 != 1){
    //     pnt_cols++;
    // }
    // while (pnt_rows % 10 != 1){
    //     pnt_rows++;
    // }

    // console.log(pnt_cols)

    const {cell_size} = grid_info

    // create points
    let points = [];
    for (let c = 0; c < pnt_cols; c++) {
        points.push([]);
        for (let r = 0; r < pnt_rows; r++) {
            points[c].push({
                x: grid_info.border_padding + c * cell_size,
                y: grid_info.border_padding + r * cell_size,
            });
        }
    }


    return points
}

function create_hexes(grid_info) {
    console.log("set hexes");

    let {hex_cols} = grid_info
    let {hex_rows} = grid_info

    // create hexes
    let hexes = [];
    for (let c = 0; c < hex_cols; c++) {
        hexes.push([]);
        for (let r = 0; r < hex_rows; r++) {
            hexes[c].push(new_hex(c, r, grid_info.points));
        }
    }

    // calculate neighbors
    for (let hx = 0; hx < hex_cols; hx++) {
        for (let hy = 0; hy < hex_rows; hy++) {
            const hex = hexes[hx][hy];

            const north_west = get_hex(hx - 1, hy - (hx % 2), hexes, hex_cols, hex_rows);
            if (north_west) hex.neighbors.push(north_west);
            hex.neighbors_full.push(north_west);

            const north = get_hex(hx, hy - 1, hexes, hex_cols, hex_rows);
            if (north) hex.neighbors.push(north);
            hex.neighbors_full.push(north);

            const north_east = get_hex(hx + 1, hy - (hx % 2), hexes, hex_cols, hex_rows);
            if (north_east) hex.neighbors.push(north_east);
            hex.neighbors_full.push(north_east);

            const south_east = get_hex(hx + 1, hy + ((hx + 1) % 2), hexes, hex_cols, hex_rows);
            if (south_east) hex.neighbors.push(south_east);
            hex.neighbors_full.push(south_east);

            const south = get_hex(hx, hy + 1, hexes, hex_cols, hex_rows);
            if (south) hex.neighbors.push(south);
            hex.neighbors_full.push(south);

            const south_west = get_hex(hx - 1, hy + ((hx + 1) % 2), hexes, hex_cols, hex_rows);
            if (south_west) hex.neighbors.push(south_west);
            hex.neighbors_full.push(south_west);
        }
    }

    return hexes
}

function get_hex_center(hex) {
    // console.log(hex)
    const center_x = (hex.points[0].x + hex.points[3].x) / 2.0;
    const center_y = (hex.points[0].y + hex.points[3].y) / 2.0;
    return { x: center_x, y: center_y };
}

function get_hex(hx, hy, hexes, hex_cols, hex_rows) {
    if (hx < 0 || hx >= hex_cols) {
        return null;
    }
    if (hy < 0 || hy >= hex_rows) {
        return null;
    }
    return hexes[hx][hy];
}

function pnt_inside_hex(point, hex) {
    const pnts = [];
    for (let i = 0; i < hex.points.length; i++) {
        pnts.push([hex.points[i].x, hex.points[i].y]);
    }

    return inside_poly(point, pnts);
}

// https://stackoverflow.com/questions/22521982/check-if-point-inside-a-polygon
function inside_poly(point, vs) {
    // ray-casting algorithm based on
    // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html

    const x = point[0];
    const y = point[1];

    let inside = false;
    for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
        const xi = vs[i][0];
        const yi = vs[i][1];
        const xj = vs[j][0];
        const yj = vs[j][1];

        const intersect =
            yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
        if (intersect) inside = !inside;
    }

    return inside;
}

export default { 
    new_hex,
    set_hex_size,
    create_points,
    create_hexes,
    get_hex_center,
    get_hex,
    pnt_inside_hex
}
