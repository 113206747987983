import backend from "./backend.js";


var game_state = null
var finished_load_function = null

var queued_orders = []


function setup(gs, kick_off_function){
	game_state = gs
	finished_load_function = kick_off_function
	queued_orders = []	//wipe queued orders

}

function submit_sandbox_orders(player, order_text = "", callback){
	if (game_state == null){
		console.log("sandbox not setup yet")
		return
	}

	let orders = order_text.split(/,|\n/) 
	const orderRequests = orders.map(order => {
		// Protect against empty string orders from the split on newlines
		if (order) {
			return submit_sandbox_order(player, order, callback);
		}
	});
	
	return orderRequests;
}

const default_callback = function (response) {
};

function submit_sandbox_order(player, order_text, callback){
	if (game_state == null){
		console.log("sandbox not setup yet")
		return
	}

	const orderRequest = backend.send_message(
        "submit_order_sandbox",
        {
            player: player,
            order: order_text,
            room_id: "sandbox"
        },
        (response) => {
			if (response.was_good){
				queued_orders.push(response.order)
				console.log("we have "+queued_orders.length+" queued sandbox orders")
			}else{
				console.log("order rejected: "+ response.response)
			}

			if (callback){
				callback(response, queued_orders);
			}
		}
	);
	
	return orderRequest;
}

function resolve_sandbox(){
	game_state.data_has_loaded = false

	
	let params = JSON.stringify( {room_id:game_state.room_id, take_snapshot:false,  post_about_results:false, stringify:true} )
	let simple_game_state = {
		cur_turn: game_state.cur_turn,
		room_info: game_state.room_info,
		territories: game_state.base_map_data,
		cur_territory_info : game_state.map_data,
		all_orders: queued_orders,
		player_stats: game_state.player_stats,
		players: game_state.base_player_data,
		version: game_state.room_info.version	
	}
	let game_state_string = JSON.stringify(simple_game_state)

	//console.log("try to resolve sandbox now with these parameters")
	//console.log(params)

	backend.send_message("resolve_data_only", {params:params, game_state:game_state_string}, function(data) {
		
		let new_game_state = JSON.parse(data.new_game_state)
		
		game_state.cur_turn = new_game_state.cur_turn
		game_state.room_info = new_game_state.room_info
		//game_state.base_map_data = new_game_state.territories 	//base map should not change
		game_state.map_data = new_game_state.cur_territory_info
		game_state.orders = new_game_state.all_orders
		for (let i=0; i<game_state.orders.length; i++){
			game_state.orders[i].unique_id = i.toString();
		}
		game_state.player_stats = new_game_state.player_stats
		game_state.base_player_data = new_game_state.players
		game_state.is_sandbox = true

		queued_orders = []	//reset

		finished_load_function()

		game_state.data_has_loaded = true
	});
}

function clear_queued_orders(){
	queued_orders = []
}

export default { 
    setup,
    submit_sandbox_orders,
    submit_sandbox_order,
    resolve_sandbox,
    clear_queued_orders
}
