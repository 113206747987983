import React from "react";
import styled from "@emotion/styled";

import Page from "../../components/Page/Page";

const Main = styled.div`
    max-width: 600px;
    margin: 40px auto;
`;

const SetupGuide = () => {
    return (
        <Page>
            <Main>
                <h1>Changelog</h1>

                <p>Info about how the game has improved and changed over time.</p>

                <h2>Version 1.04 - 2023-11-29</h2>

                <ul>
                    <li>Bug fix: players exactly at the VP threshold were not able to join forces</li>
                </ul>

                <h2>Version 1.03 - 2023-02-28</h2>

                <ul>
                    <li>High Society option for more spacious map</li>
                    <li>High Society option for experimental same-day voting mode</li>
                    <li>Default starting positions separate players with similar colors</li>
                </ul>

                <h2>Version 1.02 - 2023-02-06</h2>

                <ul>
                    <li>Updated bot with slash commands</li>
                </ul>

                <h2>Version 1.01 - 2023-01-27</h2>

                <ul>
                    <li>Minor fixes to the map</li>
                    <li>Bug fix for the bot reading votes</li>
                    <li>kill_room admin command</li>
                </ul>

                <h2>Version 1.00 - 2023-01-24</h2>

                <ul>
                    <li>Official launch!</li>
                    <li>Some commands got new names</li>
                    <li>Fixes to sandbox on mobile</li>
                </ul>

                <h2>Version 0.71 - 2022-11-10</h2>

                <ul>
                    <li>Overhaul on Fast Mode (previously performance mode)</li>
                    <li>Better distribution of colors and patterns</li>
                    <li>Advanced Setup option (High Society only)</li>
                    <li>Proofreading</li>
                </ul>

                <h2>Version 0.70 - 2022-08-08</h2>

                <ul>
                    <li>Overhaul on voting events</li>
                    <li>New patterns for High Society upgrade</li>
                    <li>Bug fixes</li>
                    <li>Emoji + special character support on the map image</li>
                    <li>Command to refresh user name</li>
                    <li>Rewind tool</li>
                </ul>

                <h2>Version 0.69 - 2022-04-20</h2>

                <ul>
                    <li>Switching to a limited palette for player colors</li>
                    <li>Security improvements</li>
                    <li>Bug fixes</li>
                    <li>lol</li>
                </ul>

                <h2>Version 0.68 - 2022-03-20</h2>

                <ul>
                    <li>Join Forces option to combine players</li>
                    <li>Performance Mode (later Fast Mode)</li>
                    <li>Map View improvements</li>
                    <li>Fancier bot messages</li>
                </ul>

                <h2>Version 0.67 - 2021-11-01</h2>

                <ul>
                    <li>First semi-publicly available version of the game</li>
                </ul>

                <h2>Version 0.66 and older</h2>

                <ul>
                    <li>I'm not even sure</li>
                    <li>I've been working on this game for a while</li>
                    <li>I wrote the first line of code for it on a flight to Japan in 2019</li>
                </ul>

            </Main>
        </Page>
    );
};

export default SetupGuide;
