import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import { colors } from "../../styles";

const Hr = styled.hr`
    box-shadow: none;
    background: none;
    border: none;
    border-bottom: 1px solid ${colors.grayLight};
    width: 100%;
    display: block;
    margin: 0;

    ${({ customstyles }) => customstyles}
`;

const Divider = ({ styles }) => {
    return <Hr customstyles={styles} />;
};

Divider.propTypes = {
    styles: PropTypes.string,
};

export default Divider;
