import React, { useState } from "react";
import styled from "@emotion/styled";
import { easing, colors, mixins } from "../../styles";
import { mobile } from "../../util/media-query.js";

const CreditsTab = styled.div`
    font-family: Roboto, Helvetica, Arial, sans-serif;
    background-color: white;
    width: 70px;
    height: 30px;
    box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    position: absolute;
    right: 25px;
    bottom: ${(props) => (props.active ? "-5px" : "-100px")};
    transition: 0.4s all ${easing.easeOutCubic};
    text-align: center;
    line-height: 25px;
    cursor: pointer;
    font-size: 12px;

    ${mobile.css`
        display: none;
    `}
`;

const AdditionalHeader = styled.span``;

const CreditsContent = styled.div`
    position: absolute;
    right: 25px;
    height: 195px;
    width: 417px;
    font-size: 14px;
    padding: 20px;
    background-color: white;
    color: ${colors.black};
    box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px 5px 0 0;
    bottom: 0;
    transform: translate(0, ${(props) => (props.active ? "0" : "100%")});
    transition: 0.4s all ${easing.easeOutCubic};
    display: flex;
    flex-direction: column;
    z-index: 3;

    span {
        display: block;
        width: 100%;
        margin: 1px 0px;
        &:first-of-type {
            margin: 0px 0px 10px 0px;
        }
    }

    ${AdditionalHeader} {
        margin: 10px 0px 1px;
    }
`;

const Link = styled.a`
    color: black;
`;

const CloseButton = styled.div`
    position: absolute;
    right: 14px;
    top: 14px;
    ${mixins.transition()};
    cursor: pointer;
    font-weight: bold;
    color: ${colors.grayDark};
    font-size: 16px;
    &:hover {
        color: black;
    }

    &:focus {
        outline: none;
        text-decoration: underline;
    }
`;

// const AdditionalAssets = styled.div`
//     font-size: 10px;
// `;

const Credits = () => {
    const [expanded, setExpanded] = useState(false);
    return (
        <>
            {" "}
            <CreditsContent active={expanded}>
                <CloseButton onClick={() => setExpanded(!expanded)}>
                    X
                </CloseButton>
                <span>
                    <strong>Skirmish Society</strong> by{" "}
                    <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://andymakes.com/"
                    >
                        Andy Wallace
                    </Link>
                </span>
                <span>
                    Design & Programming by{" "}
                    <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://andymakes.com"
                    >
                        Andy Wallace
                    </Link>
                </span>
                
                <span>
                    Frontend Programming by{" "}
                    <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://walt.codes"
                    >
                        Walt Mitchell
                    </Link>{" "}
                    and{" "}
                    <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://robysoft.net"
                    >
                        Nick Santaniello
                    </Link>
                </span>
                <span>
                    Icons by{" "}
                    <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://twitter.com/iscariot83"
                    >
                        Chris Hernandez
                    </Link>
                </span>
                <span>
                    Backend Programming by{" "}
                    <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://dustmop.io"
                    >
                        Dustin Long
                    </Link>
                </span>
                <span>
                    Trailer by{" "}
                    <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://trashpandaqc.xyz/"
                    >
                        Trash Panda QC
                    </Link>
                </span>
                <span>
                    Skirmish Commissioner Icon by{" "}
                    <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://thenounproject.com/search/?q=general&i=688897"
                    >
                        lastspark
                    </Link>
                </span>
                <span>
                    <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        href="/legal"
                    >
                        Licenses and Credits
                    </Link>
                </span>
            </CreditsContent>
            <CreditsTab
                onClick={() => setExpanded(!expanded)}
                active={!expanded}
            >
                Credits
            </CreditsTab>
        </>
    );
};

export default Credits;
