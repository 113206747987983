import React from "react";
import PropTypes from "prop-types";
import P5Sketch from "react-p5-wrapper";
import styled from "@emotion/styled";

import Sidebar from "../../Sidebar/Sidebar";
import GamemasterMessage from "../../GamemasterMessage/GamemasterMessage";
import PlayerTooltip from "../../PlayerTooltip/PlayerTooltip";

const SketchCenterer = styled.div`
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
`;

const GameOverBoard = ({
    sketch,
    isSandbox,
    isRender,
    gameState,
    wrapperRef,
}) => {
    // TODO: game over UI
    // console.log("showing game over board");

    return (
        <>
            {sketch && (
                <SketchCenterer>
                    <P5Sketch
                        isSandbox={isSandbox}
                        sketch={sketch}
                        reactGameState={gameState}
                    />
                </SketchCenterer>
            )}
            {!isRender && (
                <>
                    <PlayerTooltip boardRef={wrapperRef} />
                    <GamemasterMessage />
                    <Sidebar />
                </>
            )}
        </>
    );
};

GameOverBoard.propTypes = {
    sketch: PropTypes.func,
    isSandbox: PropTypes.bool,
    isRender: PropTypes.bool,
    gameState: PropTypes.object,
    wrapperRef: PropTypes.object,
};

export default GameOverBoard;
