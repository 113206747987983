import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import Tooltip from "../Tooltip/Tooltip";
import useGameState from "../../hooks/useGameState";

const EffectDescription = styled.div`
    background-color: white;
    width: 250px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    padding: 10px 15px;
    font-size: 14px;
    color: black;
    z-index: 2;
`;

const EffectSpan = styled.span`
    font-weight: bold;
    cursor: default;
    text-decoration: underline;

    &:hover {
        color: black;
    }

    ${({ styleString }) => styleString};
`;

const WinnerParagraph = styled.p`
    margin: 0 0 5px;
    font-weight: bold;
`;

const PlayerSpan = styled.span`
    color: ${({ color }) => color};
`;

const EffectName = ({
    disp_name: name,
    description,
    descriptions,
    choices,
    reminder_text,
    styleString,
    id,
    winner,
    showPlayer,
    is_player_vote,
}) => {
   
    const { players } = useGameState();

    //player votes
    if (is_player_vote==true || is_player_vote==null){

        // Get color of affected player
        const affectedPlayer = players.find((player) => player.id === winner);
        const color = affectedPlayer ? `hsl(${affectedPlayer.hue}, 90%, 50%)` : "";

        return (
            <Tooltip
                renderTooltip={() => {
                    return (
                        <>
                            <EffectDescription>
                                {showPlayer && (
                                    <WinnerParagraph>
                                        Affected player:{" "}
                                        <PlayerSpan color={color}>
                                            {affectedPlayer.name}
                                        </PlayerSpan>
                                    </WinnerParagraph>
                                )}
                                {description}
                            </EffectDescription>
                        </>
                    );
                }}
            >
                <EffectSpan styleString={styleString}>{name}</EffectSpan>
            </Tooltip>
        );
    }
    //event votes
    else{
        if (winner){
            let clean_name = winner[0].toUpperCase() + winner.substring(1);
            return (
                <Tooltip
                    renderTooltip={() => {
                        return (
                            <>
                                <EffectDescription>
                                    {description}
                                </EffectDescription>
                            </>
                        );
                    }}
                >
                    <EffectSpan styleString={styleString}>{clean_name}</EffectSpan>
                </Tooltip>
            );
         }
         else{
            return (
                <Tooltip
                    renderTooltip={() => {
                        return (
                            <>
                                <EffectDescription>
                                    <p>
                                    <b>{choices[0].toUpperCase()}</b>: {descriptions[0]}
                                    </p>
                                    <p>
                                    <b>{choices[1].toUpperCase()}</b>: {descriptions[1]}
                                    </p>
                                    <p>
                                    <i>{reminder_text}</i>
                                    </p>
                                </EffectDescription>
                            </>
                        );
                    }}
                >
                    <EffectSpan styleString={styleString}>{name}</EffectSpan>
                </Tooltip>
            );
         }
    }
};

EffectName.propTypes = {
    disp_name: PropTypes.string,
    description: PropTypes.string,
    styleString: PropTypes.string,
    id: PropTypes.string,
    winner: PropTypes.string,
    showPlayer: PropTypes.bool,
};

export default EffectName;
