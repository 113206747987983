import React from "react";
import { Router } from "@reach/router";

import Home from "./pages/Home/Home";
import Play from "./pages/Play/Play";
import Rules from "./pages/Rules/Rules";
import CodeOfConduct from "./pages/CodeOfConduct/CodeOfConduct";
import SetupGuide from "./pages/SetupGuide/SetupGuide";
import Shop from "./pages/Shop/Shop";
import Legal from "./pages/Legal/Legal";
import Changelog from "./pages/Changelog/Changelog";
import Sandbox from "./pages/Sandbox/Sandbox";
import Renderer from "./pages/Renderer/Renderer";
import Error404 from "./pages/Error404/Error404";
import SessionInstance from "./components/SessionInstance/SessionInstance";

import "./App.css";

function App() {
    return (
        <Router>
            <SessionInstance path="/">
                <Home path="/" />
                <Play path="/play" />
                <Rules path="/rules" />
                <CodeOfConduct path="/code-of-conduct" />
                <SetupGuide path="/setup-guide" />
                <Sandbox path="/sandbox" />
                <Shop path="/shop" />
                <Legal path="/legal" />
                <Changelog path="/changelog" />
                <Renderer path="/render" />
                <Error404 default />
            </SessionInstance>
        </Router>
    );
}

export default App;
