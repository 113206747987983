import React from "react";
// import PropTypes from "prop-types";
import styled from "@emotion/styled";

import { colors, mixins } from "../../styles";

const DefaultTextarea = styled.textarea`
    ${mixins.transition()};
    border: 1px solid ${colors.gray};
    border-radius: 4px;
    width: 100%;
    padding: 10px;

    &:focus,
    &:active {
        border-color: black;
        outline: none;
    }
`;

const Textarea = ({ ...rest }) => {
    return <DefaultTextarea {...rest} />;
};

Textarea.propTypes = {
    // someProp: PropTypes.string
};

export default Textarea;
