import React from "react";
import styled from "@emotion/styled";

import Page from "../../components/Page/Page";
import {
    DefaultLink,
    DefaultExternalLink,
} from "../../components/Button/Button";
import RoomSelector from "../../components/RoomSelector/RoomSelector";

const Masthead = styled.div`
    background: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 60px 0;
    text-align: center;
`;

const MastheadHeader = styled.h1`
    font-size: 48px;
    font-weight: normal;
    max-width: 910px;
    margin: 0 auto;
`;

const MastheadIcon = styled.img`
    display: block;
    margin: 40px auto;
`;

const MastheadVideo = styled.iframe`
    display: block;
    margin: 40px auto;
    width: 640px;
    height: 360px;

    @media screen and (max-width: 768px) {
        width: 320px;
        height: 180px;
    }
`;

const MastheadIntro = styled.p`
    font-size: 16px;
    line-height: 19px;
    margin: 0 auto 30px;
    max-width: 500px;
`;

const MastheadButtonWrapper = styled.div`
    display: flex;
    margin: 0 auto;
    padding-bottom: 10px;
`;

const MastheadButton = `
    display: inline-block;
    margin: 0 5px;
`;

const MastheadButtonExternal = styled(DefaultExternalLink)`
    ${MastheadButton}
`;

const MastheadButtonInternal = styled(DefaultLink)`
    ${MastheadButton}
`;

const SelectorWrapper = styled.div`
    padding: 0 0 60px;
`;

const Home = () => {
    return (
        <Page>
            <Masthead>
                <MastheadHeader>
                    <strong>Skirmish Society</strong> is a daily territory
                    control game embedded in your social network.
                </MastheadHeader>

                {/* <MastheadIcon src="/pic/logo/ExportStack@2x_crest.png" /> */}
                
                <MastheadVideo frameBorder="0"
                        src="https://www.youtube.com/embed/PpN41oLDsl4">
                </MastheadVideo>

                <MastheadIntro>
                    Played online and in Discord, Skirmish Society is a game of
                    diplomacy and espionage that turns your DMs into marching
                    orders. Invite our gamemaster bot to your server and they’ll
                    generate a dynamic world for you and your friends to conquer
                    -- cooperatively or otherwise.
                </MastheadIntro>

                <MastheadButtonWrapper>
                    <MastheadButtonInternal primary minSize to="/setup-guide">
                        Install on Your Server (Free!)
                    </MastheadButtonInternal>
                </MastheadButtonWrapper>

                <MastheadButtonWrapper>
                    <MastheadButtonInternal minSize to="/shop">
                        Upgrade Your Account
                    </MastheadButtonInternal>
                </MastheadButtonWrapper>

                <MastheadButtonWrapper>
                   <MastheadButtonInternal minSize to="/rules">
                        Read the Rules
                    </MastheadButtonInternal>
                </MastheadButtonWrapper>

                <MastheadButtonWrapper>
                    <MastheadButtonExternal minSize href="https://discord.gg/zBSn8p9qtn">
                        Join the Discord
                    </MastheadButtonExternal>
                </MastheadButtonWrapper>
                {/* <MastheadButtonWrapper>
                    <MastheadButtonExternal minSize href="https://tinyletter.com/AndyMakes">
                        Join the Mailing List
                    </MastheadButtonExternal>
                </MastheadButtonWrapper> */}


                
            </Masthead>

            <SelectorWrapper>
                <RoomSelector />
            </SelectorWrapper>

        </Page>
    );
};

export default Home;
