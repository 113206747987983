import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import Order from "../../Order/Order";
import EffectName from "../../EffectName/EffectName";
import { easing } from "../../../styles";
import useGameState from "../../../hooks/useGameState";

import ProgressBar from "./ProgressBar";

const PlayerWrapper = styled.div`
    padding: 5px 30px 5px 25px;
    position: relative;
    margin: 0 -30px;
    opacity: ${({ isFaded }) => (isFaded ? "0.25" : "1")};
    transition: 0.3s all ${easing.easeOutCubic},
        ${({ isFaded }) => (isFaded ? "0.3s" : "2.5s")} opacity
            ${easing.easeOutCubic};
    border-left: 5px solid ${({ borderColor }) => borderColor};

    &:hover {
        border-left: 5px solid ${({ color }) => color};
    }
`;

const PlayerNameWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const PlayerName = styled.div`
    color: ${(props) => props.color};
    text-decoration: ${(props) => (props.resigned || props.is_dead ? "line-through" : "none")};
    font-weight: bold;
    font-size: 14px;
`;

const EffectBadge = styled.div`
    padding: 5px;
    margin: 5px 0;
    background: #f3f3f3;
    color: #222;
    font-size: 11px;
    margin: 5px 0;
`;

const PlayerStats = styled.div`
    color: grey;
    font-size: 12px;
    font-style: italic;
`;

const RecentOrders = styled.div`
    border: 1px solid #ebebeb;
    border-radius: 3px;
    margin: 5px 0px 0px;
    padding: 10px 14px;
    display: ${(props) => (props.shown ? "block;" : "none;")};
    font-size: 14px;
    line-height: 1.4;

    code {
        display: block;
    }
`;

const PlayerInfoContainer = styled.div`
    cursor: pointer;

    &:hover ${PlayerName} {
        text-decoration: underline;
    }

    &:hover ${PlayerStats} {
        color: black;
    }
`;

const PlayerDisplay = ({
    name,
    hue,
    stats,
    orders,
    ordersShown,
    resigned,
    simplified,
    highestPossibleScore,
    ...rest
}) => {
    const thisRef = useRef(null);
    const {
        highlightedPlayer,
        setHighlightedPlayer,
        centeredPlayer,
        setCenteredPlayer,
    } = useGameState();
    const isHighlighted = highlightedPlayer && highlightedPlayer.name === name;
    const isCentered = centeredPlayer && centeredPlayer.name === name;

    // assign mouse callbacks on mount
    useEffect(() => {
        const playerObject = {
            ...rest,
            name,
            hue,
            stats,
            orders,
        };
        const onClick = () => setCenteredPlayer(playerObject);
        const onMouseEnter = () => setHighlightedPlayer(playerObject);
        const onMouseLeave = () => setHighlightedPlayer(null);

        const elem = thisRef.current;
        if (elem) {
            elem.addEventListener("click", onClick);
            elem.addEventListener("mouseenter", onMouseEnter);
            elem.addEventListener("mouseleave", onMouseLeave);
        }

        return () => {
            if (elem) {
                elem.removeEventListener("click", onClick);
                elem.removeEventListener("mouseenter", onMouseEnter);
                elem.removeEventListener("mouseleave", onMouseLeave);
            }
        };
    }, []); // eslint-disable-line

    // center on click
    useEffect(() => {
        if (isCentered) {
            thisRef.current.scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }
    }, [isCentered]);

    const {
        territory_count: territories,
        supply_points: supplyPoints,
        victory_points: victoryPoints,
        prev_victory_points: prevVictoryPoints,
        armies,
        active_vote_event: activeVote,
    } = stats;

    const deltaVp = victoryPoints - prevVictoryPoints;
    const color = `hsl(${hue}, ${hue < 170 ? "60%" : "90%"}, 50%)`;

    return (
        <PlayerWrapper
            isFaded={!isCentered && centeredPlayer}
            color={color}
            isHighlighted={isHighlighted}
            isCentered={isCentered}
            borderColor={isHighlighted || isCentered ? color : "transparent"}
        >
            <PlayerInfoContainer ref={thisRef}>
                <PlayerNameWrapper>
                    <PlayerName
                        color={resigned ? "#888" : color}
                        resigned={resigned}
                        is_dead = {stats.is_dead}
                    >
                        {/* TODO: the victory condition (e.g. VP, territories, etc.) should probably be set elsewhere */}
                        {name}: {victoryPoints}/{highestPossibleScore} VP
                        {deltaVp > 0 &&
                            ` (+${deltaVp} this
                        turn)`}
                    </PlayerName>
                </PlayerNameWrapper>
                <ProgressBar
                    prevProgress={prevVictoryPoints}
                    progress={victoryPoints}
                    maximum= { Math.max(highestPossibleScore,victoryPoints)}
                    color={color}
                />
                {!simplified && (
                    <PlayerStats>
                        {territories} territories, {armies} armies,{" "}
                        {supplyPoints} barracks
                    </PlayerStats>
                )}
            </PlayerInfoContainer>
            {activeVote && (
                <EffectBadge color={color}>
                    player was affected by:{" "}
                    <EffectName
                        {...activeVote}
                        styleString={`
                            font-size: 13px;
                        `}
                    />
                </EffectBadge>
            )}
            {!simplified && orders && orders.length > 0 && (
                <RecentOrders shown={ordersShown}>
                    {orders.map((order) => {
                        return <Order key={order.unique_id} {...order} />;
                    })}
                </RecentOrders>
            )}
        </PlayerWrapper>
    );
};

PlayerDisplay.defaultProps = {
    name: "",
    stats: {
        territory_count: 0,
        armies: 0,
        supply_points: 0,
        victory_points: 0,
        prev_victory_points: 0,
    },
    ordersShown: false,
    simplified: false,
};

PlayerDisplay.propTypes = {
    name: PropTypes.string,
    hue: PropTypes.number,
    stats: PropTypes.shape({
        active_vote_event: PropTypes.object, // This was listed as a string... but why?
        armies: PropTypes.number,
        id: PropTypes.string,
        is_dead: PropTypes.bool,
        name: PropTypes.string,
        permanent_vp: PropTypes.number, // Are these properties still valid?
        prev_armies: PropTypes.number,
        prev_territory_count: PropTypes.number,
        prev_victory_points: PropTypes.number,
        supply_points: PropTypes.number,
        territory_count: PropTypes.number,
        turn_num: PropTypes.number,
        victory_points: PropTypes.number,
        vp_from_chips: PropTypes.number,
        waiting_to_join: PropTypes.bool,
    }),
    // TODO: These types should probably be stored elserwhere -
    orders: PropTypes.arrayOf(
        PropTypes.shape({
            db_doc: PropTypes.string,
            failed_hold: PropTypes.bool,
            in_conflict: PropTypes.bool,
            is_priority: PropTypes.bool,
            orig_order: PropTypes.string,
            orig_type: PropTypes.string,
            outcome: PropTypes.string,
            player: PropTypes.string,
            strength: PropTypes.number,
            supply_order: PropTypes.bool,
            territory: PropTypes.string,
            timestamp: PropTypes.object,
            turn_num: PropTypes.number,
            type: PropTypes.string,
            valid: PropTypes.bool,
            was_successful: PropTypes.bool,
            unique_id: PropTypes.string,
        })
    ),
    ordersShown: PropTypes.bool,
    simplified: PropTypes.bool,
    resigned: PropTypes.bool,
    highestPossibleScore: PropTypes.number,
};

export default PlayerDisplay;