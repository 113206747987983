import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import CheckboxToggle from "../CheckboxToggle/CheckboxToggle";
import Button from "../Button/Button";
import Divider from "../Divider/Divider";
import OrdersCheckbox from "../OrdersCheckbox/OrdersCheckbox";
import TurnControls from "../TurnControls/TurnControls";
import Explainer from "./sidebar/Explainer";
import { easing, colors, mixins } from "../../styles";

import PlayerDisplay from "./sidebar/PlayerDisplay";

const ExpandToggle = styled.button`
    appearance: none;
    width: 75px;
    height: 50px;
    border: 2px solid transparent;
    border-right: 0px solid black;
    cursor: pointer;
    top: 90px;
    position: fixed;
    background-image: url("/pic/controls.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30px auto;
    right: ${(props) => (props.active ? "0px" : "-75px")};
    transition: 0.4s all ${easing.easeOutCubic};
    background-color: white;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

    &:focus {
        border-color: black;
    }

    &:hover {
        background-color: ${colors.grayLight};
    }
`;

const SidebarContainer = styled.aside`
    transition: 0.3s all ${easing.easeOutCubic};
    transform: translate3d(${(props) => (props.active ? "0%" : "100%")}, 0, 0);
    right: 0;
    top: 60px;
    display: flex;
    width: 100%;
    max-width: 100%;
    position: fixed;
    flex-direction: column;
    flex-grow: 1;
    background-color: white;
    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 30px 30px 75px;
    height: calc(100% - 60px);
    z-index: 2;

    @media screen and (min-width: 768px) {
        width: 380px;
        top: 70px;
    }
`;

export const ContentRow = styled.div`
    flex-direction: row;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 14px;
    margin: 3px 0 5px;
    padding-right: 40px;
`;

const SidebarHeader = styled.div`
    padding: 15px 0 0;
`;

const TurnControlsWrapper = styled.div`
    flex-direction: row;
    display: flex;
    align-items: center;
    margin: 0 0 20px;
    padding-right: 50px;
`;

const SortingWrapper = styled.div`
    flex-direction: row;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 14px;
    margin: 10px 0 0;
`;

const TitleContainer = styled(ContentRow)`
    justify-content: space-between;
`;

const Title = styled.h2`
    display: inline-block;
    padding: 0;
    margin: 0;
    font-size: 21px;
`;

// const TurnNumber = styled.span`
//     color: ${colors.grayDark};
//     display: inline-block;
//     font-style: italic;
//     color: #8f8f8f;
//     margin: 0 0 0 5px;
//     font-size: 14px;
//     font-weight: normal;
// `;

const CloseToggle = styled.button`
    ${mixins.transition()};
    cursor: pointer;
    font-weight: bold;
    color: ${colors.grayDark};
    display: inline-block;
    width: 40px;
    font-size: 16px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border: none;
    box-shadow: none;
    appearance: none;
    background: none;
    position: absolute;
    top: 24px;
    right: 18px;

    &:hover {
        color: black;
    }

    &:focus {
        outline: none;
        text-decoration: underline;
    }
`;

const SortingLabel = styled.span`
    display: inline-block;
    margin-right: 5px;
`;

const PlayersPanel = styled.div`
    margin-top: 10px;
`;

const SidebarUi = ({
    players,
    sortButtons,
    active,
    openSidebar,
    closeSidebar,
    toggleOrders,
    ordersShown,
    toggleSimplified,
    simplified,
    sortCriteria,
    highestPossibleScore,
    onMouseEnter,
    onMouseLeave,
}) => {
    return (
        <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <SidebarContainer aria-disabled={!active} active={active}>
                <CloseToggle onClick={closeSidebar}>X</CloseToggle>

                <TurnControlsWrapper>
                    <TurnControls />
                </TurnControlsWrapper>

                <Divider />

                <SidebarHeader>
                    <TitleContainer>
                        <Title>
                            Resolution
                            {/* <TurnNumber>Turn {turn}</TurnNumber> */}
                        </Title>
                    </TitleContainer>
                    <ContentRow>
                        <Explainer />
                    </ContentRow>
                </SidebarHeader>

                {!simplified && (
                    <ContentRow>
                        <OrdersCheckbox />
                    </ContentRow>
                )}

                {!simplified && (
                    <ContentRow>
                        <CheckboxToggle
                            id="sidebar-orders-shown-toggle"
                            onChange={() => toggleOrders(!ordersShown)}
                            checked={ordersShown}
                        >
                            Toggle orders text
                        </CheckboxToggle>
                    </ContentRow>
                )}

                <ContentRow>
                    <CheckboxToggle
                        id="sidebar-advanced-shown-toggle"
                        onChange={() => toggleSimplified(!simplified)}
                        checked={simplified}
                    >
                        Simplified standings
                    </CheckboxToggle>
                </ContentRow>

                {!simplified && (
                    <SortingWrapper>
                        <SortingLabel>Sort by:</SortingLabel>
                        {sortButtons.map(({ key, label, onClick }) => {
                            return (
                                <Button
                                    small
                                    key={key}
                                    primary={sortCriteria === key}
                                    onClick={onClick}
                                    style={{
                                        margin: "0px 5px 5px 0",
                                    }}
                                >
                                    {label}
                                </Button>
                            );
                        })}
                    </SortingWrapper>
                )}

                <PlayersPanel>
                    {players !== undefined &&
                        players.map((player) => {
                            return (
                                <PlayerDisplay
                                    key={player.name}
                                    {...player}
                                    ordersShown={ordersShown}
                                    simplified={simplified}
                                    highestPossibleScore={highestPossibleScore}
                                />
                            );
                        })}
                </PlayersPanel>
            </SidebarContainer>
            <ExpandToggle
                active={!active}
                aria-disabled={active}
                onClick={openSidebar}
            />
        </div>
    );
};

SidebarUi.propTypes = {
    players: PropTypes.arrayOf(PropTypes.object),
    sortButtons: PropTypes.arrayOf(PropTypes.object),
    active: PropTypes.bool,
    openSidebar: PropTypes.func,
    closeSidebar: PropTypes.func,
    toggleOrders: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onMouseEnter: PropTypes.func,
    ordersShown: PropTypes.bool,
    toggleSimplified: PropTypes.func,
    simplified: PropTypes.bool,
    turn: PropTypes.number,
    sortCriteria: PropTypes.string,
    highestPossibleScore: PropTypes.number,
};

export default SidebarUi;
