import React from "react";
import styled from "@emotion/styled";
import { useLocation } from "@reach/router";
import querystring from "query-string";

import Page from "../../components/Page/Page";
import GameBoard from "../../components/GameBoard/GameBoard";
import GameInstance from "../../components/GameInstance/GameInstance";
import RoomSelector from "../../components/RoomSelector/RoomSelector";

const BoardWrapper = styled.div`
    background: white;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const SelectorWrapper = styled.div`
    padding: 60px 0;
`;

const EnterRoomHeader = styled.h1`
    text-align: center;
    margin: 0 auto 20px;
`;

const EnterRoomDetails = styled.p`
    text-align: center;
    max-width: 520px;
    margin: 0 auto 60px;
`;

const Play = () => {
    // don't use session state, we need the URL param for this view
    const { search } = useLocation();
    const { room } = querystring.parse(search);

    // show the room selector if we don't have a URL param
    if (!room) {
        return (
            <Page>
                <SelectorWrapper>
                    <EnterRoomHeader>
                        Looking for a specific game?
                    </EnterRoomHeader>
                    <EnterRoomDetails>
                        We'll try to auto-magically drop you back into your
                        current game, but if you're in multiple games or just
                        lost your link, you can enter your room key manually
                        here.
                    </EnterRoomDetails>
                    <RoomSelector header="Enter a room" />
                </SelectorWrapper>
            </Page>
        );
    }

    // default game page
    return (
        <GameInstance key={room}>
            <Page Wrapper={BoardWrapper} room={room} wrapContent={false}>
                <GameBoard />
            </Page>
        </GameInstance>
    );
};

export default Play;
