import React, { useCallback, useState } from "react";
import styled from "@emotion/styled";
import { Link, navigate } from "@reach/router";
import PropTypes from "prop-types";

import { colors } from "../../styles";
import { DefaultInput } from "../Button/Button";

const Wrapper = styled.div`
    max-width: 630px;
    margin: 0 auto;
    padding: 24px;
    background: ${colors.white};
    border: 1px solid ${colors.black};
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(68, 68, 68, 0.25);
`;

const Form = styled.form``;

const FormRow = styled.div`
    @media screen and (min-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
`;

const H3 = styled.h3`
    margin: 0;
    font-weight: bold;
    font-size: 24px;
`;

const Label = styled.label`
    flex: 1;

    @media screen and (min-width: 768px) {
        display: flex;
        align-items: center;
        margin-left: 20px;
    }
`;

const Input = styled.input`
    height: 40px;
    background: ${colors.white};
    border: 1px solid ${({ hasError }) => (hasError ? "red" : colors.black)};
    border-radius: 2px;
    margin-left: 20px;
    flex: 1;
    text-indent: 10px;
`;

const Submit = styled(DefaultInput)`
    margin: 10px 0 0;
    width: 80px; // why is this necessary?
`;

const LastKnown = styled.p`
    margin: 20px 0 0;
    font-style: italic;
    color: ${colors.grayDark};
    flex: 1;
`;

const RoomLink = styled.span`
    a {
        font-weight: bold;
        color: ${colors.black};
    }
`;

const RoomSelector = ({ header }) => {
    const [lastKnownRoom] = useState(
        localStorage.getItem("lastRoomID") || null
    );

    const [roomId, setRoomId] = useState("");
    const [error, setError] = useState(null);

    const onChange = useCallback((e, what) => {
        const newInputValue = e.currentTarget.value;
        setError(null);

        // TODO: validate inputs
        setRoomId(newInputValue);
    }, []);

    const onSubmit = useCallback(
        (e) => {
            e.preventDefault();

            if (roomId?.length) {
                navigate(`/play?room=${roomId}`);
            } else {
                setError({ message: "No room ID entered." });
            }
        },
        [roomId]
    );

    return (
        <Wrapper>
            <Form onSubmit={onSubmit}>
                <FormRow>
                    <H3>{header}</H3>
                    <Label htmlFor="room-selector-input">
                        Input your room key here.
                        <Input
                            id="room-selector-input"
                            type="text"
                            value={roomId}
                            onChange={onChange}
                            hasError={error !== null}
                        />
                    </Label>
                </FormRow>
                <FormRow>
                    {lastKnownRoom && (
                        <LastKnown>
                            Your last known room key is{" "}
                            <RoomLink>
                                <Link to={`/play?room=${lastKnownRoom}`}>
                                    {lastKnownRoom}
                                </Link>
                            </RoomLink>
                        </LastKnown>
                    )}
                    <Submit primary type="submit" value="Submit" />
                </FormRow>
            </Form>
        </Wrapper>
    );
};

RoomSelector.propTypes = {
    header: PropTypes.string,
};

RoomSelector.defaultProps = {
    header: "In a match?",
};

export default RoomSelector;
