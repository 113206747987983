export const ProcessLocalStorageValue = (setting) => {
    // This is needed because local storage can't store booleans :/
    switch (setting) {
        case "off":
            return false;
        case "on":
            return true;
        default:
            return null;
    }
};
