import hex_manager from "./hex_manager.js"

function new_territory_from_json(obj, hexes) {
    const t = {};
    t.disp_name = obj.disp_name;
    t.id = obj.id;
    t.lock_group = obj.lock_group;
    t.is_supply = obj.is_supply; 
    //t.impassable = obj.impassable;
    //t.do_not_spawn_army = obj.do_not_spawn_army;
    t.value = obj.value;        

    t.oversea_neighbor_id = obj.oversea_neighbor;
    t.oversea_neighbor = null   //actual oversea neighbor territory reference will be set in data_process

    t.was_conflict = false

    t.hexes = [];
    for (let i = 0; i < obj.hexes.length; i++) {
        const hx = parseInt(obj.hexes[i].x, 10);
        const hy = parseInt(obj.hexes[i].y, 10);
        const hex = hexes[hx][hy];
        //hex.on_map_border = obj.hexes[i].on_map_border  //this was for testing map-gen and is not needed for the game
        t.hexes.push(hex);
    }

    
    if (obj.oversea_hex){
        t.oversea_hex = hexes[parseInt(obj.oversea_hex.x, 10)][parseInt(obj.oversea_hex.y, 10)]
    }

    if (obj.center_hex) {
        t.center_hex = hexes[obj.center_hex.x][obj.center_hex.y];
        t.center_pos = hex_manager.get_hex_center(t.center_hex);

        // used when a move order succeeds so the start point of the line is closer to the name than a potential other army in tthat territory
        t.army_start_pos = { x: t.center_pos.x, y: t.center_pos.y + 20 };
    }
    if (obj.army_hex) {
        t.army_hex = hexes[obj.army_hex.x][obj.army_hex.y];
        t.army_pos = hex_manager.get_hex_center(t.army_hex);
    }

    return t;
}

// function get_cur_territory_by_id(cur_territories, id) {
//     for (let i = 0; i < cur_territories.length; i++) {
//         if (cur_territories[i].id == id) {
//             return cur_territories[i];
//         }
//     }
//     console.log(`could not find id:${id}`);
//     return null;
// }

function get_territory_from_id(id, territories) {
    for (let i = 0; i < territories.length; i++) {
        if (territories[i].id == id) {
            return territories[i];
        }
    }
    return null;
}

function get_order_for_territory(ter_id, orders) {
    for (let i = 0; i < orders.length; i++) {
        if (orders[i].territory == ter_id && orders[i].valid) {
            return orders[i];
        }
    }
    // console.log("COULD NOT FIND ORDER FOR:"+ter_id)
    return null;
}

export default { 
    new_territory_from_json,
    get_territory_from_id,
    get_order_for_territory
}

