import React from "react";
import PropTypes from "prop-types";

import useGameState from "../../hooks/useGameState";
import CheckboxToggle from "../CheckboxToggle/CheckboxToggle.jsx";

const OrdersCheckbox = ({ text }) => {
    const { setShowOrders, showOrders } = useGameState();

    const onCheckboxChange = (e) => {
        const shouldShowOrders = e.currentTarget.checked;
        setShowOrders(shouldShowOrders);
    };

    return (
        <CheckboxToggle
            id="show_orders_checkbox"
            onChange={onCheckboxChange}
            checked={showOrders}
        >
            {text}
        </CheckboxToggle>
    );
};

OrdersCheckbox.propTypes = {
    text: PropTypes.string,
};

OrdersCheckbox.defaultProps = {
    text: "Toggle orders illustration",
};

export default OrdersCheckbox;
