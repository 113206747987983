import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import { mixins, colors } from "../../styles";

const WrapperLabel = styled.label`
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
        cursor: pointer;
    }
`;

const Checkbox = styled.input`
    appearance: none;
    display: block;
    border: 1px solid black;
    border-radius: 2px;
    box-shadow: none;
    margin: 0 9px 0 0;
    width: 20px;
    height: 20px;
    position: relative;

    &:focus {
        outline: none;
    }

    &::after {
        ${mixins.transition("0.15s")};
        content: "";
        width: 12px;
        height: 12px;
        background: ${colors.gray};
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -6px 0 0 -6px;
        transform: scale(0);
    }

    &:checked::after {
        transform: scale(1);
    }
`;

const Text = styled.span`
    input:focus + &,
    label:hover > & {
        text-decoration: underline;
    }
`;

const CheckboxToggle = ({ id, children, onChange, checked }) => {
    return (
        <WrapperLabel htmlFor={id}>
            <Checkbox
                type="checkbox"
                id={id}
                onChange={onChange}
                checked={checked}
            />
            <Text>{children}</Text>
        </WrapperLabel>
    );
};

CheckboxToggle.propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    checked: PropTypes.bool.isRequired,
};

export default CheckboxToggle;
