import React from "react";
import styled from "@emotion/styled";

import PlayerName from "../../PlayerName/PlayerName";
import useGameState from "../../../hooks/useGameState";
import EffectName from "../../EffectName/EffectName";

const Wrapper = styled.div`
    margin: 0;
`;

const Paragraph = styled.p`
    margin: 0 0 10px;
`;

const Explainer = () => {
    const { players, roomInfo, turn, totalTurns } = useGameState();

    const { winner, score_to_win } = roomInfo;
    //const winningPlayer = players.find((player) => winner && player.name === winner);
    const winningPlayer =
        winner && players.find((player) => player.id === winner.id);

    // Get highest VP score
    const leadingPlayer = players.reduce((prev, current) => {
        return prev.stats.victory_points >= current.stats.victory_points
            ? prev
            : current;
    });
    const highScore = leadingPlayer.stats.victory_points;

    // Get all players with this score
    const winningPlayers = players.filter((player) => {
        return player.stats.victory_points >= highScore;
    });

    // Any players affected by... effects
    const affectedPlayer = players.find((player) => {
        return player.stats.active_vote_event; // falsey if undefined or null
    });

    // Function to loop over and render each player
    const renderPlayers = () =>
        winningPlayers.map((player, i) => {
            const isLast = i === winningPlayers.length - 1;
            const isLastOfMany = isLast && winningPlayers.length > 1;

            return (
                <React.Fragment key={player.name}>
                    {isLastOfMany ? "and " : " "}
                    <PlayerName {...player} />
                    {isLast ? " " : ", "}
                </React.Fragment>
            );
        });

    return (
        <Wrapper>
            <Paragraph>
                {winner && turn === totalTurns ? (
                    <strong>
                        
                        <PlayerName {...winningPlayer} /> wins!
                    </strong>
                ) : (
                    <>
                        {winningPlayers.length === players.length
                            ? "All players "
                            : renderPlayers()}
                        {winningPlayers.length === 1 ? "leads" : "tied"} with{" "}
                        {highScore}. Players require{" "}
                        <strong>{score_to_win} VP to win.</strong>{" "}
                    </>
                )}
            </Paragraph>
            {affectedPlayer && (
                <Paragraph>
                    This resolution, <PlayerName {...affectedPlayer} /> was
                    affected by{" "}
                    <EffectName {...affectedPlayer.stats.active_vote_event} />.
                </Paragraph>
            )}
        </Wrapper>
    );
};

Explainer.propTypes = {};

export default Explainer;
