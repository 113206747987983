import React, { useState } from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import querystring from "query-string";
import { useLocation } from "@reach/router";

import Page from "../../components/Page/Page";
import RoomSelector from "../../components/RoomSelector/RoomSelector";
import { colors } from "../../styles";

const Wrapper = styled.div`
    font-family: Roboto, Helvetica, Arial, sans-serif;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;

    h1 {
        font-size: 142px;
        line-height: 123px;
        margin: 0;
        padding: 0;
        margin: 60px 0px 10px;
    }

    h2 {
        font-size: 36px;
        margin: 0;
        padding: 0;
    }

    p {
        max-width: 400px;
    }

    a {
        color: ${colors.black};
        font-weight: bold;
    }
`;

const SelectorWrapper = styled.div`
    padding: 60px 0;
`;

export const Error404Content = ({ badRoom = false }) => {
    const { search } = useLocation();
    const { room } = querystring.parse(search);
    const [lastKnownRoom] = useState(
        localStorage.getItem("lastRoomID") || null
    );

    return (
        <Wrapper>
            <h1>404</h1>
            <h2>{badRoom ? `Room "${room}"` : "Page"} Not Found</h2>
            <p>
                Oops! Looks like that {badRoom ? "room" : "page"} doesn't exist.
                Want to head back to the <a href="/">homepage</a>?
            </p>
            {lastKnownRoom && (
                <p>
                    Not to snoop, but we noticed you were recently checking out{" "}
                    <a href={`/?room=${lastKnownRoom}`}>a game in this room</a>{" "}
                    with id <strong>"{lastKnownRoom}"</strong>. Maybe that's
                    what you were looking for?
                </p>
            )}
            <SelectorWrapper>
                <RoomSelector />
            </SelectorWrapper>
        </Wrapper>
    );
};

Error404Content.propTypes = {
    badRoom: PropTypes.bool,
};

const Error404 = () => {
    return (
        <Page>
            <Error404Content />
        </Page>
    );
};

export default Error404;
