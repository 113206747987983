import easings from "./_easing";

const transition = (
    duration = "0.3s",
    property = "all",
    delay = "0s",
    easing = easings.easeOutQuint
) => `transition: ${duration} ${property} ${delay} ${easing}`;

export default {
	transition
};
