import React, { useState, useEffect } from "react";
import { useLocation } from "@reach/router";

import GameStateContext from "../../context/GameStateContext";
import { ProcessLocalStorageValue } from "../../util/local-storage-helper";
import isMobile from "../../util/is-mobile";

const GameInstance = ({ children }) => {
    const { pathname } = useLocation();
    const isRender = pathname === "/render";

    // TODO: useReducer
    const [showOrders, setShowOrders] = useState(true);
    const [roomId, setRoomId] = useState(-1);
    const [roomInfo, setRoomInfo] = useState({});
    const [turn, setTurn] = useState(-1);
    const [totalTurns, setTotalTurns] = useState(0);
    const [players, setPlayers] = useState([]);
    const [orders, setOrders] = useState([]);
    const [badRoom, setBadRoom] = useState(false);
    const [hoveringCanvas, setHoveringCanvas] = useState(true);
    const [sidebarActive, setSidebarActive] = useState(
        !isMobile() && !isRender
    );
    const [sandboxInfo, setSandboxInfo] = useState({ isSandbox: false });
    const [highlightedPlayer, setHighlightedPlayer] = useState(null);
    const [highlightedTerritory, setHighlightedTerritory] = useState(null);
    const [centeredPlayer, setCenteredPlayer] = useState(null);

    // If boolean localstorage preferences exist, apply them
    useEffect(() => {
        const sidebarPref = ProcessLocalStorageValue(
            localStorage.getItem("lastChosenSidebarState")
        );
        if (!isRender && sidebarPref != null) {
            const isMobile = window.matchMedia('(max-width:768px)')
            setSidebarActive(sidebarPref && !isMobile.matches);
        }
    }, [isRender]);

    // Clear the centered player after a couple seconds
    useEffect(() => {
        let timeout;
        let validTimeout = true;

        if (centeredPlayer) {
            timeout = setTimeout(
                () => validTimeout && setCenteredPlayer(null),
                1500
            );
        }

        return () => {
            validTimeout = false;
            if (timeout) clearTimeout(timeout);
        };
    }, [centeredPlayer]);

    // Called once when the sketch finishes initializaing
    // eslint-disable-next-line consistent-return
    const initWithGameState = ({ gameState, sandbox }) => {
        if (!gameState) {
            // eslint-disable-next-line no-console
            console.error(
                "ERROR: No game_state passed back to React on P5 init."
            );
            return null;
        }
        // Pull relevant values from game state
        const mostRecentTurn =
            gameState.territories && gameState.territories.length
                ? gameState.territories[0].turn_num
                : -1;

        // Update React state in game context
        // We might want a dispatch/resolver pattern here, if all these state variables become unweildy
        setHighlightedPlayer(null);
        setHighlightedTerritory(null);
        setCenteredPlayer(null);
        setTurn(mostRecentTurn);
        setTotalTurns(mostRecentTurn);
        if (gameState.room_id) setRoomId(gameState.room_id);
        if (gameState.players) setPlayers(gameState.players);
        if (gameState.orders) setOrders(gameState.orders);
        if (gameState.room_info) setRoomInfo(gameState.room_info);

        setSandboxInfo({
            ...sandbox,
            isSandbox: gameState.is_sandbox,
        });
    };

    // eslint-disable-next-line consistent-return
    const updateWithGameState = ({ gameState }) => {
        if (!gameState) {
            // eslint-disable-next-line no-console
            console.error(
                "ERROR: No game_state passed back to React on P5 update."
            );
            return null;
        }

        // Pull relevant values from game state
        const mostRecentTurn =
            gameState.territories && gameState.territories.length
                ? gameState.territories[0].turn_num
                : -1;

        // Update React state in game context
        // We might want a dispatch/resolver pattern here, if all these state variables become unweildy
        setTurn(mostRecentTurn);
        if (gameState.players) setPlayers(gameState.players);
        if (gameState.orders) setOrders(gameState.orders);
        if (gameState.room_info) setRoomInfo(gameState.room_info);

        setSandboxInfo({
            ...sandboxInfo,
            isSandbox: gameState.is_sandbox,
        });
    };

    return (
        <GameStateContext.Provider
            value={{
                players,
                orders,
                showOrders,
                setShowOrders,
                roomId,
                roomInfo,
                turn,
                setTurn,
                totalTurns,
                initWithGameState,
                updateWithGameState,
                badRoom,
                setBadRoom,
                hoveringCanvas,
                setHoveringCanvas,
                highlightedPlayer,
                setHighlightedPlayer,
                highlightedTerritory,
                setHighlightedTerritory,
                centeredPlayer,
                setCenteredPlayer,
                sidebarActive,
                setSidebarActive,
                sandbox: sandboxInfo,
            }}
        >
            {children}
        </GameStateContext.Provider>
    );
};

export default GameInstance;
