import React, { useEffect, useState } from "react";
import { useLocation } from "@reach/router";
import querystring from "query-string";

import SessionStateContext from "../../context/SessionStateContext";

const SessionInstance = ({ children }) => {
    const { search } = useLocation();
    const { room } = querystring.parse(search);

    // Default to room URL param, then local storage
    const [currentRoom, setCurrentRoom] = useState(
        room || localStorage.getItem("lastRoomID")
    );

    // Update room in session when param is updated
    useEffect(() => {
        if (room && room !== currentRoom) {
            // console.log("**** changing current room to ", room);
            setCurrentRoom(room);
        }
    }, [room]);

    return (
        <SessionStateContext.Provider
            value={{
                currentRoom,
            }}
        >
            {children}
        </SessionStateContext.Provider>
    );
};

export default SessionInstance;
