import React from "react";
import styled from "@emotion/styled";

import Page from "../../components/Page/Page"

const Main = styled.div`
    max-width: 600px;
    margin: 40px auto;
`;

const FunFact = styled.div`
    border: none;
    minWidth: 300px;
    padding: 5px 30px;
    background: #eee;
`;

const BotCommand = styled.span`
    background: #ddd;
    color: #f00;
    font-family: "Courier New", Courier, monospace;
    marg;
    padding-left: 2px;
    padding-right: 2px;
`;

const SetupGuide = () => {
    return (
        <Page>
            <Main>
                

                <h1>How To Install Skirmish Society on Discord</h1>

                <div>
                    <p>
                        Skirmish Society is a once-per-day territory control game that you play over Discord. The game is embedded in your server and can be played by everybody on that server. Commands are submitted through an easy to install bot.
                    </p>
                </div>

                <h1>Adding the bot to your server</h1>

                <p>
                    To get started, you should install the Skirmish Commissioner bot on your server. Once installed the bot will help walk you through the process of starting a game.
                </p>

                <h2>Selecting your bot version</h2>
                <p>
                    There are two flavors depending on how much permission you feel comfortable giving the bot. Both will allow you to play the game with all features.
                </p>
                <p>
                    You must have "Manage Server" rights on your Discord server to install the bot. If you don't, please ask an admin on your server to do this.
                </p>
                <p>
                    Discord makes it super easy to add bots to a server. Just click one of the links below and confirm which server you want to add it to.
                </p>

                <h3>Easy (requires channel access)</h3>

                <p>
                    <b><a target="_blank" href="https://discord.com/oauth2/authorize?client_id=702674944143654932&scope=bot&permissions=16">Click here to add the bot</a></b>
                </p>

                <p>
                    This install link will give the bot permission to manage channels on the server. This makes the setup process a little easier because the bot will make the game channel itself.
                </p>

                <p>
                    Creating the game channel is the only time this bot will ever use this permission.
                </p>

                <h3>Limited Access</h3>

                <p>
                    <b><a target="_blank" href="https://discord.com/oauth2/authorize?client_id=702674944143654932&scope=bot">Click here to add the bot</a></b>
                </p>

                <p>
                    If you don't feel comfortable giving bots any extra permissions, that's fine! This version of the bot will ask you to make the game channel yourself, but after that it will have everything you need to play the game.
                </p>
                <p>
                    With limited permission, the bot will also be unable to change the channel topic, so it is recommended that you put the link to the gameboard as the channel topic once that game starts.
                </p>

                <h2>Saying Hi</h2>

                <p>
                    Once you've added the bot, it should post in #general or #welcome (if you have those channels) and will create #skirmish-society (or ask you to create it, if you went with limited access). Take a look and make sure it has been added and that it has posted.
                </p>

                <p>
                    Once the channel has been made, head over to #skirmish-society in your Discord. This is the channel where you and all other players will communicate with the bot. You can move this channel wherever you'd like, <b>but do not change the name</b>.
                </p>

                <p>
                    You can communicate with the bot using Discord slash commands (which all begin with "/"). Try posting <BotCommand>/help</BotCommand> to start communicating with the bot. It will tell you how to start a new game. You can keep reading this guide or just follow the bot's instructions. 
                </p>

                <h1>Admin Role</h1>
                
                <p>
                    The only people who can send admin commands to the bot are server mods (any user with "Manage Server" permissions) and people with the <BotCommand>skirmish society admin</BotCommand> role.
                </p>

                <p>
                    If you were the one who installed the bot, that means you have Manage Server permissions, so if you're planning to run the game, you can skip to the next step!
                </p>

                <p>
                    But if you want somebody else who is not a mod to be able to run the game, you will need to add a <BotCommand>skirmish society admin</BotCommand> role to your Discord server. The name of the role must be "skirmish society admin" but it does not require any special permission on the server and can be any color.
                </p>

                <p>
                    Any number of users can have this role, and they do not need to otherwise be admins on the server.
                </p>

                <p>
                    Users with this role will be able to do things like setup and start a game as well as other administrative commands like pausing the game or blocking disruptive players.
                </p>

                <p>
                    Discord has a guide for adding a new role <a target="_blank" href='https://support.discord.com/hc/en-us/articles/206029707-How-do-I-set-up-Permissions-'>here</a>.
                </p>

                <p>
                    <b>Note:</b> It may take an hour or two for the bot to recognize that somebody has gained or lost the skirmish society admin role.
                </p>


                <h1>Starting a game</h1>

                <p>
                    Once you setup a game, it will be in the join phase. Up to 20 players can join the game. It won't actually start until you give the skirmish-commissioner bot the <BotCommand>/start</BotCommand> command.
                </p>

                <p>
                    To setup a game, use <BotCommand>/setup</BotCommand> in the #skirmish-society channel on your server. The bot will ask you a few questions to tailor the game.
                </p>

                <p>
                    <img
                        alt="Putting the /setup command in the text field"
                        src="pic/setup_guide/setup_prompt.jpg"
                    />
                </p>

                <p>
                    If you put a "/" in the text field and start typing, Discord should autocomplete for you. You want "setup" and not "advanced_setup" for now.
                </p>

                <h3>Game Length</h3>

                <p>
                    The bot will ask how long a game you want. This is determined by the amount of victory points (VP) needed to win the game.
                </p>

                <p>
                    Select "Short" "Normal" or "Long"
                </p>

                <p>
                    <img
                        alt="Let's make a new room! If you have a game in progress, this will reset it. I'll ask you a few questions.
                        You can ignore any of these prompts for a bit to cancel.
                        
                        How long a game do you want? short (7VP), normal (10VP), or long (15VP)?"
                        src="pic/setup_guide/setup1.jpg"
                    />
                </p>

                <h3>Local Time</h3>

                <p>
                    Time zones can be tricky! Rather than having you enter in your time zone or having you calculate how far off you are from UTC (the universal standard time), the bot just asks you to select what time it is where you are. This will allow the bot to figure out your time zone and print times in your local time.
                </p>

                <p>
                    <img
                        alt="Next, let's figure out what time zone you're in. What is the hour where you are right now?

                        For example, if it is 6:45AM where you are, select 6AM
                        
                        This can be changed later with the /local time command."
                        src="pic/setup_guide/setup2.jpg"
                    />
                </p>

                <p>
                    Next the bot will ask you to select a time zone label from a list based on the time you entered. This is just used when writing out the time and will not affect anything.
                </p>

                <p>
                    <img
                        alt="Please select a time zone label from this list.

                        (this will just be a label when I talk about time)"
                        src="pic/setup_guide/setup2b.jpg"
                    />
                </p>

                <p>
                    Often not all players will be in the same time zone, so anything that prints the time will also print the time in UTC.
                </p>

                <h3>Resolve Time</h3>

                <p>
                    Turns in Skirmish Society are resolved once per day. Typically you will want the game to resolve some time in the evening in your time zone. The next prompt will ask you to select a time to resolve the turns (using your local time). Resolution will always happen on the hour.
                </p>

                <p>
                    <img
                        alt="On what hour do you want the turn to resolve?

                        This can be changed later with the /resolution_time command"
                        src="pic/setup_guide/setup3.jpg"
                    />
                </p>

                <h3>Confirmation</h3>

                <p>
                    Before making the room, the bot will have you make sure that everything looks good.
                </p>

                <p>
                    <img
                        alt="Here's what I have for you:

                        🎖 VP to win: 10
                        🕰 Time zone: EDT
                        ⏰ Resolution time: 8:00 PM
                       
                       Does that sound good? Should I make the room?
                       (Players can join once the room is created. The game will not begin until an admin starts it)"
                        src="pic/setup_guide/setup4.jpg"
                    />
                </p>


                {/* <FunFact>
                    <p>
                        <b>Why UTC?</b>
                    </p>

                    <p>
                        UTC comes up a lot! It stands for Universal Time Coordinated and it is what comptuers often use when dealing with time. Any time the game lists a local time, it also lists the time in UTC. This is because time zones can cause a lot of issues! People could be playing from anywhere in the world, so I can't just assume that 5 o'clock means the same thing for everybody. Besides being a universal standard, the backend server that runs Skirmish Society uses UTC.
                    </p>
                </FunFact> */}

                <h2>Starting the game</h2>

                <p>
                    After the those setup questions, the bot will make a room for you and players can start joining!
                </p>

                <p>
                    Let folks in your server know that you started a game if you haven't already.
                </p>

                <p>
                    Players can join by using the <BotCommand>/join</BotCommand> command in the #skirmish-society channel. The bot will DM them an additional join confirmation. <b>Don't forget to join the game yourself!</b>
                </p>

                <p>
                    The join period lasts as long as you need it to. Make sure everybody is in and when you are ready, use <BotCommand>/start</BotCommand> in the #skirmish-society channel to start the game! Good luck taking over the map!
                </p>

                <p>
                    <img
                        alt="Putting the /start command in the text field"
                        src="pic/setup_guide/start1.jpg"
                    />
                </p>

                <p>
                    <img
                        alt="⚔️ Are you really ready to start? ⚔️

                        ⚠️No more players will be able to join after you start the game, so make sure everybody is in.
                        There are currently 5 players in the game.
                        
                        This game is set to resolve turns at 8:00 PM EDT (1:00 UTC).
                        If you start now, the first turn will resolve in 3 hours, 55 minutes, and 23 seconds."
                        src="pic/setup_guide/start2.jpg"
                    />
                </p>

                <p>
                    <b>No new players will be able to join after you start</b>, so make sure everybody is in. You can click the link for your room posted by the bot to see a list of players currently in the game.
                </p>

                <h2>Admin Commands</h2>

                <p>
                    There are a number of useful commands that for admins to help run and moderate the game. You can find them at the <a href='/rules#Admin%20Commands'>bottom of the rules doc</a>.
                </p>

                <h1>Code of Conduct</h1>

                <p>
                    If you will be the admin for this game, make sure to take a look at the <a target="_blank" href='/code-of-conduct'>Code of Conduct</a>.
                </p>

                <p>
                    It is very short and it includes some tips for running a game that makes sure players have fun and feel safe.
                </p>

                <h1>Advanced Setup with High Society Account Upgrade</h1>

                <p>
                    If you want to support this game and get some aesthetic upgrades and handy tools, you can buy a <a target="_blank" href='/shop'>High Society upgrade</a>! <b>This does not give you any in-game advantage.</b>
                </p>

                <p>
                    In addition to letting you customize your character, it will give you access to the High Society admin commands, including <a href='/rules#High%20Society%20Admin%20Commands'><b>advanced setup</b></a> which lets you do things like set an exact VP target and control how often voting events happen! You can even set the game to Fast Mode where turns resolve every 15 minutes.
                </p>

                <p> 
                    Just use <BotCommand>/advanced_setup</BotCommand> when starting a new game once you've upgraded.
                </p>
                {/* <p>
                    There are a few features that are nice to have, but which create a bit too much of a server load for me to be able to include them across the board. If you are into the game and want to help support it, you can upgrade your account. This will help me cover server costs!
                </p> */}

                <p>
                    You can buy a key to upgrade your account <a target="_blank" href='/shop'>here</a>. It's just $3!
                </p>

                <p>
                    Once you have a key, you can redeem it by using the <BotCommand>/redeem [YOUR_KEY]</BotCommand> command.
                </p>

                <p>
                    This upgrade is tied to your Discord account. If you play Skirmish Society on a new server, you will still get the perks of an upgraded account.
                </p>

                <h1>That's it!</h1>

                <p>
                    You did it! Get some friends together and have fun playing Skirmish Society!
                </p>

            </Main>
        </Page>
    );
};

export default SetupGuide;
