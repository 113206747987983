import React from "react";
import styled from "@emotion/styled";

import GameBoard from "../../../components/GameBoard/GameBoard";

const SandboxWrapper = styled.div`
    width: 100%;
    height: 400px;
    margin: 0 auto 30px;
    box-shadow: 0 0 0 1px #ddd;
    position: relative;
`;

const SandboxBoard = () => {
    return <GameBoard isSandbox room="sandbox" Wrapper={SandboxWrapper} />;
};

export default SandboxBoard;
