import React, { useRef } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import useMouse from "@react-hook/mouse-position";
import { Portal } from "react-portal";

import { mixins } from "../../styles";

const Wrapper = styled.span`
    display: inline-block;
`;

const Content = styled.div`
    ${(props) => mixins.transition(props.transitionDuration || "0.4s", "opacity")};
    position: fixed;
    pointer-events: none;
    z-index: 4;
    left: ${(props) => props.position.x || "-999"}px;
    top: ${(props) => props.position.y || "-999"}px;
    transform: translateX(${(props) => props.flipped ? "-100%" : "0%"})
        translateY(calc(-100% - 10px));
    opacity: ${(props) => props.visible ? "1" : "0"};
`;

const Tooltip = ({ children, renderTooltip, customRef, forceVisible, transitionDuration }) => {
    const wrapperRef = React.useRef(null);
    const mouse = useMouse(customRef || wrapperRef);
    const position = useRef({});
    const flipped = useRef(false);
    const visible = useRef(false);

    // Only update position values if mouse sends back info
    if (mouse.pageX) {
        position.current = { x: mouse.pageX, y: mouse.pageY };
        flipped.current = mouse.pageX > window.innerWidth / 2;
        visible.current = forceVisible || mouse.isOver;
    }

    return (
        <Wrapper ref={wrapperRef}>
            {children}
            <Portal>
                <Content 
                    visible={visible.current} 
                    flipped={flipped.current} 
                    position={position.current}
                    transitionDuration={transitionDuration}
                >
                    {renderTooltip()}
                </Content>
            </Portal>
        </Wrapper>
    );
};

Tooltip.propTypes = {
    renderTooltip: PropTypes.func,
    customRef: PropTypes.object,
    forceVisible: PropTypes.bool,
    transitionDuration: PropTypes.string
};

export default Tooltip;
