import React from "react";
import styled from "@emotion/styled";

import Page from "../../components/Page/Page"

const Main = styled.div`
    max-width: 600px;
    margin: 40px auto;
`;

const Rules = () => {
    return (
        <Page>
            <Main>
                <h1>Skirmish Society Code of Conduct</h1>

                <div className="rules_text">
                    <p>Thanks for taking a look at Skirmish Society!</p>

                    <p>
                        This game can bring up strong emotions. Betrayal and deceit
                        are a core part of the play. It is important to keep this
                        within the magic circle of the game, and not let these
                        strong emotions bleed into your non-game interactions with
                        other players.
                    </p>

                    <p>
                        Remember that this is just a game. You must be respectful
                        towards other players and understand that slights or
                        betrayal in game are not real-world attacks. There is an
                        element of role-play to this game. Remind yourself that you
                        were betrayed by the diplomat that other player is playing
                        as, not the other player themself.
                    </p>

                    <p>
                        If you believe you will have difficulty compartmentalizing
                        like this, you may want to skip this game. If you find
                        yourself losing your cool mid-game, step away for a few
                        hours or consider resigning.
                    </p>

                    <p>
                        It is up to the admin of your game to define what
                        constitutes unacceptable behavior, but any of the following
                        should be cause for immediate intervention:
                    </p>

                    <p>
                        <ul>
                            <li>
                                Derogatory language / slurs in messages or public
                                channels. (This should not be acceptable in your
                                Discord anyway.)
                            </li>
                            <li>
                                Real life threats, even as a joke. (ex: "If you go
                                back on your word one more time, I'm going to kill
                                you.")
                            </li>
                            <li>
                                Any type of threatened real-world retaliation for
                                in-game action, even if it is not violent. (ex:
                                "There's no way you're coming out for drinks with us
                                after what you did.")
                            </li>
                            <li>
                                Any type of ad hominem attack. This includes
                                bringing up things that player did outside of the
                                game, insulting or attacking the player as a person.
                                (ex: "You didn't pay your part of the tab last week
                                and now this. You're just not a trustworthy
                                person.")
                            </li>
                            <li>
                                Threats to spoil the game/griefing. (ex: "I don't
                                care about winning any more. I just want to make
                                this game suck for you.") While in-game retaliation
                                for in-game betrayal is perfectly acceptable,
                                threats like this start to veer outside of the
                                game, as the player's goal is not to play the game
                                in a good-faith manner, but ruin the experience for
                                another player.
                            </li>
                        </ul>
                    </p>

                    <p>
                        If this type of behavior is happening to your over DM, bring
                        it up with the admin on your server.
                    </p>

                    <p>
                        Being emotionally invested in the highs and lows is part of
                        what makes games like this exciting! But at the end of the
                        day, your mental wellbeing and the people you play with are
                        far more important than a game. Treat yourself well and walk
                        away if you are not having fun.
                    </p>

                    <p>If that all sounds good, let's play some Skirmish Society!</p>
                </div>

                <div className="rules_text">
                    <h2>For Admins</h2>

                    <p>
                        If you are the admin of the game on your server, it's up to
                        you to make sure that this code of conduct is being
                        followed. The <a href='/rules/#Admin%20Commands'>admin commands</a> are important tools, and you
                        should not hesitate to ban any player who is making other
                        people feel unsafe or unwelcome, but generally your first
                        move should be to talk to any player who has crossed a line.
                        If warning signs are there, don't wait until somebody's
                        behavior escalates to the point where it requires a ban.
                    </p>

                    <p>
                        If you are an admin and you see unacceptable behavior
                        popping up, you should step in and talk to those players,
                        reminding them that this is a game and that attacking other
                        players is not acceptable. Admins can also pause the entire
                        game to give people time to cool off. If attempts to
                        de-escalate are not successful, admins should remove that
                        player from the game.
                    </p>

                    <p>
                        When starting a game, it is a good idea to post about what
                        is and isn't acceptable so that players know you are looking
                        after their wellbeing. The examples listed above are just a
                        baseline. Think about what type of environment you are
                        cultivating, and make sure players on your server know what
                        type of behavior is and isn't acceptable. If you are also
                        playing, be extra vigilant about your own reactions to the
                        game.
                    </p>

                    <p>
                        Hopefully you will encounter no problems in your game. Being
                        explicit about how you want people to treat each other will
                        make this even more likely!
                    </p>
                </div>

                <div className="rules_text">
                    <p>
                        Back to <a href="/rules">Rules</a>.
                    </p>
                </div>
            </Main>
        </Page>
    );
};

export default Rules;
