import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import App from "./App";

if (process.env.NODE_ENV === "development") {
    // eslint-disable-next-line
    global.test = require("./scripts/console_test");
}

ReactDOM.render(
    // eslint-disable-next-line
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);
