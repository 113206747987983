import React from "react";
import styled from "@emotion/styled";

import Page from "../../components/Page/Page";
import Sidebar from "../../components/Sidebar/Sidebar";
import GameInstance from "../../components/GameInstance/GameInstance";

import Header from "./sandbox/Header";
import SandboxBoard from "./sandbox/SandboxBoard";
import Content from "./sandbox/Content";

const Main = styled.div`
    max-width: 600px;
    margin: 40px auto;
`;

const Sandbox = () => {
    return (
        <GameInstance>
            <Page>
                <Main>
                    <Header />
                    <SandboxBoard />
                    <Content />
                </Main>
                <Sidebar />
            </Page>
        </GameInstance>
    );
};

export default Sandbox;
