import React from "react";
import PropTypes from "prop-types";
import { Link } from "@reach/router";
import styled from "@emotion/styled";

import { mixins, colors } from "../../styles";

// eslint is having some real issues with how Prettier wants to format styled-components
/* eslint-disable indent */
const ButtonStyles = ({ small, primary, minSize }) => `
    ${mixins.transition()};
    text-align: center;
    border: 1px solid black;
    border-radius: 4px;
    padding: ${small ? "5px 9px" : "9px 14px"};
    font-weight: bold;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    color: ${primary ? "white" : "black"};
    background: ${primary ? "black" : "white"};
    min-width: ${minSize ? "140px" : "0"};
    min-height: ${minSize ? "38px" : "0"};
    text-decoration: none;
    white-space:pre;

    &:not([disabled]) {
        cursor: pointer;

        @media screen and (min-width: 768px) {
            &:hover {
                background: ${primary ? colors.slate : colors.grayLight};
                text-decoration: underline;
            }
        }
    }

    &[disabled] {
        opacity: 0.3;
    }

    &:focus {
        text-decoration: underline;
    }
`;
/* eslint-enable indent */

const DefaultButton = styled.button`
    ${(props) => ButtonStyles(props)}
`;

const DefaultLink = styled(Link)`
    ${(props) => ButtonStyles(props)}
`;

const DefaultExternalLink = styled.a`
    ${(props) => ButtonStyles(props)}
`;

const DefaultInput = styled.input`
    ${(props) => ButtonStyles(props)}
    display: inline-block;
`;

const Button = ({ primary, small, to, children, ...rest }) => {
    const Component = to?.length ? DefaultLink : DefaultButton;

    return (
        <Component {...rest} primary={primary} small={small} to={to}>
            {children}
        </Component>
    );
};

Button.propTypes = {
    primary: PropTypes.bool,
    small: PropTypes.bool,
    to: PropTypes.string,
};

export default Button;
export { DefaultButton, DefaultLink, DefaultExternalLink, DefaultInput };
