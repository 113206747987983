import React, { useState, useEffect, useRef, useCallback } from "react";

import useGameState from "../../hooks/useGameState";
import isMobile from "../../util/is-mobile";
import { ProcessLocalStorageValue } from "../../util/local-storage-helper";

import SidebarUi from "./SidebarUi";

const Sidebar = () => {
    const {
        players,
        turn,
        orders,
        roomInfo,
        sidebarActive,
        setSidebarActive,
        setHoveringCanvas,
        setHighlightedPlayer,
    } = useGameState();

    const [ordersShown, setOrdersShown] = useState(!isMobile());
    const [simplified, setSimplified] = useState(false);
    const [playersWithOrders, setPlayersWithOrders] = useState([]);
    const [sortedPlayers, setSortedPlayers] = useState([]);
    const [sortCriteria, setSortCriteria] = useState(
        localStorage.getItem("lastChosenSortMode") || "victory_points"
    );

    // Special case for alphabetical sort. Couldn't seem to get the .sort to work numerically and alphabetically.
    const sortAlphabetically = () => {
        const newSortedPlayers = [...playersWithOrders].sort((a, b) => {
            const x = a.name.toLowerCase();
            const y = b.name.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
        });
        setSortCriteria("name");
        localStorage.setItem("lastChosenSortMode", "name");
        setSortedPlayers(newSortedPlayers);
    };

    // Sort by custom criteria
    const sortPlayers = (criteria) => {
        if (criteria === "name") {
            // kinda hate this, but if the saved criteria is alphabetical...
            sortAlphabetically();
            return;
        }
        // Save criteria for next load
        localStorage.setItem("lastChosenSortMode", criteria);
        const newSortedPlayers = [...playersWithOrders].sort((a, b) => {
            //Andy edit to bump dead players down
            if(a.stats["is_dead"] && !b.stats["is_dead"])   return 1
            if(b.stats["is_dead"] && !a.stats["is_dead"])   return -1

            return b.stats[criteria] - a.stats[criteria];
        });

        setSortCriteria(criteria);
        setSortedPlayers(newSortedPlayers);
    };

    // Ascribe orders to players when we get them
    useEffect(() => {
        const newPlayersWithOrders = players.map((player) => {
            const playerOrders = orders.filter(
                (order) => order.player === player.id
            );

            return {
                ...player,
                orders: playerOrders,
            };
        });
        setPlayersWithOrders(newPlayersWithOrders);
    }, [orders, players]);

    // If boolean preferences exist, apply them
    useEffect(() => {
        const ordersPref = ProcessLocalStorageValue(
            localStorage.getItem("lastChosenOrdersState")
        );
        const simplePref = ProcessLocalStorageValue(
            localStorage.getItem("lastChosenSimplifiedState")
        );
        if (ordersPref != null) {
            setOrdersShown(ordersPref);
        }
        if (simplePref != null) {
            setSimplified(simplePref);
        }
    }, []);

    useEffect(() => {
        // Store a local value of the last room the player was in.
        if (roomInfo?.id && roomInfo.id !== "sandbox") {
            localStorage.setItem("lastRoomID", roomInfo.id);
        }
    }, [roomInfo]);

    //  record the highest possible score
    // this can be higher than the score to win in sudden death
    const highestPossibleScore = useRef(0);
    useEffect(() => {
        // Default to score needed to win
        highestPossibleScore.current = roomInfo.score_to_win;

        // See if any players have a score equal to the current max
        // Andy is removing this because it felt weird to have a 7 point game suddenly jump to 8 if the winner exceeded the goal
        // players.forEach((player) => {
        //     if (player.stats.victory_points >= highestPossibleScore.current) {
        //         // If there's a winner, the highest score is exactly this score
        //         // If not, that means we're in sudden death and the winning score is one higher
        //         highestPossibleScore.current = roomInfo.winner
        //             ? player.stats.victory_points
        //             : player.stats.victory_points + 1;
        //     }
        // });
    }, [players, roomInfo]);

    // Default sort when we get new players
    useEffect(() => {
        sortPlayers(sortCriteria || "victory_points");
    }, [playersWithOrders]); // eslint-disable-line react-hooks/exhaustive-deps

    const closeSidebar = () => {
        localStorage.setItem("lastChosenSidebarState", "off");
        setSidebarActive(false);
    };

    const openSidebar = () => {
        localStorage.setItem("lastChosenSidebarState", "on");
        setSidebarActive(true);
    };

    const toggleOrders = (setting) => {
        setOrdersShown(setting);
        localStorage.setItem(
            "lastChosenOrdersState",
            setting === true ? "on" : "off"
        );
    };

    const toggleSimplified = (setting) => {
        setSimplified(setting);
        localStorage.setItem(
            "lastChosenSimplifiedState",
            setting === true ? "on" : "off"
        );
    };

    // Hide board tooltips when hovering sidebar
    const onMouseEnter = useCallback(() => {
        setHoveringCanvas(false);
        setHighlightedPlayer(null);
    }, []);
    const onMouseLeave = useCallback(() => {
        setHoveringCanvas(true);
    }, []);

    // Don't show the sidebar if we have no players
    if (!players || players.length === 0) {
        return null;
    }

    // Make array of sort buttons with callbacks
    const sortButtons = [
        {
            label: "VP",
            key: "victory_points",
            onClick: () => sortPlayers("victory_points"),
        },
        {
            label: "A -> Z",
            key: "name",
            onClick: () => sortAlphabetically(),
        },
        {
            label: "Territories",
            key: "territory_count",
            onClick: () => sortPlayers("territory_count"),
        },
        {
            label: "Armies",
            key: "armies",
            onClick: () => sortPlayers("armies"),
        },
        {
            label: "Barracks",
            key: "supply_points",
            onClick: () => sortPlayers("supply_points"),
        },
    ];

    const uiProps = {
        players: sortedPlayers,
        sortButtons,
        active: sidebarActive,
        highestPossibleScore: highestPossibleScore.current,
        openSidebar,
        closeSidebar,
        toggleOrders,
        ordersShown,
        toggleSimplified,
        simplified,
        sortCriteria,
        turn,
        onMouseEnter,
        onMouseLeave,
    };

    return <SidebarUi {...uiProps} />;
};

export default Sidebar;
