//all of these values affect where things are drawn
//I put them up here to make adjustmentes easier
const main_ctrl_dist_prc = 0.35;
const main_ctrl_prc = 0.5;
const cross_prc = 0.5;

const bezzie_steps = 18;

const hash_spacing = 7;

const sup_join_prc = 0.65;
const sup_prc_1 = 0.35;
const sup_prc_2 = 0.5;

const ui_hit_circle_size = 17

function get_bez_control(src, target, p) {
    // set control points
    const main_ctrl_1 = { x: 0, y: 0 };
    const main_ctrl_2 = { x: 0, y: 0 };

    const midX = (src.x + target.x) / 2;
    const midY = (src.y + target.y) / 2;

    const angle = p.atan2(src.y - target.y, src.x - target.x) + 90;
    const main_dist = p.dist(src.x, src.y, target.x, target.y);
    const ctrl_dist = main_dist * main_ctrl_dist_prc;

    main_ctrl_2.x = midX + p.cos(angle) * ctrl_dist;
    main_ctrl_2.y = midY + p.sin(angle) * ctrl_dist;

    main_ctrl_1.x = main_ctrl_prc * src.x + (1.0 - main_ctrl_prc) * main_ctrl_2.x;
    main_ctrl_1.y = main_ctrl_prc * src.y + (1.0 - main_ctrl_prc) * main_ctrl_2.y;

    const return_val = {};
    return_val.pnt1 = main_ctrl_1;
    return_val.pnt2 = main_ctrl_2;

    return return_val;
}

function draw_direct_bez({
    src,
    target,
    arrow_pad_dist,
    draw_hash,
    fbo,
    ui_hit_circles,
    p
}) {
    const main_ctrl = get_bez_control(src, target, p);

    // bezie
    if (!draw_hash) {
        draw_bez_arrow({
            pnt1:src, 
            ctrl1: main_ctrl.pnt1, 
            ctrl2: main_ctrl.pnt2, 
            pnt2: target, 
            arrow_pad_dist: arrow_pad_dist,
            fbo: fbo, 
            ui_hit_circles: ui_hit_circles,
            p:p
        });
    } else {
        draw_hash_bez({
            pnt1:src, 
            ctrl1: main_ctrl.pnt1, 
            ctrl2: main_ctrl.pnt2, 
            arrow_pad_dist: arrow_pad_dist,
            pnt2: target, 
            fbo: fbo, 
            ui_hit_circles: ui_hit_circles,
            p:p
        });
    }
}

function draw_support_bez({support_src, src, target, arrow_pad_dist, fbo, ui_hit_circles, p}) {
    const main_ctrl = get_bez_control(src, target, p);

    // get a point a certain percentage of the way through
    const sup_join_pnt = {};
    sup_join_pnt.x = p.bezierPoint(
        src.x,
        main_ctrl.pnt1.x,
        main_ctrl.pnt2.x,
        target.x,
        sup_join_prc
    );
    sup_join_pnt.y = p.bezierPoint(
        src.y,
        main_ctrl.pnt1.y,
        main_ctrl.pnt2.y,
        target.y,
        sup_join_prc
    );

    // set second set of control points for support
    const sup_ctrl_1 = { x: 0, y: 0 };
    const sup_ctrl_2 = { x: 0, y: 0 };

    sup_ctrl_1.x = sup_prc_1 * support_src.x + (1.0 - sup_prc_1) * src.x;
    sup_ctrl_1.y = sup_prc_1 * support_src.y + (1.0 - sup_prc_1) * src.y;

    sup_ctrl_2.x = sup_prc_2 * main_ctrl.pnt1.x + (1.0 - sup_prc_2) * main_ctrl.pnt2.x;
    sup_ctrl_2.y = sup_prc_2 * main_ctrl.pnt1.y + (1.0 - sup_prc_2) * main_ctrl.pnt2.y;

    draw_hash_bez({
        pnt1:support_src, 
        ctrl1: sup_ctrl_1, 
        ctrl2: sup_ctrl_2, 
        pnt2: sup_join_pnt, 
        arrow_pad_dist: arrow_pad_dist,
        fbo: fbo, 
        ui_hit_circles: ui_hit_circles,
        p:p
    });
}



function draw_bez_arrow({pnt1, ctrl1, ctrl2, pnt2, arrow_pad_dist, fbo, ui_hit_circles, p}) {
    let points = get_bez_steps({
        pnt1:pnt1,
        ctrl1:ctrl1,
        ctrl2:ctrl2,
        pnt2:pnt2,
        arrow_pad_dist: arrow_pad_dist,
        spacing:hash_spacing,
        p:p
    })

    let arrow_start_index = points.length - 4;
    let start_thickness = 9;
    let end_thickness = 3;
    let arrow_start_thickness = 10;

    fbo.beginShape();
    //down one side
    for (let i=1; i<points.length; i++){
        let pnt = points[i];
        let prev = points[i-1];

        let angle = Math.atan2(prev.y-pnt.y,prev.x-pnt.x) + p.PI/2
        
        let thickness = p.map(i,0,points.length, start_thickness,end_thickness)

        if (i == arrow_start_index){
            let quick_x = pnt.x+Math.cos(angle)*thickness;
            let quick_y = pnt.y+Math.sin(angle)*thickness;
            fbo.vertex(quick_x,quick_y);
        }

        if (i >= arrow_start_index){
            thickness = p.map(i, arrow_start_index,points.length-1, arrow_start_thickness,0);
        }

        let x = pnt.x+Math.cos(angle)*thickness;
        let y = pnt.y+Math.sin(angle)*thickness;

        fbo.vertex(x,y);

        //marking the space this UI takes up
        if (ui_hit_circles != null && i%3==0){
            ui_hit_circles.push({
                x: x,
                y: y,
                s: ui_hit_circle_size
            })
        }
    }
    //and up the other
    for (let i=points.length-1; i>0; i--){
        let pnt = points[i];
        let prev = points[i-1];

        let angle = Math.atan2(prev.y-pnt.y,prev.x-pnt.x) + p.PI/2;

        let thickness = p.map(i,0,points.length, start_thickness,end_thickness)
        let orig_thickness = thickness
        
        if (i >= arrow_start_index){
            thickness = p.map(i, arrow_start_index,points.length-1, arrow_start_thickness,0);
        }
        let x = pnt.x-Math.cos(angle)*thickness;
        let y = pnt.y-Math.sin(angle)*thickness;

        fbo.vertex(x,y);

        if (i == arrow_start_index){
            let quick_x = pnt.x-Math.cos(angle)*orig_thickness;
            let quick_y = pnt.y-Math.sin(angle)*orig_thickness;
            fbo.vertex(quick_x,quick_y);
        }

        //marking the space this UI takes up
        if (ui_hit_circles != null && i%3==0){
            ui_hit_circles.push({
                x: x,
                y: y,
                s: ui_hit_circle_size
            })
        }
    }
    fbo.endShape(p.CLOSE);

}

function draw_hash_bez({pnt1, ctrl1, ctrl2, pnt2, arrow_pad_dist, fbo, ui_hit_circles, p}) {
    let points = get_bez_steps({
        pnt1:pnt1,
        ctrl1:ctrl1,
        ctrl2:ctrl2,
        pnt2:pnt2,
        arrow_pad_dist: arrow_pad_dist,
        spacing:hash_spacing,
        p:p
    })

    for (let i=1; i<points.length; i++){
        let pnt = points[i];
        let prev = points[i-1];

        let angle = Math.atan2(prev.y-pnt.y,prev.x-pnt.x)

        let box_w = p.map(i,0,points.length,24,6)
        let box_h = 4;

        fbo.push();
        fbo.translate(pnt.x,pnt.y);
        fbo.rotate(angle + p.PI/2);
        
        fbo.rect(-box_w/2, -box_h/2, box_w, box_h)
        
        fbo.pop();

        //marking the space this UI takes up
        if (ui_hit_circles != null && i%3==0){
            ui_hit_circles.push({
                x: pnt.x,
                y: pnt.y,
                s: ui_hit_circle_size*1.5
            })
        }
    }
}


function get_bez_steps({pnt1, ctrl1, ctrl2, pnt2, arrow_pad_dist, spacing, p}){
    let list = [];

    list.push({x:pnt1.x,y:pnt1.y})

    //how many incremental steps to take. Each step should be smaller than spacing
    //this isn't an exact science since the line is curved and will be a bit longer than the direct distance between the points
    const incr_steps = p.dist(pnt1.x,pnt1.y, pnt2.x,pnt2.y) / spacing;

    let dist_left = spacing;
    let last_pos = {x:pnt1.x,y:pnt1.y};
    
    for (let i=0; i<incr_steps; i++){
        let prc = i/incr_steps
        let pos = {
            x : p.bezierPoint(pnt1.x, ctrl1.x, ctrl2.x, pnt2.x, prc),
            y : p.bezierPoint(pnt1.y, ctrl1.y, ctrl2.y, pnt2.y, prc)
        }
        dist_left -= p.dist(pos.x,pos.y, last_pos.x,last_pos.y)

        if (dist_left <= 0){
            dist_left += spacing
            list.push(pos)
        }

        //stopping before we get to our target
        if (arrow_pad_dist > 0){
            let dist_to_target = p.dist(pos.x,pos.y, pnt2.x, pnt2.y)
            if (dist_to_target <= arrow_pad_dist){
                break
            }
        }

        last_pos.x = pos.x;
        last_pos.y = pos.y;
    }

    return list
}

export default { 
    get_bez_control, 
    draw_direct_bez, 
    draw_support_bez, 
    draw_hash_bez,
    get_bez_steps
}

