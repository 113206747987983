import draw_arrow from "./draw_arrow.js";
import hex_manager from "./hex_manager.js"
import territory_manager from "./territory_manager.js"
import data_process from "./data_process.js"

// some style info for lines
const move_trail_stroke = { s:35, b:20, a: 1, w: 4 };
const move_trail_fill = { s:90, b:75, a: 1 };
const move_trail_arrow_padding = 20;


const move_trail_failed_stroke = { s: 35, b: 20, a:0.5, w: 2 }; //using same values for failed support
const move_trail_failed_fill = { s: 90, b: 42, a:0.35 };
const move_trail_failed_arrow_padding = 35;

const support_trail_stroke = { s:35, b:20, a: 1, w: 2 };
const support_trail_fill = { s:90, b:75, a: 1 };
const support_move_arrow_padding = 5;
const support_hold_arrow_padding = 30;

//the move failed values wound up being the same
// const support_trail_failed_stroke = { s: 35, b:20, a: 0.4, w: 2 };
// const support_trail_failed_fill = { s:90, b:56, a:0.4 };

//tints and such
const highlight_style = {sat:51, bri:90, alpha:0.5}
const army_icon_style = {sat:75, bri:75, alpha:1}
const resigned_army_style = {sat:10, bri:75, alpha:1}
const dead_icon_style = {sat:army_icon_style.sat, bri:army_icon_style.bri, alpha:0.9}// fbo.tint(player.hue, 60, 100, 0.85);
const conflict_marker_style = {sat:70, bri:10, alpha:0.8}

const territory_icon_alpha = 220

//text
const territory_name_style = {
    fill: 0,
    stroke: 255,
    stroke_weight: 3,
    text_size: 20,
    text_style: "normal",
    text_align: "center",
    font_name: "Helvetica",
    y_offset: 10
}

//this inherits any other properties from territory_name_style
const supply_name_style = {
    stroke_weight: 3,
    text_size: 20,
    text_style: "bold"
}

function draw_overseas_connection(ter, fbo, p) {
    let pos_a = hex_manager.get_hex_center( ter.oversea_hex )
    let pos_b = hex_manager.get_hex_center( ter.oversea_neighbor.oversea_hex)

    let x_shift = 0
    let y_shift = 0
    //figure out what direaction we're going in
    if ( Math.abs(pos_a.x-pos_b.x) > Math.abs(pos_a.y-pos_b.y)){
        //horizontal
        if (pos_a.x > pos_b.x){
            x_shift = fbo.width
        }else{
            x_shift = -fbo.width
        }
    }
    else{
        //vertical
        if (pos_a.y > pos_b.y){
            y_shift = fbo.height
        }else{
            y_shift = -fbo.height
        }
    }

    pos_b.x+=x_shift;
    pos_b.y+=y_shift;

    const oversea_dot_style = {
        step_dist: 20,
        size: 7,
        stroke: 100,
        stroke_weight: 3,
        fill:245,
    }

    fbo.stroke(oversea_dot_style.stroke);
    fbo.strokeWeight(oversea_dot_style.stroke_weight);
    fbo.fill(oversea_dot_style.fill);

    //draw dots to the connecting point
    let angle = Math.atan2(pos_b.y-pos_a.y, pos_b.x-pos_a.x);
    
    let start_pos = {
        x: pos_a.x + Math.cos(angle) * oversea_dot_style.step_dist,
        y: pos_a.y + Math.sin(angle) * oversea_dot_style.step_dist
    }
    let end_pos = {
        x: pos_b.x - Math.cos(angle) * oversea_dot_style.step_dist,
        y: pos_b.y - Math.sin(angle) * oversea_dot_style.step_dist
    }

    let num_dots = Math.floor( p.dist(start_pos.x, start_pos.y, end_pos.x, end_pos.y) / oversea_dot_style.step_dist );
    for (let i=0; i<=num_dots; i++){
        let prc = i / num_dots;
        let this_x = (1.0-prc)*start_pos.x + prc *end_pos.x;
        let this_y = (1.0-prc)*start_pos.y + prc *end_pos.y;
        fbo.ellipse(this_x, this_y, oversea_dot_style.size, oversea_dot_style.size);
    }


    //throw a text label on there
    //TRYING OUT NOT DOING THIS IF WE HAVE INFINTIE SCROLL
    // let prc = 0.5
    // let label_pnt = {
    //     x: prc*cur_x + (1.0-prc)*pos_a.x,
    //     y: prc*cur_y + (1.0-prc)*pos_a.y
    // }

    // fbo.fill(territory_name_style.fill);
    // fbo.stroke(territory_name_style.stroke);
    // fbo.strokeWeight(territory_name_style.stroke_weight);
    // fbo.textSize(territory_name_style.text_size);
    // fbo.textStyle(territory_name_style.text_style);
    // fbo.textAlign(territory_name_style.text_align,territory_name_style.text_align);
    
    // fbo.text(ter.oversea_neighbor.id, label_pnt.x, label_pnt.y-15); 

}

//draws a light line around the borerd of the territory and a dark line if this is the border of a player's area
function draw_territory_border(ter, fbo, p) {
    //console.log(ter)

    fbo.stroke(0, 200);
    fbo.strokeWeight(1.5);
    if (ter.player != null){
        fbo.stroke(255,200);
        fbo.strokeWeight(2);
    }

    fbo.noFill();
    fbo.beginShape();
    fbo.strokeJoin(p.MITER);

    for (let i = 0; i < ter.perim.length; i++) {
        fbo.vertex(ter.perim[i].a.x, ter.perim[i].a.y);
    }
    fbo.endShape(p.CLOSE);

    // thick lines around an entire player controlled area
    fbo.stroke(0, 200);
    fbo.strokeJoin(p.ROUND);
    if (ter.player != null){
        fbo.colorMode(p.HSB);
        fbo.stroke(ter.player.hue, 75,40)
    }
    fbo.strokeWeight(4);
    for (let i = 0; i < ter.border_lines.length; i++) {
        fbo.line(
            ter.border_lines[i].a.x,
            ter.border_lines[i].a.y,
            ter.border_lines[i].b.x,
            ter.border_lines[i].b.y
        );
    }
}

//draws a bright color over a given territory
function draw_territory_highlight(ter, fbo, p) {
    fbo.colorMode(p.HSB);

    fbo.fill(ter.player.hue, highlight_style.sat, highlight_style.bri, highlight_style.alpha);
    fbo.noStroke();

    // run around and draw it
    fbo.beginShape();
    for (let i = 0; i < ter.perim.length; i++) {
        fbo.vertex(ter.perim[i].a.x, ter.perim[i].a.y);
    }
    fbo.endShape(p.CLOSE);
}



function draw_territory_army(ter, icons, fbo, ui_hit_circles, p) {
    // army icon
    if (ter.has_army) {
        const { player } = ter;

        if (player == null) {
            console.log(`I got a null player in territory: ${ter.id}`);
            console.log(ter);
        }

        let icon = icons.army_hold

        //was this from the supply?
        if (ter.supply_order && ter.supply_order.was_successful){
            icon = icons.army_deployed;
        }
        //if there is no order or we've marked that a sucessful move went here, this was a move
        else if (ter.order == null || ter.successful_move_order){
            icon = icons.army_move; //todo put this back to move

            //if there was an army here but it failed to hold, the army that moved here was a victor
            if (ter.order){
                if (ter.order.failed_hold){
                    icon = icons.army_victory
                }
            }
        }
        //after that it could be a lot of things
        else{
            //hold orders
            if (ter.order.orig_type == "hold"){
                icon = icons.army_hold
            }

            //moves (that failed, so the unit remained here)
            if (ter.order.orig_type == "move"){
                icon = icons.army_move_fail
            }

            //support
            if (ter.order.orig_type == "support"){
                //is the order still support, or did it get interupted and forced to be a hold?
                if (ter.order.type == "support"){
                    icon = icons.army_support
                }
                else{
                    icon = icons.army_support_cut
                }
            }

            //building
            if (ter.order.orig_type == "build"){
                if (ter.order.type == "build"){
                    icon = icons.army_build
                }
                else{
                    icon = icons.army_farm_cut
                }
            }

            //farming
            if (ter.order.orig_type == "farm"){
                if (ter.order.type == "farm"){
                    icon = icons.army_farm
                }
                else{
                    icon = icons.army_farm_cut
                }
            }
        }

        ter.should_flip_army_icon = false
        //if there was a sucessful move into this territory, check where it came from
        if (icon == icons.army_move){
            if (ter.successful_move_order.source_ter.army_pos.x > ter.army_pos.x){
                ter.should_flip_army_icon = true
            }
            //flip if overseas
            if (ter.successful_move_order.source_ter.oversea_neighbor_id == ter.id){
                ter.should_flip_army_icon = !ter.should_flip_army_icon;
            }
        }
        //if there was a move order that failed, see where we were trying to go
        if (ter.order){
            if (ter.order.orig_type == "move" && !ter.order.was_successful){
                if(ter.army_pos.x > ter.order.target_ter.army_pos.x ){
                    ter.should_flip_army_icon = true;
                }
                //flip if overseas
                if (ter.oversea_neighbor_id == ter.order.move_target){
                    ter.should_flip_army_icon = !ter.should_flip_army_icon;
                }
            }
        }

        fbo.colorMode(p.HSB);
        fbo.tint(player.hue, army_icon_style.sat, army_icon_style.bri);

        if (player.stats.is_dead){
            fbo.tint(player.hue, resigned_army_style.sat, resigned_army_style.bri);
        }
        
        fbo.push();
        fbo.translate(ter.army_pos.x, ter.army_pos.y);
        if (ter.should_flip_army_icon){
            fbo.scale(-1,1)
        }

        fbo.image(icon, -icon.width / 2, -icon.height / 2);
        fbo.pop();

        fbo.colorMode(p.RGB);

        //marking the space this UI takes up
        if (ui_hit_circles != null){
            ui_hit_circles.push({
                x: ter.army_pos.x,
                y: ter.army_pos.y,
                s: 60
            })
        }
    }
}

function draw_conflict_marker(ter, icons, fbo, ui_hit_circles,  p){
    if (ter.was_conflict){
        const { player } = ter;
        fbo.tint(0,conflict_marker_style.alpha*255)

        let icon = icons.bounce_overlay

        if (player != null){
            if (ter.has_army){
                fbo.colorMode(p.HSB);
                fbo.tint(player.hue, conflict_marker_style.sat, conflict_marker_style.bri, conflict_marker_style.alpha);
                fbo.colorMode(p.RGB);
                icon = icons.attack_overlay
            }
        }

        fbo.push();
        fbo.translate(ter.army_pos.x, ter.army_pos.y);
        fbo.image(icon, -icon.width / 2, -icon.height / 2);
        fbo.pop();

        //if we're tracking UI hit circles, add that
        if (ui_hit_circles != null){
            ui_hit_circles.push({
                x: ter.army_pos.x,
                y: ter.army_pos.y,
                s: 80
            })
        }
    }
}

function draw_territory_front({ter, icons, fbo, ui_hit_circles, p}) {
    // icons if it has special status supply or farm
    if (ter.is_supply) {
        fbo.push();
        fbo.tint(255, territory_icon_alpha);
        let icon_y_padding = 20;
        fbo.translate(ter.center_pos.x, ter.center_pos.y - icon_y_padding);
        if (ter.is_farm == false){
            let icon = icons.supply_point_icon
            if (ter.supply_order){
                if (!ter.supply_order.was_successful && !ter.supply_order.invalid_reason){
                    icon = icons.supply_point_fail
                }

                //marking the space this UI takes up (maybe don't need this)
                if (ui_hit_circles != null){
                    ui_hit_circles.push({
                        x: ter.center_pos.x+30,
                        y: ter.center_pos.y- icon_y_padding-icons.farm_icon.height,
                        s: 40
                    })
                }
            }
            fbo.image(icon, -icon.width / 2, -icon.height);
        }else{
            fbo.image(
                icons.farm_icon,
                -icons.farm_icon.width/2,
                -icons.farm_icon.height
            );
        }
        fbo.pop();

        //marking the space this UI takes up
        if (ui_hit_circles != null){
            ui_hit_circles.push({
                x: ter.center_pos.x,
                y: ter.center_pos.y- icon_y_padding-icons.farm_icon.height,
                s: 40
            })
        }
    }

    // name
    if (ter.center_hex) {
        fbo.strokeJoin(p.ROUND);
        fbo.fill(territory_name_style.fill);
        fbo.stroke(territory_name_style.stroke);
        fbo.strokeWeight(territory_name_style.stroke_weight);
        //fbo.textFont(icons.font);
        fbo.textFont(territory_name_style.font_name);
        fbo.textSize(territory_name_style.text_size);
        fbo.textStyle(territory_name_style.text_style);
        fbo.textAlign(territory_name_style.text_align,territory_name_style.text_align);
        
        if (ter.is_supply) {
            fbo.textSize(supply_name_style.text_size);
            fbo.strokeWeight(supply_name_style.stroke_weight);
            fbo.textStyle(supply_name_style.text_style);
        }
        // if (ter.owner == "NONE"){    //just give everybody an outline. Looks better
        //     fbo.noStroke();
        // }
        fbo.text(ter.disp_name, ter.center_pos.x, ter.center_pos.y - territory_name_style.y_offset);

        let id_text = ter.id;
        fbo.text(id_text, ter.center_pos.x, ter.center_pos.y + territory_name_style.y_offset);

        //if we're tracking UI hit circles, add that
        if (ui_hit_circles != null){
            ui_hit_circles.push({
                x: ter.center_pos.x,
                y: ter.center_pos.y,
                s: 80
            })
        }

    } else {
        console.log(`${ter.disp_name} IS MISSING CENTER HEX`);
    }
}

function draw_territory_orders({ter, game_state, icons, fbo, ui_hit_circles, p}) {
    //make sure we have a valid order to draw
    if (ter.order == null){
    	return
    }
    if (ter.order.valid == false){
    	return
    }

    let player = data_process.get_player_from_id(ter.order.player, game_state.players)

    //draw it
    p.noFill();
	const { order } = ter;
	if (order.orig_type == "move") {
        draw_move_order(order, ter, player, fbo, ui_hit_circles, p)
    }
	if (order.orig_type == "support") {
        draw_support_order(order, ter, player, fbo, ui_hit_circles, p)
    }
	if (order.type == "build" && order.was_successful){
        draw_build_order(order, ter, player, fbo, p)
    }
}

function draw_territory_failed_hold_orders({ter, game_state, icons, fbo, ui_hit_circles, p}) {
    //make sure we have a valid order to draw
    if (ter.order == null){
        return
    }
    if (ter.order.valid == false){
        return
    }
    const { order } = ter;
    if (order.failed_hold) {
        let player = data_process.get_player_from_id(ter.order.player, game_state.players)
        draw_failed_hold_order({
            order:order,
            ter: ter,
            game_state: game_state,
            player: player,
            icons: icons,
            fbo: fbo,
            ui_hit_circles: ui_hit_circles,
            p: p
        })
    }
}

function draw_move_order(order, ter, player, fbo, ui_hit_circles, p){
	const { target_ter } = order;

    let arrow_info = {
        src:ter.army_start_pos,
        target:target_ter.army_pos,
        arrow_pad_dist: move_trail_arrow_padding,
        draw_hash: false,
        fbo: fbo,
        ui_hit_circles: ui_hit_circles,
        p:p
    }

    //formatting if it was successful
    if (order.was_successful){
        fbo.colorMode(p.HSB);
        fbo.strokeWeight(move_trail_stroke.w);
        fbo.stroke(player.hue, move_trail_stroke.s, move_trail_stroke.b, move_trail_stroke.a)
        fbo.fill(player.hue, move_trail_fill.s, move_trail_fill.b, move_trail_fill.a)
    }
    //formatting if it failed
    else{
        fbo.colorMode(p.HSB);
        fbo.strokeWeight(move_trail_failed_stroke.w);
        fbo.stroke(player.hue, move_trail_failed_stroke.s, move_trail_failed_stroke.b, move_trail_failed_stroke.a)
        fbo.fill(player.hue, move_trail_failed_fill.s, move_trail_failed_fill.b, move_trail_failed_fill.a)

        arrow_info.arrow_pad_dist = move_trail_failed_arrow_padding
        arrow_info.src = ter.army_pos
    }

    let is_oversea = false
    if (ter.oversea_neighbor_id == target_ter.id){
        is_oversea = true;
    }
    
    //if it's not oversea, just draw the arrow
    if (!is_oversea){
        draw_arrow.draw_direct_bez(arrow_info);
    }
    //if it is, draw two arrows for the screen-wrap
    else{
        let pos_a = ter.army_start_pos
        let pos_b = target_ter.army_pos

        let x_shift = 0
        let y_shift = 0
        //figure out what direaction we're going in
        if ( Math.abs(pos_a.x-pos_b.x) > Math.abs(pos_a.y-pos_b.y)){
            //horizontal
            if (pos_a.x > pos_b.x)  x_shift = fbo.width
            else                    x_shift = -fbo.width
        }
        else{
            //vertical
            if (pos_a.y > pos_b.y)  y_shift = fbo.height
            else                    y_shift = -fbo.height
        }
        //draw both arrows
        draw_arrow.draw_direct_bez({
            src:arrow_info.src,
            target:{x:target_ter.army_pos.x+x_shift, y:target_ter.army_pos.y+y_shift},
            arrow_pad_dist: arrow_info.arrow_pad_dist,
            draw_hash: arrow_info.draw_hash,
            fbo: arrow_info.fbo,
            ui_hit_circles:ui_hit_circles,
            p:arrow_info.p
        });
        draw_arrow.draw_direct_bez({
            //src:{x:ter.army_start_pos.x-x_shift, y:ter.army_start_pos.y-y_shift},
            src:{x:arrow_info.src.x-x_shift, y:arrow_info.src.y-y_shift},
            target: arrow_info.target,
            arrow_pad_dist: arrow_info.arrow_pad_dist,
            draw_hash: arrow_info.draw_hash,
            fbo: arrow_info.fbo,
            ui_hit_circles:ui_hit_circles,
            p: arrow_info.p
        });
    }

    fbo.colorMode(p.RGB);   //make sure we're bakc on RGB at the end
}

function draw_support_order(order, ter, player, fbo, ui_hit_circles, p){
    if (order.was_successful) {
        fbo.colorMode(p.HSB);
        fbo.strokeWeight(support_trail_stroke.w);
        fbo.stroke(player.hue, support_trail_stroke.s, support_trail_stroke.b, support_trail_stroke.a)
        fbo.fill(player.hue, support_trail_fill.s, support_trail_fill.b, support_trail_fill.a)
    } else {
        fbo.colorMode(p.HSB);
        fbo.strokeWeight(move_trail_failed_stroke.w);
        fbo.stroke(player.hue, move_trail_failed_stroke.s, move_trail_failed_stroke.b, move_trail_failed_stroke.a);
        fbo.fill(player.hue, move_trail_failed_fill.s, move_trail_failed_fill.b, move_trail_failed_fill.a);
    }

	//Support Move Order
	if (order.support_type == "move") {
        // in the case of supporting a move from supply, the target territory will be listed as order.supported_ter
        let supported_army_start_pos;
        if (order.is_supply_support) {
            supported_army_start_pos = {
                x: order.supported_ter.army_start_pos.x,
                y: order.supported_ter.army_start_pos.y,
            };
            supported_army_start_pos.x += 25;
            supported_army_start_pos.y -= 35;
        } else {
            if (order.supported_ter.order == null) {
                console.log("there was no order to support");
                return;
            }
            supported_army_start_pos = order.supported_ter.order.was_successful ? order.supported_ter.army_start_pos : order.supported_ter.army_pos;
        }

        let end_is_oversea = false
        if (ter.oversea_neighbor_id == order.target_ter.id){
            end_is_oversea = true;
        }
        let src_is_oversea = false
        if (order.supported_ter.oversea_neighbor_id == order.target_ter.id){
            src_is_oversea = true;
        }

        //is_oversea = true
        // console.log("support end overseas: "+end_is_oversea)
        // console.log("support src overseas: "+src_is_oversea)

        //if it's not overseas, just draw the arrow
        if (!end_is_oversea && !src_is_oversea){
            draw_arrow.draw_support_bez({
                support_src:ter.army_pos,
                src: supported_army_start_pos,
                target:order.target_ter.army_pos,
                arrow_pad_dist: support_move_arrow_padding,
                fbo:fbo,
                ui_hit_circles:ui_hit_circles,
                p:p
            })
        }
        //otherwise draw 2 to do the screen wrap
        //the end territory is overseas
        else if(end_is_oversea){
            let pos_a = ter.army_pos
            let pos_b = order.target_ter.army_pos

            let x_shift = 0
            let y_shift = 0
            //figure out what direaction we're going in
            if ( Math.abs(pos_a.x-pos_b.x) > Math.abs(pos_a.y-pos_b.y)){
                //horizontal
                if (pos_a.x > pos_b.x)  x_shift = fbo.width
                else                    x_shift = -fbo.width
            }
            else{
                //vertical
                if (pos_a.y > pos_b.y)  y_shift = fbo.height
                else                    y_shift = -fbo.height
            }
            
            //draw both arrows
            draw_arrow.draw_support_bez({
                support_src:ter.army_pos,
                src: {x:supported_army_start_pos.x+x_shift, y:supported_army_start_pos.y+y_shift},
                target: {x:order.target_ter.army_pos.x+x_shift, y:order.target_ter.army_pos.y+y_shift},
                arrow_pad_dist: support_move_arrow_padding,
                fbo:fbo,
                ui_hit_circles: ui_hit_circles,
                p:p
            })
            draw_arrow.draw_support_bez({
                support_src:{x:ter.army_pos.x-x_shift, y:ter.army_pos.y-y_shift},
                src: supported_army_start_pos,
                target:order.target_ter.army_pos,
                arrow_pad_dist: support_move_arrow_padding,
                fbo:fbo,
                ui_hit_circles: ui_hit_circles,
                p:p
            })
        }
        //the source territory is overseas
        else{
            let pos_a = ter.army_pos
            let pos_b = supported_army_start_pos

            let x_shift = 0
            let y_shift = 0
            //figure out what direaction we're going in
            if ( Math.abs(pos_a.x-pos_b.x) > Math.abs(pos_a.y-pos_b.y)){
                //horizontal
                if (pos_a.x > pos_b.x)  x_shift = fbo.width
                else                    x_shift = -fbo.width
            }
            else{
                //vertical
                if (pos_a.y > pos_b.y)  y_shift = fbo.height
                else                    y_shift = -fbo.height
            }
            
            //draw both arrows
            draw_arrow.draw_support_bez({
                support_src:ter.army_pos,
                src: {x:supported_army_start_pos.x+x_shift, y:supported_army_start_pos.y+y_shift},
                target: order.target_ter.army_pos,
                arrow_pad_dist: support_move_arrow_padding,
                fbo:fbo,
                ui_hit_circles: ui_hit_circles,
                p:p
            })
            draw_arrow.draw_support_bez({
                support_src:{x:ter.army_pos.x-x_shift, y:ter.army_pos.y-y_shift},
                src: supported_army_start_pos,
                target: {x:order.target_ter.army_pos.x-x_shift, y:order.target_ter.army_pos.y-y_shift},
                arrow_pad_dist: support_move_arrow_padding,
                fbo:fbo,
                ui_hit_circles: ui_hit_circles,
                p:p
            })

        }
    }

    //Support Hold Order
    if (order.support_type == "hold") {
        let is_oversea = false
        if (ter.oversea_neighbor_id == order.supported_ter.id){
            is_oversea = true;
        }

        if (!is_oversea){
            draw_arrow.draw_direct_bez({
                src: ter.army_pos,
                target:order.supported_ter.army_pos,
                arrow_pad_dist: support_hold_arrow_padding,
                draw_hash: true,
                fbo: fbo,
                ui_hit_circles: ui_hit_circles,
                p:p
            });
        }
        else{
            let pos_a = ter.army_start_pos
            let pos_b = order.supported_ter.army_pos

            let x_shift = 0
            let y_shift = 0
            //figure out what direaction we're going in
            if ( Math.abs(pos_a.x-pos_b.x) > Math.abs(pos_a.y-pos_b.y)){
                //horizontal
                if (pos_a.x > pos_b.x)  x_shift = fbo.width
                else                    x_shift = -fbo.width
            }
            else{
                //vertical
                if (pos_a.y > pos_b.y)  y_shift = fbo.height
                else                    y_shift = -fbo.height
            }
            //draw both arrows
            draw_arrow.draw_direct_bez({
                src: ter.army_pos,
                target:{x:order.supported_ter.army_pos.x+x_shift, y:order.supported_ter.army_pos.y+y_shift},
                arrow_pad_dist: support_hold_arrow_padding,
                draw_hash: true,
                fbo: fbo,
                ui_hit_circles:ui_hit_circles,
                p:p
            });
            draw_arrow.draw_direct_bez({
                src: {x:ter.army_pos.x-x_shift, y:ter.army_pos.y-y_shift},
                target:order.supported_ter.army_pos,
                arrow_pad_dist: support_hold_arrow_padding,
                draw_hash: true,
                fbo: fbo,
                ui_hit_circles:ui_hit_circles,
                p:p
            });
            /*
            draw_arrow.draw_direct_bez({
                src:arrow_info.src,
                target:{x:target_ter.army_pos.x+x_shift, y:target_ter.army_pos.y+y_shift},
                arrow_pad_dist: arrow_info.arrow_pad_dist,
                draw_hash: arrow_info.draw_hash,
                fbo: arrow_info.fbo,
                p:arrow_info.p
            });
            draw_arrow.draw_direct_bez({
                src:{x:ter.army_start_pos.x-x_shift, y:ter.army_start_pos.y-y_shift},
                target: arrow_info.target,
                arrow_pad_dist: arrow_info.arrow_pad_dist,
                draw_hash: arrow_info.draw_hash,
                fbo: arrow_info.fbo,
                p: arrow_info.p
            });
            */
        }
    }

    fbo.colorMode(p.RGB);   //make sure we're bakc on RGB at the end
}

function draw_build_order(order, ter, player, fbo, p){
	//need to add
}

//this drew a "+1" above sucessful farm orders. No longer used or needed because that is included in the sprite now
//should be fine to remove
function draw_farm_order(order, ter, player, fbo, p){
	fbo.colorMode(p.HSB)
    fbo.fill(player.hue,60,80)
    fbo.stroke(player.hue,10,100)
    fbo.colorMode(p.RGB)

    fbo.strokeWeight(8)
    fbo.textSize(45)
    fbo.textStyle(p.NORMAL)
    fbo.textAlign(p.CENTER, p.CENTER)
    fbo.text("+1", ter.army_pos.x, ter.army_pos.y-50)
}


function draw_failed_hold_order({order, ter, game_state, player, icons, fbo, ui_hit_circles, p}){
	fbo.colorMode(p.HSB);

    let this_sat = dead_icon_style.sat;
    if (player.stats.is_dead && player.resigned){
        this_sat = resigned_army_style.sat;
    }

    fbo.tint(player.hue, this_sat, dead_icon_style.bri, dead_icon_style.alpha);
    fbo.colorMode(p.RGB);
    const arm_x = ter.army_pos.x;
    const arm_y = ter.army_pos.y;

    fbo.push();
    fbo.translate(arm_x, arm_y);
    if (ter.should_flip_army_icon){ //should_flip_army_icon icon gets set in draw_territory_army, so make sure that gets called first
        fbo.scale(-1,1)
    }

    fbo.image(icons.army_defeat, -icons.army_defeat.width / 2, -icons.army_defeat.height / 2);
    fbo.pop();

    //marking the space this UI takes up
    if (ui_hit_circles != null){
        ui_hit_circles.push({
            x: arm_x+20,
            y: arm_y+45,
            s: 40
        })
    }
}


function draw_hex(hex, fbo, p) {
    fbo.beginShape();
    for (let i = 0; i < hex.points.length; i++) {
        fbo.vertex(hex.points[i].x, hex.points[i].y);
    }

    fbo.endShape(p.CLOSE);
}

//TODO: you should be able to get rid of this!
function draw_farm_fill(ter, fbo, p){
    fbo.colorMode(p.HSB)
    ter.hexes.forEach( (hex) => {
        if (hex.x % 2 == 0){
            const sat = 45;
            const bri = 65;//78;
            fbo.fill(ter.player.hue, sat, bri, 0.75);
            draw_hex(hex, fbo, p);
        }
    })
    fbo.colorMode(p.RGB)
}

function draw_demo_fill(ter, fbo, p){
    fbo.beginShape();
    //console.log(ter)
    if (ter.lock_group >= 0){
        fbo.colorMode(p.HSB)
        let hue = (ter.lock_group * 35) % 360
        let sat = 50 + (ter.lock_group * 11) % 50
        let bri = 50 + ((ter.lock_group+10) * 47) % 50
        fbo.fill(hue,sat,bri)
    }else{
        fbo.fill(100)
    }


    for (let i = 0; i < ter.perim.length; i++) {
        fbo.vertex(ter.perim[i].a.x, ter.perim[i].a.y);
    }
    fbo.endShape(p.CLOSE);

    fbo.colorMode(p.RGB)
}

//This is no longer used
//TODO: if you like using arrow_pad_dist you cna remove this and all the commented out calls to it in this file
function get_pnt_btwn_territories(ter1, ter2, prc) {
    const avg_x = (1.0 - prc) * ter1.army_pos.x + prc * ter2.army_pos.x;
    const avg_y = (1.0 - prc) * ter1.army_pos.y + prc * ter2.army_pos.y;
    return { x: avg_x, y: avg_y };
}

//this works, but is not used. Easier to do do this in get_bez_steps
function get_pnt_towards_territory(ter1, ter2, dist_from_target){
    const angle = Math.atan2(ter1.army_pos.y - ter2.army_pos.y , ter1.army_pos.x - ter2.army_pos.x)   
    return {
        x: ter2.army_pos.x + Math.cos(angle) * dist_from_target,
        y: ter2.army_pos.y + Math.sin(angle) * dist_from_target
    }
}

export default {
	draw_hex,
	draw_territory_border,
	draw_territory_highlight,
	draw_territory_orders,
	draw_territory_army,
	draw_territory_front,
	draw_overseas_connection,
    draw_demo_fill,
    draw_territory_failed_hold_orders,
    draw_conflict_marker,
    draw_farm_fill
}