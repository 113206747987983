import signer from "./signer.js";
const $ = require("jquery");





function send_message(command, params, success) {
    //firebase url
    let endpoint = "https://us-central1-digiplomacy-8046e.cloudfunctions.net/app/";
    
    if (process.env.LOCAL_TEST){
        //switch to local end point if running locally
        endpoint = "http://localhost:5001/digiplomacy-8046e/us-central1/app/"
    }
    
    let info = Object.assign({}, params);
    let stamp = {
        url: '/' + command,
        room_id: info.room_id,
        server_id: info.server_id,
        utctime: new Date().getTime(),
    }
    info.sig = signer.signature(stamp);
    info.utctime = stamp.utctime;

    let url = endpoint + command;
    $.post(url, info, success);
}


export default {
    send_message,
}
