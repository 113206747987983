import React, { useState, useEffect, useCallback } from "react";
import { Link } from "@reach/router";
import styled from "@emotion/styled";

import useGameState from "../../../hooks/useGameState";
import Button, { DefaultButton } from "../../../components/Button/Button";
import Divider from "../../../components/Divider/Divider";
import Order from "../../../components/Order/Order";
import Textarea from "../../../components/Textarea/Textarea";

import { colors } from "../../../styles";

const Controls = styled.div`
    padding-bottom: 100px;
`;

const InputRow = styled.div`
    margin: 0 -10px 30px;

    @media screen and (min-width: 768px) {
        display: flex;
        flex-direction: row;
    }
`;

const InputColumn = styled.div`
    flex: 1;
    padding: 0 10px;
`;

const PlayerButton = styled(DefaultButton)`
    background: ${({ hue }) => `hsl(${hue}, 70%, 95%)`};

    &:not([disabled]):hover {
        background: ${({ hue }) => `hsl(${hue}, 70%, 80%)`};
    }
`;

const Notice = styled.p`
    font-size: 12px;
`;

const RecentOrders = styled.div`
    border: 1px solid ${colors.gray};
    border-radius: 1px;
    margin: 5px 0px;
    padding: 8px;

    code {
        display: block;
    }
`;

const Header = styled.h4`
    margin: 16px 0 8px;
`;

const Content = () => {
    const { sandbox, players } = useGameState();
    const [inputStates, setInputStates] = useState({});
    const [orders, setOrders] = useState([]);
    const [notice, setNotice] = useState("");
    const [noticePlayer, setNoticePlayer] = useState("");
    const params = window.location.search;

    // Initialize array of input states for players
    useEffect(() => {
        const inputs = {};
        players &&
            players.forEach(({ id }) => {
                inputs[id] = "";
            });
        setInputStates(inputs);
    }, [players]);

    // Callback on input change
    const setInputValue = useCallback(
        (id, value) => {
            setInputStates({
                ...inputStates,
                [id]: value,
            });
        },
        [inputStates]
    );

    // Callback on player input submit
    const onPlayerSubmit = (playerId) => {
        const playerOrders = inputStates[playerId];

        const onRequestSuccess = (response, queuedOrders) => {
            if (response.was_good) {
                // Clear notice and input, save submitted order to array
                setNotice("");
                setInputValue(playerId, "");
                setOrders(queuedOrders);
            } else {
                // Show error in
                setNotice(response.response);
            }
            // Select the player we want to show the notice for
            setNoticePlayer(playerId);
        };

        if (playerOrders) {
            sandbox.submit(playerId, playerOrders, onRequestSuccess);
        }
    };

    // Callback to resolve all submitted orders, empty all inputs
    const resolveOrders = () => {
        sandbox.resolve();
        setOrders([]);
        setNotice("");
        players.forEach(({ id }) => {
            setInputValue(id, "");
        });
    };

    return (
        <>
            <Controls>
                <p>
                    You can enter orders for any of the three players here to
                    test out the rules of the game before comitting to your own
                    orders. You do not need to include "/submit". Don&apos;t forget to hit submit before resolving the
                    turn.
                </p>
                <p>
                    When entering multiple orders at once, seperate with comma
                    or line breaks.
                </p>
                <p>
                    Refresh the page to reset. Refer to the{" "}
                    <Link to={`/rules${params}`}>rules</Link> for info on how to
                    use orders.
                </p>

                <Divider styles="margin: 30px auto 14px;" />

                {players && players.length > 0 && (
                    <>
                        <InputRow>
                            {players.map(({ id, name, hue }) => {
                                return (
                                    <InputColumn key={id}>
                                        <Header>{`${name} Player`}</Header>
                                        <Textarea
                                            cols="20"
                                            rows="6"
                                            placeholder={`Enter orders for ${name} Player here`}
                                            onChange={(e) => {
                                                return setInputValue(
                                                    id,
                                                    e.currentTarget.value
                                                );
                                            }}
                                            style={{
                                                marginBottom: "10px",
                                                resize: "vertical",
                                            }}
                                            value={inputStates[id]}
                                        />
                                        <PlayerButton
                                            onClick={() => onPlayerSubmit(id)}
                                            hue={hue}
                                        >
                                            {`Submit ${name}`}
                                        </PlayerButton>
                                        {noticePlayer === id && (
                                            <Notice>{notice}</Notice>
                                        )}
                                    </InputColumn>
                                );
                            })}
                        </InputRow>
                        {orders && orders.length > 0 && (
                            <>
                                <Header>Submitted orders</Header>
                                <InputRow>
                                    {players.map(({ id }) => {
                                        const playerOrders = orders
                                            ? orders.filter((order) => {
                                                return order.player === id;
                                            })
                                            : [];
                                        return (
                                            <InputColumn key={id}>
                                                {playerOrders && playerOrders.length > 0 && (
                                                    <RecentOrders>
                                                        {playerOrders.map(
                                                            (order, i) => {
                                                                return (
                                                                    <Order
                                                                        {...order}
                                                                        key={order.unique_id || i}
                                                                        was_successful
                                                                    />
                                                                );
                                                            }
                                                        )}
                                                    </RecentOrders>
                                                )}
                                            </InputColumn>
                                        );
                                    })}
                                </InputRow>
                            </>
                        )}
                        <Button
                            disabled={!orders || orders.length === 0}
                            primary
                            onClick={resolveOrders}
                        >
                            Resolve All Orders
                        </Button>
                    </>
                )}
            </Controls>
        </>
    );
};

export default Content;
