import React, { useCallback } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import useGameState from "../../hooks/useGameState.jsx";
import { mixins } from "../../styles";

const Player = styled.button`
    ${mixins.transition()};
    color: ${(props) => props.color};
    cursor: pointer;
    font-weight: bold;
    appearance: none;
    box-shadow: none;
    border: none;
    background: none;
    border: none;
    padding: 0;
    font-family: "Roboto", sans-serif;
    font-size: inherit;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }

    &:focus:not(:focus-visible) {
        outline: 0;
    }
`;

const PlayerName = ({ name, hue, stats, orders, ...rest }) => {
    const { setHighlightedPlayer, setCenteredPlayer } = useGameState();

    const playerObject = {
        ...rest,
        name,
        hue,
        stats,
        orders,
    };

    const onClick = useCallback(() => setCenteredPlayer(playerObject), [
        playerObject,
    ]);
    const onMouseEnter = useCallback(() => setHighlightedPlayer(playerObject), [
        playerObject,
    ]);
    const onMouseLeave = useCallback(() => setHighlightedPlayer(null), []);

    const color = `hsl(${hue}, 90%, 50%)`;

    return (
        <Player
            color={color}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {name}
        </Player>
    );
};

PlayerName.propTypes = {
    name: PropTypes.string,
    hue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    stats: PropTypes.object,
    orders: PropTypes.object,
};

export default PlayerName;
