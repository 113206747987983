import React from "react";
import PropTypes from "prop-types";
import P5Sketch from "react-p5-wrapper";
import styled from "@emotion/styled";
import { mobile } from "../../../util/media-query.js";
import Sidebar from "../../Sidebar/Sidebar";
import GamemasterMessage from "../../GamemasterMessage/GamemasterMessage";
import PlayerTooltip from "../../PlayerTooltip/PlayerTooltip";

const SketchCenterer = styled.div`
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);

    ${(props) =>
        !props.isSandbox &&
        mobile.css`
        // on mobile, fix the sketch in place and set its height to window height to prevent it from sliding around everywhere.
        position: fixed;
        height: 100%;
    `}
`;

const PlayingBoard = ({
    sketch,
    isSandbox,
    isRender,
    gameState,
    wrapperRef,
}) => {
    return (
        <>
            {sketch && (
                <SketchCenterer isSandbox={isSandbox}>
                    <P5Sketch
                        isSandbox={isSandbox}
                        sketch={sketch}
                        reactGameState={gameState}
                    />
                </SketchCenterer>
            )}
            {!isRender && (
                <>
                    <PlayerTooltip boardRef={wrapperRef} />
                    {!isSandbox && <GamemasterMessage />}
                    {!isSandbox && <Sidebar />}
                </>
            )}
        </>
    );
};

PlayingBoard.propTypes = {
    sketch: PropTypes.func,
    isSandbox: PropTypes.bool,
    isRender: PropTypes.bool,
    gameState: PropTypes.object,
    wrapperRef: PropTypes.object,
};

export default PlayingBoard;
