import React, { useState, useCallback } from "react";
import styled from "@emotion/styled";

import useGameState from "../../hooks/useGameState";
import PlayerName from "../PlayerName/PlayerName";

import { mixins, colors, easing } from "../../styles";
import EffectName from "../EffectName/EffectName";

const ExpandToggle = styled.button`
    box-shadow: none;
    appearance: none;
    width: 75px;
    height: 50px;
    border: 2px solid transparent;
    border-right: 0px solid black;
    cursor: pointer;
    top: 90px;
    position: fixed;
    background-image: url("/pic/gamemaster.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 36px auto;
    left: ${(props) => (props.active ? "0px" : "-80px")};
    transition: 0.4s all ${easing.easeOutCubic};
    background-color: white;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

    &:focus {
        border-color: black;
    }

    &:hover {
        background-color: ${colors.grayLight};
    }
`;

const Wrapper = styled.ul`
    transition: 0.3s all ${easing.easeOutCubic};
    transform: translate3d(${(props) => (props.active ? "0%" : "-100%")}, 0, 0);
    left: 0;
    position: fixed;
    top: 60px;
    width: 100%;
    padding: 30px 30px 10px;
    background: white;
    margin: 0;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    z-index: 2;

    @media screen and (min-width: 768px) {
        transform: translate3d(
            ${(props) => (props.active ? "25px" : "-100%")},
            0,
            0
        );
        top: 90px;
        width: auto;
        max-width: 380px;
        border-radius: 5px;
    }
`;

const CloseToggle = styled.button`
    ${mixins.transition()};
    cursor: pointer;
    font-weight: bold;
    color: ${colors.grayDark};
    display: block;
    position: absolute;
    top: -5px;
    right: -6px;
    width: 40px;
    font-size: 16px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border: none;
    box-shadow: none;
    appearance: none;
    background: none;

    &:hover {
        color: black;
    }

    &:focus {
        outline: none;
        text-decoration: underline;
    }
`;

const Item = styled.li`
    list-style: none;
    margin-bottom: 15px;
`;

const HeaderWrapper = styled(Item)`
    position: relative;
    display: flex;
    align-items: center;
`;

const Gamemaster = styled.img`
    display: block;
    width: 50px;
`;

const Header = styled.div`
    padding: 0 40px 0 15px;
`;

const Paragraph = styled.p`
    margin: 0 0 10px;
`;

const SpecialModeText = styled.div`
    font-style: italic;
    color: ${colors.grayDark};
    font-weight: normal;
`;

const MiniPerformanceMode = styled.span`
    font-style: italic;
    color: ${colors.grayDark};
    font-weight: normal;
    font-size: 14px;
`;

const TurnLink = styled.a`
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
        text-decoration: none;
    }
`;

const Notice = styled.p`
    margin: 0;
    font-weight: bold;
`;

const GameOverNotice = styled.p`
    margin: 0;
    font-weight: bold;
    font-size: 30px;
`;

const GamemasterMessage = () => {
    const {
        players,
        turn,
        setTurn,
        totalTurns,
        roomInfo,
        setHoveringCanvas,
        setHighlightedPlayer,
    } = useGameState();

    const { winner, cur_voting_event, awarded_voting_event, performance_mode, same_day_votes  } =
        roomInfo || {};
    const viewingCurrentTurn = turn === totalTurns;

    const isMobile = window.matchMedia('(max-width:768px)')

    const [controlsActive, setControlsActive] = useState(!isMobile.matches);

    const onMouseEnter = useCallback(() => {
        setHoveringCanvas(false);
        setHighlightedPlayer(null);
    }, []);
    const onMouseLeave = useCallback(() => {
        setHoveringCanvas(true);
    }, []);

    const loadLastTurn = useCallback(() => {
        setTurn(totalTurns);
    }, [totalTurns]);

    // Don't show the message if we have no players (e.g. invalid game)
    if (!players || players.length === 0) {
        return null;
    }

    const awardedPlayer =
        awarded_voting_event &&
        players.find((player) => player.id === awarded_voting_event.winner);
    const tiePlayer =
        cur_voting_event &&
        players.find((player) => player.id === cur_voting_event.winner_in_tie_id);
    //const winningPlayer =
        //winner && players.find((player) => winner && player.name === winner);
    const winningPlayer =
        winner && players.find((player) => player.id === winner.id);

    return (
        <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <ExpandToggle
                onClick={() => setControlsActive(true)}
                active={!controlsActive}
            />
            <Wrapper active={controlsActive}>
                <HeaderWrapper>
                    <Gamemaster src="/pic/gamemaster.jpg" />
                    <Header>
                        {!winner || !viewingCurrentTurn ? (
                            <>
                                {!viewingCurrentTurn ? (
                                    <Notice>Viewing past turn</Notice>
                                ) : (
                                    <Notice>
                                        {performance_mode && (
                                            <SpecialModeText>
                                                FAST MODE
                                            </SpecialModeText>
                                        )}
                                        <span id="countdown_text">
                                            Loading countdown...
                                        </span>{" "}
                                    </Notice>
                                )}
                            </>
                        ) : (
                            <GameOverNotice>GAME OVER! 🎉</GameOverNotice>
                        )}
                    </Header>
                    <CloseToggle onClick={() => setControlsActive(false)}>
                        X
                    </CloseToggle>
                </HeaderWrapper>
                {!viewingCurrentTurn && (
                    <Item>
                        <Paragraph>
                            I don't have anything more to say about this turn.
                            You can{" "}
                            <TurnLink onClick={loadLastTurn}>
                                return to the current turn
                            </TurnLink>{" "}
                            to see my latest messages.
                        </Paragraph>
                    </Item>
                )}
                {!winner && turn === totalTurns && awarded_voting_event && (awarded_voting_event.is_player_vote==true || awarded_voting_event.is_player_vote==null) && (
                    <Item>
                        <Paragraph>
                            Currently accepting orders. Any orders involving{" "}
                            <PlayerName {...awardedPlayer} /> will be affected
                            by <EffectName {...awarded_voting_event} />.{" "}
                        </Paragraph>
                    </Item>
                )}
                {!winner && turn === totalTurns && awarded_voting_event &&  !same_day_votes && awarded_voting_event.is_player_vote==false && (
                    <Item>
                        <Paragraph>
                            Currently accepting orders. All orders this turn will be affected{" "} 
                            by <EffectName {...awarded_voting_event} />.{" "}
                        </Paragraph>
                    </Item>
                )}
                {turn === totalTurns && awarded_voting_event && same_day_votes && awarded_voting_event.is_player_vote==false && (
                    <Item>
                        <Paragraph>
                            Orders submitted last turn were affected{" "} 
                            by <EffectName {...awarded_voting_event} />.{" "}
                        </Paragraph>
                    </Item>
                )}
                
                {!winner && turn === totalTurns && cur_voting_event && !same_day_votes && (cur_voting_event.is_player_vote==true || cur_voting_event.is_player_vote==null) && (
                    <Item>
                        <Paragraph>
                            The society is currently voting on the target of
                            next turn’s effect,{" "}
                            <EffectName {...cur_voting_event} />. In the event
                            of a tie, the effect will go to{" "}
                            <PlayerName {...tiePlayer} />.
                        </Paragraph>
                    </Item>
                )}
                {!winner && turn === totalTurns && cur_voting_event && same_day_votes && (cur_voting_event.is_player_vote==true || cur_voting_event.is_player_vote==null) && (
                    <Item>
                        <Paragraph>
                            The society is currently voting on the target of
                            this turn’s effect,{" "}
                            <EffectName {...cur_voting_event} />. In the event
                            of a tie, the effect will go to{" "}
                            <PlayerName {...tiePlayer} />.
                        </Paragraph>
                    </Item>
                )}
                {!winner && turn === totalTurns && cur_voting_event && !same_day_votes && cur_voting_event.is_player_vote==false && (
                    <Item>
                        <Paragraph>
                            The society is currently voting on next turn’s effect,{" "}
                            <EffectName {...cur_voting_event} />. In the event
                            of a tie,{" "}
                            <b>{cur_voting_event.winner_in_tie_id[0].toUpperCase() + cur_voting_event.winner_in_tie_id.substring(1)}</b>{" "}
                            will be chosen.
                        </Paragraph>
                    </Item>
                )}
                {!winner && turn === totalTurns && cur_voting_event && same_day_votes && cur_voting_event.is_player_vote==false && (
                    <Item>
                        <Paragraph>
                            The society is currently voting on this turn’s effect,{" "}
                            <EffectName {...cur_voting_event} />. In the event
                            of a tie,{" "}
                            <b>{cur_voting_event.winner_in_tie_id[0].toUpperCase() + cur_voting_event.winner_in_tie_id.substring(1)}</b>{" "}
                            will be chosen.
                        </Paragraph>
                    </Item>
                )}
                {winningPlayer && (
                    <Item>
                        <Paragraph>
                            Congratulations to <PlayerName {...winningPlayer} />
                            , who won this game after {totalTurns} turns with a
                            VP of {winningPlayer.stats?.victory_points}. Thanks
                            for playing!
                        </Paragraph>
                    </Item>
                )}
            </Wrapper>
        </div>
    );
};

export default GamemasterMessage;
