import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import Tooltip from "../Tooltip/Tooltip";
import EffectName from "../EffectName/EffectName";

const SupplementaryText = styled.span`
    color: grey;
    margin-left: 5px;
`;

// eslint is having some real issues with how Prettier wants to format styled-components
/* eslint-disable indent */
const OrderContent = styled.span`
    cursor: default;

    &:hover {
        text-decoration: ${(props) =>
            props.hoverStyles ? "underline" : "none"};
        font-weight: ${(props) => (props.hoverStyles ? "bold" : "normal")};
    }

    ${({ unsuccessful }) => {
        return (
            unsuccessful &&
            `
            color: red;
            text-decoration: underline;
        `
        );
    }}

    ${({ invalid }) => {
        return (
            invalid &&
            `
            color: grey;
            text-decoration: line-through;

            &:hover {
                text-decoration: line-through;
            }`
        );
    }}
`;
const TooltipWrapper = styled.code`
    color: black;
    text-decoration: none;
`;
/* eslint-enable indent */

const OrderContentTooltip = styled.div`
    background-color: white;
    width: 250px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    padding: 10px 15px;
    font-size: 14px;
    color: black;
    z-index: 2;
`;

const Order = ({
    was_successful,
    valid,
    is_priority,
    orig_order,
    invalid_reason,
    relevant_vote_event,
    summary_text,
}) => {
    return (
        <TooltipWrapper>
            <Tooltip
                transitionDuration="0s"
                renderTooltip={() => {
                    return (
                        summary_text && (
                            <OrderContentTooltip>
                                {summary_text}
                            </OrderContentTooltip>
                        )
                    );
                }}
            >
                <OrderContent
                    invalid={!valid}
                    unsuccessful={!was_successful}
                    hoverStyles={summary_text}
                >
                    {orig_order}
                    {is_priority && <strong> (priority)</strong>}
                </OrderContent>
            </Tooltip>
            {invalid_reason && (
                <SupplementaryText>({invalid_reason})</SupplementaryText>
            )}
            {relevant_vote_event && (
                <SupplementaryText>
                    [
                    <EffectName
                        {...relevant_vote_event}
                        styleString="font-weight: normal"
                        showPlayer
                    />
                    ]
                </SupplementaryText>
            )}
        </TooltipWrapper>
    );
};

Order.propTypes = {
    was_successful: PropTypes.bool,
    valid: PropTypes.bool,
    is_priority: PropTypes.bool,
    orig_order: PropTypes.string,
    invalid_reason: PropTypes.string,
    relevant_vote_event: PropTypes.object,
    summary_text: PropTypes.string,
};

export default Order;
