import React, { useCallback } from "react";
import styled from "@emotion/styled";

import useGameState from "../../hooks/useGameState";
import Button from "../Button/Button";

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 0 -3px;
`;

const TurnLabel = styled.span`
    display: inline-block;
    padding: 0 5px;
    flex: 3;
    text-align: center;
`;

const firstTurn = 1; // don't allow users to navigate to turn 0

const TurnControls = () => {
    const { turn, setTurn, totalTurns} = useGameState();

    const loadFirstTurn = useCallback(() => {
        setTurn(firstTurn);
    }, []);
    const loadPrevTurn = useCallback(() => {
        setTurn(Math.max(firstTurn, turn - 1));
    }, [turn]);
    const loadNextTurn = useCallback(() => {
        setTurn(Math.min(totalTurns, turn + 1));
    }, [turn, totalTurns]);
    const loadLastTurn = useCallback(() => {
        setTurn(totalTurns);
    }, [totalTurns]);

    const hasTurnsBefore = turn > firstTurn;
    const hasTurnsAfter = turn < totalTurns;

    return (
        <Wrapper>
            <Button
                style={{ flex: 1, margin: "0 3px" }}
                small
                disabled={!hasTurnsBefore}
                onClick={loadFirstTurn}
            >
                &lt;&lt;
            </Button>{" "}
            <Button
                style={{ flex: 1, margin: "0 3px" }}
                small
                disabled={!hasTurnsBefore}
                onClick={loadPrevTurn}
            >
                &lt;
            </Button>{" "}
            <TurnLabel>{turn >= -1 ? `Turn ${turn}` : "Loading..."}</TurnLabel>
            <Button
                style={{ flex: 1, margin: "0 3px" }}
                small
                disabled={!hasTurnsAfter}
                onClick={loadNextTurn}
            >
                &gt;
            </Button>{" "}
            <Button
                style={{ flex: 1, margin: "0 3px" }}
                small
                disabled={!hasTurnsAfter}
                onClick={loadLastTurn}
            >
                &gt;&gt;
            </Button>{" "}
        </Wrapper>
    );
};

export default TurnControls;
