const crypto = require('crypto');

function signature(obj) {
  let keys = Object.keys(obj);
  keys.sort();
  let my_secret = process.env.DIGIPLOMACY_SECRET;
  if (!my_secret) {
    console.log('DIGIPLOMACY_SECRET is empty');
    return '';
  }
  let hash = crypto.createHmac('sha256', my_secret);
  for (let i = 0; i < keys.length; i++) {
    let key = keys[i].toString();
    if (!obj[key]) {
      continue;
    }
    let val = obj[key].toString();
    if (!val) {
      continue;
    }
    hash.update(key);
    hash.update(':');
    hash.update(val);
    hash.update(',');
  }
  return hash.digest('hex');
}

module.exports.signature = signature;
