import React from "react";
import styled from "@emotion/styled";

import Page from "../../components/Page/Page";
import { DefaultExternalLink } from "../../components/Button/Button";

const Main = styled.div`
    max-width: 600px;
    margin: 40px auto;
`;

const ProductWrapper = styled.div`
    margin: 36px auto;
`;

const ProductHeadder = styled.h2`
    text-align: center;
`;

const ProductCta = styled(DefaultExternalLink)`
    margin: 0 auto 20px;
    display: inline-block;
`;

const ProductImage = styled.img`
    display: block;
    margin: 0 auto;
`;

const CenterParagraph = styled.p`
    text-align: center;
    margin: 0;
`;

const BotCommand = styled.span`
    background: #ddd;
    color: #f00;
    font-family: "Courier New", Courier, monospace;
    marg;
    padding-left: 2px;
    padding-right: 2px;
`;

const Shop = () => {
    return (
        <Page>
            <Main>
                <h1>High Society Account Upgrade</h1>

                <p>
                    You can play Skirmish Society for free by <a target="_blank" href='/setup-guide'>installing it on your Discord server</a>, but you can improve your experience and support the development of the game by purchasing a High Society account upgrade!
                </p>

                

                <ProductWrapper>
                    <ProductImage
                        src="/pic/shop/height_150-diamond.png"
                    />
                    <ProductHeadder>High Society</ProductHeadder>
                    <p></p>
                    <CenterParagraph>
                        <ProductCta
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://andymakes.itch.io/skirmish-society-high-society"
                            primary
                        >
                            Upgrade Now $3
                        </ProductCta>
                    </CenterParagraph>

                    <p>
                        Redeeming this key will upgrade your Discord account for
                        Skirmish Society.{" "}
                        <strong>
                            This is an add-on to your account.
                        </strong>
                    </p>
                    <p>You get...</p>
                    
                    <h2>Funky New Territory Patterns</h2>

                    <p>
                        Upgrading your account gives you access to 8 new patterns to mark your territory.
                    </p>
                    <p>
                        <img src="pic/rules/patterns_premium.png" alt="premium player patterns"></img>
                    </p>

                    <h2>Order Verification</h2>

                    <p>
                    The bot will double check your orders when you submit them to make sure they're valid. No more gnashing of teeth when an order fails because of a typo!
                    </p>
                    <p>
                        <img src="pic/shop/order_checker.jpg" alt="bot checking if an order is valid"></img>
                    </p>

                    <h2>Order Replacement Heads Up</h2>

                    <p>
                    The bot will let you know if you just submitted an order that will replace a previous one.
                    </p>
                    <p>
                        <img src="pic/shop/order_replace.jpg" alt="bot confirming that a new order replaces an older one"></img>
                    </p>
                    
                    <h2>Premium Admin Commands</h2>

                    <p>
                        If you're the one organizing the game, having the High Society 
                        upgrade will allow you to take control of how
                        you run your server. You get access to new tools 
                        like the ability to <a href="/rules/#High Society Admin Commands">rewind a turn</a> or
                        put the game into <a href="/rules/#High Society Admin Commands">fast mode</a>.
                    </p>

                    <p>You can check out all High Society admin commands <a href='/rules/#High Society Admin Commands'>on the rules page</a>, but here is some of what you can do:</p>


                    <h3>Fast Mode</h3>

                    <p>
                        Want to stream Skirmish Society or play a game in one night with friends? With High Society you can enable fast mode where turns resolve every 15 minutes.
                    </p>

                    <p>
                        <img src="pic/shop/fast_mode.jpg" alt="putting the game into fast mode"></img>
                    </p>

                    <h3>Rewind</h3>

                    <p>
                        Forget to pause the game for your friend's wedding? High Society admins have the ability to rewind the game one turn. The game will be exactly back in the state it was in before the last resolution.
                    </p>


                    <h3>Advanced Setup</h3>

                    <p>
                        If you have High Society you can use <a href='/rules/#High Society Admin Commands'>/advanced_setup</a> to create a game exactly to your liking. Here are some of the setup options you'll have access to:
                    </p>

                    <ul>
                        <li>Victory points to win</li>
                        <li>Vote frequency</li>
                        <li>Fast mode</li>
                    </ul>

                    <p>You can even try a game with some experimental features</p>

                    <ul>
                        <li>A more spacious map with a little more room to stretch your arms</li>
                        <li>A new mode where voting events take effect the same turn they're announced</li>
                    </ul>


                    <p>
                        <img src="pic/shop/advanced_setup.jpg" alt="bot conifrmation message for advanced setup"></img>
                    </p>

                    

                    <h2>The Satisfaction of Supporting Indie Development</h2>

                    <p>
                        This game is a labor of love, but it's a labor of love with server costs! Buying this upgrade is the best way to support development.
                    </p>

                    <h1>Upgrade Now!</h1>

                    <p>
                        This upgrade is tied to your Discord account. If you
                        play Skirmish Society on a new server, you will still
                        get the perks of an upgraded account.
                    </p>

                    <CenterParagraph>
                        <ProductCta
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://andymakes.itch.io/skirmish-society-high-society"
                            primary
                        >
                            Upgrade Now $3
                        </ProductCta>
                    </CenterParagraph>

                    <p>
                        <strong>
                            Upgrading your account does not confer any in-game
                            advantage and never will. 
                        </strong> You will have to make deals and betray your friends like everybody else.
                    </p>

                    <p>
                        Purchases are handled through <a href='https://andymakes.itch.io/skirmish-society-high-society'>Itch.io</a>.
                    </p>

                    
                    <p>
                        <strong>A note from Andy:</strong> There are a few
                        features that are nice to have, but which create a bit
                        too much of a server load for me to be able to include
                        them across the board. If you are into the game and want
                        to help support it, you can upgrade your account. This
                        will help me cover server costs and give you access to
                        some nice features.
                    </p>
                    <p>
                    It is my hope that sales of this upgrade will be enough to cover server costs and keep this game free.  I want this game to be accessible. It is not my main source of income and if a bunch of folks are playing it and it sustains itself, I'm content.  I am lucky enough that the time I dedicate to it can be a labor of love. However there are very real server and maintenance costs associated with a game like this. It is my hope that selling High Society upgrades to people who are enjoying the game and can afford it will cover these costs.
                    </p>
                    
                </ProductWrapper>
            </Main>
        </Page>
    );
};

export default Shop;
