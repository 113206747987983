import data_pull from "./data_pull.js";
import draw_map from "./draw_map.js";
import sketch from "./sketch.js";
import sandbox from "./sandbox.js";

// defaults
// See GameInstance.jsx for all React app state values and setters
let mounted = false;
let reactState = {};

// Helpers

// Defensive check for comparing past and next player objects
const canComparePlayers = (key, nextGameState) => {
    return (
        mounted &&
        reactState &&
        ((reactState[key] &&
            nextGameState[key] &&
            reactState[key].name !== nextGameState[key].name) ||
            (!reactState[key] && nextGameState[key]) ||
            (!nextGameState[key] && reactState[key]))
    );
};

// Callbacks for P5 when React state changes

const _updateMapFromProps = (nextGameState, { fbos, icons, p, game_state }) => {
    if (
        mounted &&
        reactState &&
        reactState.showOrders !== nextGameState.showOrders
    ) {
        draw_map.refresh_ui({
            show_orders: nextGameState.showOrders,
            fbo: fbos.ui,
            icons,
            p,
            game_state,
        });
    }
};

const _updateTurnFromProps = (nextGameState, { game_state, loadCallback }) => {
    if (
        mounted &&
        !game_state.is_sandbox &&
        reactState &&
        reactState.turn !== nextGameState.turn
    ) {
        data_pull.load_map_for_turn(
            nextGameState.turn,
            game_state,
            loadCallback
        );
    }
};

const _updateCenterFromProps = (
    nextGameState,
    { game_state, fbos, p, grid_info }
) => {
    // Center and highlight player on click
    if (canComparePlayers("centeredPlayer", nextGameState)) {
        if (nextGameState.centeredPlayer) {
            console.log("centering");
            sketch.center_cam({
                player_id: nextGameState.centeredPlayer.id,
                grid_info,
                p,
            });
        }
        draw_map.refresh_highlight_fbo({
            p,
            fbo: fbos.highlight,
            highlighted_player: nextGameState.centeredPlayer,
            territories: game_state.territories,
        });
    }
};

const _updateHighlightFromProps = (nextGameState, { game_state, fbos, p }) => {
    // Highlight player on hover (if we don't have a centered player)
    // Or re-highlight if we're losing the centered player
    if (
        (canComparePlayers("highlightedPlayer", nextGameState) &&
            !reactState.centeredPlayer &&
            !nextGameState.centeredPlayer) ||
        (nextGameState.highlightedPlayer && !nextGameState.centeredPlayer)
    ) {
        //console.log("UPDATE HIGHLIGHT")
        draw_map.refresh_highlight_fbo({
            p,
            fbo: fbos.highlight,
            highlighted_player: nextGameState.highlightedPlayer,
            territories: game_state.territories,
        });
    }
};

const _updateCanvasSizeFromProps = (nextGameState) => {
    if (nextGameState.sidebarActive !== reactState.sidebarActive) {
        const isOpening = nextGameState.sidebarActive;
        const timeout = isOpening ? 300 : 60;

        setTimeout(() => {
            const resizeEvent = window.document.createEvent("UIEvents"); 
            resizeEvent.initUIEvent("resize", true, false, window, 0); 
            window.dispatchEvent(resizeEvent);
        }, timeout);
    }
};

// State get/set for local copy of React state

const getGameState = () => {
    return reactState;
};

const setGameState = (nextGameState) => {
    reactState = nextGameState;
};

// Lifecycle methods

const init = (gameState) => {
    // Set initial UI app values with results of data fetch
    reactState.initWithGameState({
        gameState,
        sandbox: {
            submit: sandbox.submit_sandbox_orders,
            resolve: sandbox.resolve_sandbox,
        },
    });
    mounted = true;
};

const declareBadRoom = () => {
    reactState.setBadRoom(true);
};

const update = (gameState) => {
    reactState.updateWithGameState({ gameState });
};

const handleNewProps = (props, config) => {
    // Callbacks for nuggets of UI state
    _updateMapFromProps(props.reactGameState, config);
    _updateTurnFromProps(props.reactGameState, config);
    _updateHighlightFromProps(props.reactGameState, config);
    _updateCenterFromProps(props.reactGameState, config);
    _updateCanvasSizeFromProps(props.reactGameState, config);

    // Save new state
    setGameState(props.reactGameState);
};

export default { init, update, declareBadRoom, handleNewProps, getGameState };
