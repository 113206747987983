import sketch from "./sketch.js";
import ui_bridge from "./ui_bridge.js";
import backend from "./backend.js";

const $ = require("jquery");


function start_initial_load(game_state, callback, turn_to_load) {
    game_state.is_sandbox = game_state.room_id == "sandbox";

    // base map data  - does not change based on turn
    backend.send_message(
        "get_base_map",
        { room_id: game_state.room_id },
        function (_base_map_data) {
            game_state.base_map_data = _base_map_data;
            console.log(
                `got base map: ${game_state.base_map_data.length} entries`
            );
            check_load(game_state, callback);
        }
    );

    // player data - does not change based on turn
    backend.send_message("get_players", { room_id: game_state.room_id }, function (
        _base_player_data
    ) {
        game_state.base_player_data = _base_player_data;
        console.log(
            `got player data: ${game_state.base_player_data.length} entries`
        );
        check_load(game_state, callback);
    });

    game_state.data_has_loaded = false; //trying only setting this to false on the very first load

    load_map_for_turn(turn_to_load, game_state, callback);
}

//use -1 for turn_num to get most recent turn
function load_map_for_turn(turn_num, game_state, callback) {
    console.log(`loading turn ${turn_num}`);

    if (game_state.still_loading){
        console.log("Can't grab a turn right now. I'm already loading a turn.")
        return;
    }
    
    game_state.still_loading = true;    //flag to mark that we are currently pulling data
    
    game_state.loading_progress = 0;

    game_state.room_info = null;
    game_state.map_data = null;
    game_state.orders = null;
    game_state.player_stats = null;
    game_state.players = null;

    //TODO: replace this with some react magic
    $("#turn_num_text").html("loading...");

    // room info
    backend.send_message(
        "get_room_info",
        { room_id: game_state.room_id },
        function (_room_info) {
            game_state.room_info = _room_info;
            console.log("got room info");
            if (game_state.room_info == null) {
                console.log(`no room found for id ${game_state.room_id}`);
                game_state.bad_room_id = true;
                // Tell the frontend that this room is BAD!
                ui_bridge.declareBadRoom();
            } else { 
                console.log(`got room info`);
                //console.log(game_state.room_info);

                // time data
                backend.send_message(
                    "get_time_to_next_resolution",
                    { room_info_string: JSON.stringify(game_state.room_info) },
                    function (time_data) {
                        sketch.set_time_info(time_data, game_state.room_info);
                    }
                );
                check_load(game_state, callback);
            }
        }
    );

    // map data
    backend.send_message(
        "get_cur_map",
        { room_id: game_state.room_id, turn_num: turn_num },
        function (_map_data) {
            game_state.map_data = _map_data;
            console.log(
                `got cur map data: ${game_state.map_data.length} entries`
            );
            check_load(game_state, callback);
        }
    );

    // orders
    backend.send_message(
        "get_orders",
        { room_id: game_state.room_id, turn_num: turn_num },
        function (_orders_data) {
            game_state.orders = _orders_data;
            console.log(`got orders: ${game_state.orders.length} entries`);
            check_load(game_state, callback);
        }
    );

    // player stats
    backend.send_message(
        "get_player_stats",
        { room_id: game_state.room_id, turn_num: turn_num },
        function (_player_stats) {
            game_state.player_stats = _player_stats;
            console.log(
                `got player stats: ${game_state.player_stats.length} entries`
            );
            check_load(game_state, callback);
        }
    );
}

function check_load(game_state, callback) {
    let ready = true;
    game_state.loading_progress = 0;

    if (game_state.room_info != null) game_state.loading_progress++;
    else ready = false;

    if (game_state.base_map_data != null) game_state.loading_progress++;
    else ready = false;

    if (game_state.base_player_data != null) game_state.loading_progress++;
    else ready = false;

    if (game_state.map_data != null) game_state.loading_progress++;
    else ready = false;

    if (game_state.orders != null) game_state.loading_progress++;
    else ready = false;

    if (game_state.player_stats != null) game_state.loading_progress++;
    else ready = false;

    if (ready) {
        console.log("got em all");

        game_state.data_has_loaded = true;
        game_state.still_loading = false; 

        //bounce back to actually set everything up using this data
        callback();

        console.log("done with load");
    } else {
        // console.log("loading: "+game_state.loading_progress)
        //alert("ahhh");
        //     callback();
    }
}

function load_first_turn(game_state, callback) {
    if (!game_state.data_has_loaded) return;
    load_map_for_turn(0, game_state, callback);
}
function load_last_turn(game_state, callback) {
    if (!game_state.data_has_loaded) return;
    load_map_for_turn(-1, game_state, callback);
}
function load_prev_turn(game_state, callback) {
    if (!game_state.data_has_loaded) return;

    let target_turn = game_state.cur_turn - 1;
    if (target_turn < 0) target_turn = 0;
    load_map_for_turn(target_turn, game_state, callback);
}

function load_next_turn(game_state, callback) {
    if (!data_has_loaded) return;

    let target_turn = game_state.cur_turn + 1;
    if (target_turn > game_state.newest_turn_num)
        target_turn = game_state.newest_turn_num;
    load_map_for_turn(target_turn, game_state, callback);
}

export default {
    start_initial_load,
    load_map_for_turn,
    check_load,
    load_first_turn,
    load_prev_turn,
    load_next_turn,
};
