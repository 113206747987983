import React from "react";
import styled from "@emotion/styled";
import { Link } from "@reach/router";

import Page from "../../components/Page/Page";

const NAVBAR_HEIGHT = 90;

const Wrapper = styled.main`
    max-width: 1020px;
    margin: 40px auto;

    [id]::before {
        content: '';
        display: block;
        height:      ${NAVBAR_HEIGHT}px;
        margin-top: -${NAVBAR_HEIGHT}px;
        visibility: hidden;
`;

const BotCommand = styled.span`
    background: #ddd;
    color: #f00;
    font-family: "Courier New", Courier, monospace;
    marg;
    padding-left: 2px;
    padding-right: 2px;
`;

const Rules = () => {
    const params = window.location.search;

    return (
        <Page>
            <Wrapper>
                <h1>Skirmish Society Rules</h1>
                <div className="rules_section">
                    {" "}
                    
                    <div>
                        {/* <div>
                            <p>
                                <b>This game is currently in early access.</b> It will be launching on January 24 2023.
                            </p>
                        </div> */}
                        
                        <h2 id="Overview">Overview</h2>
                        <div className="rules_text">
                            <p>
                                This is a war/territory control game in the vein of{" "}
                                <a href="https://en.wikipedia.org/wiki/Diplomacy_(game)">
                                    Diplomacy
                                </a>{" "}
                                that is embedded in your Discord network. Each turn
                                period lasts one full day. You submit your orders
                                by sending commands to @skirmish-commissioner in
                                the #skirmish-society channel or secretly over DM.
                            </p>

                            <p>
                                The goal is to gain Victory Points by
                                controlling the largest territory or by building
                                and using farms.
                                <b>
                                    The first player to reach 10 Victory Points
                                    is the winner
                                </b>{" "}
                                in a normal game.
                            </p>

                            <p>
                                If you have played Diplomacy, most of the rules
                                will feel pretty similar (you can probably just
                                skim the <a href="#Orders">Orders section</a>
                                ). If not, this game has a few critical
                                differences from most war games (Risk for
                                example).
                            </p>
                            <ol>
                                <li>
                                    Players do not take individual turns. All
                                    orders are resolved simultaneously. During
                                    each turn period, players can submit orders
                                    (instructions to their armies). At
                                    the end of the turn period, all orders are
                                    resolved simultaneously and we see which
                                    orders succeeded and which ones failed. This
                                    is entirely deterministic (no randomness or
                                    die rolls are used). It does not matter if orders were submitted before or after other orders.
                                </li>
                                <li>
                                    Only a single army can be in a territory at
                                    a time (unlike Risk where you can keep
                                    building up your armies in a single
                                    country). An army has a strength of 1.
                                </li>
                            </ol>

                            <p>
                                <b>
                                    Each turn period is 24 hours long. Orders
                                    will be resolved once per day.
                                </b>
                            </p>

                            <p>
                                The rules for Diplomacy state "You are a
                                diplomat first and a general second." That is
                                true here too. You will not be able to win
                                alone. This game takes place on Discord, so you
                                can communicate with all players there.
                            </p>

                            <p>
                                This document is long because it has a lot of
                                examples. If you'd rather just read the heading
                                for each section and mess around in the{" "}
                                <Link to={`/sandbox${params}`}>sandbox</Link>,
                                go for it.
                            </p>

                            <p>
                                If this is your first time playing, you probably
                                want to read up through Submitting Orders, and
                                then skim from there or come back to this doc
                                when you want to look up how specific things
                                work once they come up in game.{" "}
                                <b>
                                    You do not need to read this entire page to
                                    get started.
                                </b>
                            </p>
                        </div>
                    </div>
                    <div
                        className="example"
                        style={{
                            border: "none",
                            minWidth: "300px",
                            padding: "0 30px",
                        }}
                    >
                        <div>
                            <h2 id="Overview">Table of Contents</h2>
                            <ul>
                                <li>
                                    <a href="#Overview">Overview</a>
                                </li>
                                <li>
                                    <a href="#Code of Conduct">
                                        Code of Conduct
                                    </a>
                                </li>
                                <li>
                                    <a href="#Setup">Setup</a>
                                </li>
                                <li>
                                    <a href="#Winning">Winning</a>
                                </li>
                                <li>
                                    <a href="#Combat Basics">Combat Basics</a>
                                </li>
                                <li>
                                    <a href="#The Sandbox">The Sandbox</a>
                                </li>
                                <li>
                                    <a href="#Units and Territories">
                                        Units & Territories
                                    </a>
                                </li>
                                <li>
                                    <a href="#Bot">
                                        Communicating with the Bot
                                    </a>
                                </li>
                                <li>
                                    <a href="#Starting A Game">
                                        Starting A Game
                                    </a>
                                </li>
                                <li>
                                    <a href="#Submitting Orders">
                                        Submitting Orders
                                    </a>
                                </li>
                                <li>
                                    <a href="#Orders">Orders</a>
                                </li>
                                <ul>
                                    <li>
                                        <a href="#Hold Orders">Hold Orders</a>
                                    </li>
                                    <li>
                                        <a href="#Move Orders">Move Orders</a>
                                    </li>
                                    <li>
                                        <a href="#Support Orders">
                                            Support Orders
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#Cutting Support">
                                            Cutting Support
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#Build Orders">Build Orders</a>
                                    </li>
                                    <li>
                                        <a href="#Farm Orders">Farm Orders</a>
                                    </li>
                                </ul>

                                <li>
                                    <a href="#Losing and Gaining Armies">
                                        Losing & Gaining Armies
                                    </a>
                                </li>
                                <ul>
                                    <li>
                                        <a href="#Reinforcement">
                                            Reinforcement Orders
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#Building and Reinforcement">
                                            Building and Reinforcing on the same turn
                                        </a>
                                    </li>
                                </ul>
                                <li>
                                    <a href="#Voting Events">Voting Events</a>
                                </li>
                                <li>
                                    <a href="#Joining Forces">Joining Forces</a>
                                </li>
                                <li>
                                    <a href="#Losing The Game">
                                        Losing The Game
                                    </a>
                                </li>
                                <li>
                                    <a href="#Changing Your Look">
                                        Changing Your Look
                                    </a>
                                </li>
                                <li>
                                    <a href="#High Society">
                                        High Society Account Upgrade
                                    </a>
                                </li>
                                <li>
                                    <a href="#Other Commands">Other Commands</a>
                                </li>
                                <li>
                                    <a href="#Admin Commands">Admin Commands</a>
                                </li>
                                <ul>
                                    <li>
                                        <a href="#High Society Admin Commands">High Society Admin Commands</a>
                                    </li>
                                </ul>
                                <li>
                                    <a href="#Closing Thoughts">Closing Thoughts</a>
                                </li>
                                <li>
                                    <a href="#Credits">Credits</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                

                <h2 id="Code of Conduct">Code of Conduct</h2>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            These types of games can get a bit heated! Before
                            you start playing, take a look at the{" "}
                            <Link to={`/code-of-conduct${params}`}>
                                Code of Conduct
                            </Link>
                            . It's very short.
                        </p>
                    </div>
                </div>

                <h2 id="Setup">Setup</h2>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            Skirmish Society is free to install on your Discord! All you need is:
                        </p>
                        <ul>
                            <li>
                                A Discord you want to play on
                            </li>
                            <li>
                                The skirmish-commissioner bot
                            </li>
                            <li>
                                3 to 20 players
                            </li>
                        </ul>
                        <p>
                            If you are installing Skirmish Society on your
                            Discord server for the first time, follow the{" "}
                            <a href="/setup-guide">simple setup guide</a>.
                        </p>
                        <p>
                            Discord is very bot-friendly and this process does not require any technical know-how.
                        </p>
                    </div>
                </div>

                <h2 id="Winning">Winning</h2>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            The first player to gain enough Victory Points (VP)
                            is the winner. The number of VP needed is set by the
                            admin on your server at the start of the game.
                        </p>

                        <p>Victory Points can be earned in two ways:</p>

                        <ul>
                            <li>
                                <b>Expansion</b>: As long as they control at
                                least 5 territories, the player with the most
                                territories at the end of a turn will earn one
                                VP. (If multiple players are tied for most
                                territories they each earn 1 VP)
                            </li>
                            <li>
                                <b>Farming</b>: Armies on a farm territory can
                                spend their turn farming, earning one VP.
                            </li>
                        </ul>

                        <p>
                            Only one player can win. If two or more players
                            cross the VP threshold on the same turn the one with
                            more VP will win. If they are tied the game will
                            continue until only one player is in the lead.
                        </p>
                    </div>
                    <div className="example">
                        <p className="exmaple_label">Player Standings</p>
                        <img
                            alt="player sidebar example"
                            src="pic/rules/player_side_bar.jpg"
                        />
                        <p className="good_order">
                            By default the side bar sorts players based on how
                            much VP they have and then how many territories they
                            control.
                            <br />
                            <br />
                            Orders are shown under the players names.
                        </p>
                    </div>
                </div>

                <h2 id="Combat Basics">Combat Basics</h2>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            Each turn, you can tell your armies to Hold, Move,
                            Support, Build, or Farm. These orders are{" "}
                            <a href="#Orders"> covered in more detail</a> below,
                            but the basics are this:
                        </p>

                        <ul>
                            <li>A holding army will defend its position.</li>
                            <li>
                                A moving army will attempt move to an adjacent
                                (possibly occupied) territory.
                            </li>
                            <li>
                                A supporting army stays put and lends its
                                strength to an adjacent move or hold order.
                            </li>
                            <li>A building army builds a farm.</li>
                            <li>A farming army farms a farm.</li>
                        </ul>

                        <p>
                            Combat happens when an army moves to an occupied
                            territory or when two or more armies try to move to
                            the same empty territory. When this occurs, the army
                            with the most support will win. In a tie, a holding
                            army wins.
                        </p>

                        <p>
                            You add strength to a move by supporting it,{" "}
                            <i>
                                not by having a second army move into the same
                                territory
                            </i>
                            .
                        </p>

                        <p>
                            You can get new armies by capturing barracks
                            territories. If you have more barracks than current
                            armies, you can{" "}
                            <a href="#Reinforcement">
                                deploy new armies
                            </a>
                            .
                        </p>

                        <p>Any time you'd like a quick summary of the available orders and commands, DM the bot the <BotCommand>/cheat_sheet</BotCommand> command. You can use that as a fast reference to go along with this more complete rule book.</p>
                    </div>

                    <div className="example">
                        <p className="exmaple_label">Combat</p>

                        <img
                            alt="Support example"
                            src="pic/rules/support1.jpg"
                        />

                        <p className="good_order" style={{ width: "350px" }}>
                            The Blue army in RG moved into SI with support from
                            the army in OG. The Red army in SI had no support
                            and was defeated. SI now belongs to the Blue player.
                        </p>
                    </div>
                </div>

                <h2 id="The Sandbox">The Sandbox</h2>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            <b>
                                You can test out these rules in the{" "}
                                <Link to={`/sandbox${params}`}>sandbox</Link>
                            </b>
                            . This is a simulation of the game where you control
                            multiple players and can reset at any time. It is
                            recommend that you mess around there a bit to get
                            the hang of the game. Submitting bad orders in the
                            real game can set you back a lot.
                        </p>

                        <p>
                            If you're not sure how a specific scenario will play
                            out, try testing it in the sandbox before committing
                            your armies to it in the real game.
                        </p>
                    </div>
                </div>

                <h2 id="units-and-territories">Units and Territories</h2>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            The only unit in this game is the army. Armies are
                            how the player interacts with the board. Each turn,
                            a player can send an order to each army they have.
                            In combat, these orders will either tell the army to
                            stay put, move to an adjacent territory, or support
                            a nearby army. Armies can also be ordered to build a
                            farm or farm for VP.
                        </p>

                        <p>
                            Territories are the countries on the board. Some
                            territories are barracks. These are listed in bold
                            and have a{" "}
                            <img
                                alt="supply point icon"
                                src="pic/icons/supplyPointIdle_trim.png"
                            />{" "}
                            symbol. The number of barracks a player controls
                            determines how many armies they can have. You can
                            read more about this in{" "}
                            <a href="#Reinforcement">Reinforcement Orders</a>.
                        </p>

                        <p>
                            Barracks territories can become farms, marked with a{" "}
                            <img alt="farm icon" src="pic/icons/farm.png" />.
                            The map does not start with any farms. Farms can be
                            used to gain VP.
                        </p>

                        <p>
                            Some territories on the border of the map have a
                            dotted line connecting them to a territory on the
                            other side of the map. This is an oversea
                            connection. These two territories are considered
                            adjacent and moving between them is no different
                            than moving between two adjacent landlocked
                            territories. Outside of these overseas connections,
                            the ocean is completely impassible.
                        </p>

                        <br />
                        <img alt="demo map" src="pic/rules/demo_map.jpg" />
                    </div>
                </div>

                <h2 id="Bot">Communicating with the Bot</h2>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            All commands in this game happen through slash commands sent to the
                            @skirmish-commissioner bot on your Discord server.
                            After you join the game, you can use those commands
                            in #skirmish-society or in a private DM with the bot.
                        </p>

                        <p>
                            Slash commands are widely used by Discord bots. They're just messages that start with "/". Discord will attempt to autocomplete any recognized slash command once you start typing it. They will even pop up with a little description of what the command does.
                        </p>

                        <p>Here I am using <BotCommand>/join</BotCommand> to join the game:</p>
                        <p>
                            <img
                                alt="discord screencap"
                                src="pic/rules/join.gif"
                                width="600"
                            />
                        </p>

                        <p>
                            Try using <BotCommand>/help</BotCommand> in your #skirmish-society channel to try it out!
                        </p>

                        {/* <p>
                            <img
                                alt="discord screencap"
                                src="pic/rules/discord.jpg"
                                width="600"
                            />
                        </p> */}

                        {/* <p>To join the game, post</p>
                        <p className="sample_orders">
                            @skirmish-commissioner join
                        </p>

                        <p>
                            {" "}
                            in #skirmish-society (You must join in the public
                            channel. After that you can DM the bot)
                        </p> */}

                        <p>
                            Remember, the skirmish-commissioner bot isn't the
                            only part of this game on Discord. All of your
                            fellow players are there too! Talk to them and try
                            to get them on your side.
                        </p>

                        <p>
                            One communication tool at your disposal is the Broadcast command.
                            This will allow you to have the bot post an
                            anonymous message on your behalf. Broadcast is
                            covered in the{" "}
                            <a href="#Other Commands">Other Commands</a>{" "}
                            section.
                        </p>
                    </div>
                </div>

                <h2 id="Starting A Game">Starting A Game</h2>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            If you are brand new and want to run a game of
                            Skirmish Society on your Discord Server, follow this{" "}
                            <a href="/setup-guide">simple setup guide</a> to
                            install the bot.
                        </p>

                        <p>
                            Once the bot has been installed on your server,
                            a mod or a user
                            with the "skirmish society admin" roles can use the
                        </p>
                        <p className="sample_orders">
                            /setup
                        </p>
                        <p>
                            command to setup the game. The game will be in join
                            mode and players on your server can join by using
                        </p>
                        <p className="sample_orders">
                            /join
                        </p>
                        <p>in the #skirmish-society channel.</p>
                        {/* <p>
                            <img
                                alt="joinging on discord"
                                src="pic/rules/discord_join.png"
                                width="600"
                            />
                        </p> */}
                        <p>
                            It is recomended that you have at least 5 players,
                            but the game can support up to 20! The more players
                            the better.
                        </p>
                        <p>When everybody is in, the admin can use</p>
                        <p className="sample_orders">
                            /start
                        </p>
                        <p>to start the game.</p>
                        <p>
                            <b>
                                Once the game starts, no new players will be
                                able to join.
                            </b>
                        </p>
                    </div>
                </div>

                <h2 id="Submitting Orders">Submitting Orders</h2>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            During each turn period, you can communicate with
                            other players and submit orders using the <BotCommand>/submit</BotCommand> command.
                            Orders can be sent publicly in the
                            #skirmish-society channel or secretly via DM with
                            @skirmish-commissioner. Either way, use <BotCommand>/submit</BotCommand> followed by your order(s). If you are submitting more than one order, separate them with a comma.
                        </p>

                        <p>
                            On mobile, you may need to tap "orders" when it autocompletes the <BotCommand>/submit</BotCommand> command.
                        </p>

                        <p>
                            Submitting three orders could look like this:
                        </p>

                        <p className="sample_orders">
                            /submit BL S CR to SL
                            <br />
                            /submit TR HL<br />
                            /submit WB to FL
                        </p>
                        <p>or like this:</p>
                        <p className="sample_orders">
                            /submit BL S CR to SL, TR H, WB to FL
                        </p>

                        <p>
                            You can submit your orders for the turn all at once,
                            or send orders throughout the turn period.
                        </p>

                        <p>
                            You can also replace an order if you realized that
                            you typed something wrong or you changed your mind.
                            If multiple orders are received for the same army,
                            the most recent one will be used and earlier ones
                            will be thrown out. It's fine to send some initial orders knowing you might change them later.
                        </p>

                        <p>
                            <b>
                                Newer orders will always replace older orders
                                for the same army
                            </b>
                            . No greater importance is given to orders submitted
                            publicly or privately. When the game is resolving
                            orders, the most recent valid order for a given
                            army will be used.
                        </p>

                        <p>
                            <b>
                                About 5 minutes before the turn resolves, orders
                                will stop being accepted.
                            </b>{" "}
                            Do not wait until the last second to submit your
                            orders! You will also not be able to submit for
                            about 5 minutes after the turn resolves.
                        </p>

                        <p>
                            To make it easier to read, this guide will mostly omit "/submit" when writing out orders. Just assume that if we're talking about submitting orders, the <BotCommand>/submit</BotCommand> command has been used.
                        </p>

                        {/* <p>
                            (This means you should not automatically trust
                            somebody you made a deal with who posts their orders
                            publicly. For all you know, they might DM
                            @skirmish-commissioner with their real orders a
                            minute later.)
                        </p> */}
                    </div>
                </div>

                <h2 id="Orders">Orders</h2>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            During each turn period, you can submit an order to
                            each army you control. Orders always start with the
                            abbreviation of the territory where that army is
                            now. For instance, to tell my army in Betts Frond to
                            hold and my army in Jilch Nar to move to Aevora, I
                            could submit
                        </p>

                        <p className="sample_orders">
                            BF H<br />
                            JN to AE
                        </p>

                        <p>
                            "BF" is the abbreviation for Betts Frond, and "H" is
                            the abbreviation for Hold. "JN" and "AE" are the
                            abbreviations for Jilch Nar and Aevora respectively,
                            and "to" is the command for a move.
                        </p>

                        <p>
                            Orders are not case sensitive. You can use upper or
                            lower case or a mix.
                        </p>

                        <p>There are three types of combat orders:</p>
                        <ul>
                            <li>Hold - army stays put and defends</li>
                            <li>
                                Move - army moves to an adjacent territory
                                (moving into an occupied territory is an attack)
                            </li>
                            <li>
                                Support - army supports an adjacent army in a
                                move or hold order, increasing the strength of
                                that army by 1
                            </li>
                        </ul>

                        <p>There are two types of farming orders:</p>
                        <ul>
                            <li>Build - army turns a barracks into a farm</li>
                            <li>
                                Farm - army works a farm territory, gaining 1 VP
                            </li>
                        </ul>

                        <p>
                            Once orders are resolved for the turn, they are
                            written in a way to show if they were successful or
                            not. This instruction document will do the same. If
                            an order is successful, it is printed normally. If
                            it failed, it is printed with an{" "}
                            <span className="bad_order">
                                underline and tinted red
                            </span>
                            . If an order was invalid, it is printed in{" "}
                            <span className="invalid_order">
                                grey with a strike through it
                            </span>
                            .
                        </p>

                        <p>
                            In Orders Example (the image to the right), Red
                            player submitted two orders.{" "}
                            <span className="good_order">JN to AE </span>
                            succeeded. The army moved from Jilch Nar to Aevora.{" "}
                            <span className="bad_order">BF to EH </span>
                            failed. The order was valid, but the blue army in
                            Erenhaz blocked the move, causing it to fail.
                        </p>
                        <p>
                            Blue player also submitted two orders.{" "}
                            <span className="good_order">EH H</span> succeeded,
                            The army in Erenhaz successfully held their ground.{" "}
                            <span className="invalid_order">VW to JN</span> was
                            invalid because Vinwesh and Jilch Nar are not
                            adjacent. This order was ignored and the army in
                            Vinwesh defaulted to a hold order (which succeeded).
                        </p>

                        <p>
                            All orders for the most recently resolved turn are
                            shown to the right of the map on the game's main
                            page.
                        </p>

                        <p>
                            If you miss a turn period, your armies will
                            automatically receive hold orders, so you will not
                            be completely vulnerable.
                        </p>

                        <p>
                            Any time you want a quick refresher on the available orders, you can DM the bot the <BotCommand>/cheat_sheet</BotCommand> command for a quick list with examples.
                        </p>
                    </div>

                    <div className="example">
                        <p className="exmaple_label">Orders Example</p>

                        <img alt="sample orders" src="pic/rules/orders1.jpg" />
                        <p className="player_name example_red_player">red</p>
                        <p className="bad_order">BF to EH</p>
                        <p className="good_order">JN to AE</p>
                        <p className="player_name example_blue_player">blue</p>
                        <p className="good_order">EH H</p>
                        <p className="invalid_order">VW to JN</p>
                        <p className="good_order">VW H</p>
                    </div>
                </div>

                <h3 id="Hold Orders">Hold Orders</h3>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            A hold order tells that army to stay put and defend
                            the territory it is in. With no support, it will
                            defend with a strength of 1.
                        </p>

                        <p>
                            A hold order is the abbreviation of the territory
                            you are ordering to hold and the letter "H" or
                            "hold". Hold orders need no further information
                            beyond the territory doing the hold.
                        </p>

                        <p className="sample_orders">
                            SI H<br />
                            SI hold
                        </p>

                        <p>
                            In a conflict, the defender has the advantage. So an
                            army holding without any support will be able to
                            repel an attack from from an unsupported enemy army.
                            In Hold Example, the army in Simm is holding and
                            repels the attack from Obgob.
                        </p>

                        <p>
                            If no order is submitted for an army in a turn
                            period, they will automatically receive a hold
                            order. This means you don't actually have to submit
                            hold orders for your armies, but you can if you like
                            to write out all of your orders for the turn.
                        </p>
                    </div>

                    <div className="example">
                        <p className="exmaple_label">Hold Example</p>

                        <img
                            alt="sample hold order"
                            src="pic/rules/hold1.jpg"
                        />
                        <p className="player_name example_red_player">red</p>
                        <p className="good_order">SI H</p>
                        <p className="player_name example_blue_player">blue</p>
                        <p className="bad_order">OG to SI</p>
                    </div>
                </div>

                <h3 id="Move Orders">Move Orders</h3>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            A move order tells an army to leave its current
                            territory and move to an adjacent one. Moving to an
                            occupied territory is how you attack other players.
                            A move order starts with the territory of the army
                            making the move, then the word "to", followed by the
                            target territory.
                        </p>
                        <p>
                            Because this is such a common order, there are a few
                            other ways you can write it. If you are more
                            familiar with games like Diplomacy, you can also use
                            the "->" symbol (a dash and a less-than symbol to
                            form an arrow) in place of the word "to" or just use
                            ">". The following are all valid ways of writing the
                            same order:
                        </p>

                        <p className="sample_orders">
                            EH to AE
                            <br />
                            EH -> AE
                            <br />
                            EH > AE
                        </p>

                        <p>
                            If the order is successful (the territory being
                            moved into was uncontested, or the attacking army
                            had more strength than the holding army in that
                            territory), the army moves there and the owner of
                            that army now controls that territory. This can be
                            seen in Move Example 1.
                        </p>

                        <p>
                            You can try out submitting move orders in the{" "}
                            <Link to={`/sandbox${params}`} target="_blank">
                                sandbox
                            </Link>
                            .
                        </p>
                    </div>

                    <div className="example">
                        <p className="exmaple_label">Move Example 1</p>

                        <img
                            alt="sample move order"
                            src="pic/rules/move1.jpg"
                        />
                        <p className="player_name example_blue_player">blue</p>
                        <p className="good_order">EH to AE</p>
                    </div>
                </div>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            An attacking army without support has a strength of
                            1. A move order fails if a defending army has as
                            much or more strength than the attacking army, or if
                            two armies of equal strength attempt to move into
                            the same territory. When a move order fails, the
                            army stays where it was and is considered to be
                            holding.
                        </p>

                        <p>
                            Failed move orders are shown on the map as a faded,
                            transparent arrow.
                        </p>

                        <p>
                            In Move Example 2, Red and Blue both attempt to move
                            an army into Regelix. The two armies bounce and
                            remain where they started. This is called a
                            standoff.
                        </p>

                        <p>
                            A move order will also fail if the army would kill
                            another army that belongs to the same player.
                        </p>
                    </div>

                    <div className="example">
                        <p className="exmaple_label">Move Example 2</p>

                        <img
                            alt="sample move order"
                            src="pic/rules/move2.jpg"
                        />
                        <p className="player_name example_red_player">red</p>
                        <p className="bad_order">FI to RG</p>
                        <p className="player_name example_blue_player">blue</p>
                        <p className="bad_order">AE to RG</p>
                    </div>
                </div>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            A string of armies can each move into a territory
                            previously controlled by the others, as long as all
                            armies are in a valid place after resolution.
                        </p>

                        <p>
                            The only exception is that{" "}
                            <b>two armies cannot trade positions</b>. However,
                            three armies can "rotate."
                        </p>

                        <p>
                            In Move Example 3, several armies move into territories
                            that were occupied, but because each army moved into
                            a new one, there were no conflicts.
                        </p>
                    </div>

                    <div className="example">
                        <p className="exmaple_label">Move Example 3</p>

                        <img alt="sample move" src="pic/rules/move3.jpg" />
                        <p className="player_name example_red_player">red</p>
                        <p className="good_order">FI to SI</p>
                        <p className="good_order">RG to FI</p>
                        <p className="player_name example_blue_player">blue</p>
                        <p className="good_order">BF to RG</p>
                        <p className="good_order">OG to BF</p>
                    </div>
                </div>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            However, if any of those move orders fail, it may
                            cause a chain of other failed moves as each army
                            "bonks" into the one in front.
                        </p>

                        <p>
                            In Move Example 4, the orders are very similiar, but
                            because the army in Filp holds, it causes all of the
                            other move orders in the chain to fail.
                        </p>
                    </div>

                    <div className="example">
                        <p className="exmaple_label">Move Example 4</p>

                        <img
                            alt="sample move order 6"
                            src="pic/rules/move4.jpg"
                        />
                        <p className="player_name example_red_player">red</p>
                        <p className="good_order">FI H</p>
                        <p className="bad_order">RG to FI</p>
                        <p className="player_name example_blue_player">blue</p>
                        <p className="bad_order">BF to RG</p>
                        <p className="bad_order">OG to BF</p>
                    </div>
                </div>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            <b>
                                Moving two of your armies into a territory will
                                not increase the strength of a move!
                            </b>
                        </p>

                        <p>
                            All this will do is create a scenario where your two
                            armies are in a standoff and stay where they are. You must
                            use support orders to increase the strength of a
                            move.{" "}
                            <b>
                                The same is true for strengthening a hold order
                            </b>
                            . Don't move another army into the territory; use a{" "}
                            <a href="#Support Orders">support order</a>.
                        </p>

                        <p>
                            In Double Move Example, the Red player tries to move
                            into RG with two armies. Instead of taking it
                            easily, the two armies bounce off each other as if
                            they were on opposing teams.
                        </p>

                        <p>
                            There may be unusual circumstances where you want to
                            issue two move orders into the same territory, but
                            this is rare.
                        </p>
                    </div>

                    <div className="example">
                        <p className="exmaple_label">Double Move Example</p>

                        <img
                            alt="sample move order 7"
                            src="pic/rules/double_move.jpg"
                        />
                        <p className="player_name example_red_player">red</p>
                        <p className="bad_order">FI to RG</p>
                        <p className="bad_order">SI to RG</p>
                    </div>
                </div>

                <h3 id="Overseas Connections">Overseas Connections</h3>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            Some territories on the border of the map are
                            connected via a dotted line over the ocean. These
                            territories are considered adjacent.
                        </p>

                        <p>
                            This means armies can move between them, and that
                            Support (see next section) can be given between
                            them.
                        </p>
                    </div>

                    <div className="example">
                        <p className="exmaple_label">Overseas Example</p>

                        <img
                            alt="sampe overseas connection order"
                            src="pic/rules/overseas.jpg"
                        />
                        <p className="player_name example_red_player">red</p>
                        <p className="good_order">VJ to RW</p>
                    </div>
                </div>

                <h3 id="Support Orders">Support Orders</h3>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            A support order pledges that army's strength to a
                            Hold or Move order that ends in an adjacent
                            territory. Support orders allow you to work with
                            yourself or others to acomplish your goals. A
                            supported Move order is the only way to kill an
                            opponent's army. You can give and receive support
                            from other players.
                        </p>

                        <p>
                            While reading this section, you might want to try
                            submitting some support orders in the{" "}
                            <Link to={`/sandbox${params}`} target="_blank">
                                sandbox
                            </Link>
                            .
                        </p>

                        <p>
                            Support orders must start with the abbreviation of
                            the territory your army is in, and then list the
                            full order that is being supported. (For instance,
                            you cannot just order the army in Obgob to support a
                            move from Regelix, but must specify that Obgob is
                            supporting a move from Regelix to Simm.)
                        </p>

                        <p className="sample_orders">
                            RG to SI
                            <br />
                            OG S RG to SI
                        </p>

                        <p>
                            Assuming the support order succeeds, an order
                            receiving support will have its strength increased
                            by 1. In Support Example 1, Blue player defeats the
                            Red army in Simm because support from Obgob
                            increased the strength of the move order to 2.
                        </p>

                        <p>
                            Support orders are shown on the map as hash lines.
                        </p>
                    </div>

                    <div className="example">
                        <p className="exmaple_label">Support Example 1</p>

                        <img
                            alt="example support order"
                            src="pic/rules/support1.jpg"
                        />
                        <p className="player_name example_red_player">red</p>
                        <p className="bad_order">SI H</p>
                        <p className="good_order">FI H</p>
                        <p className="player_name example_blue_player">blue</p>
                        <p className="good_order">RG to SI</p>
                        <p className="good_order">OG S RG to SI</p>
                    </div>
                </div>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            Hold orders can also receive support. The total
                            strength of an attacking army must beat the strength
                            of the holding army. Ties go to the defender. In
                            Support Example 2, we see the same attack from Blue,
                            but this time Red player has the army in Filp
                            support the hold order in Simm. The army in Simm now
                            has a strength of 2, matching the strength of the
                            Red attack from Regelix.
                        </p>

                        <p className="sample_orders">
                            SI H<br />
                            FI S SI H
                        </p>

                        <p>
                            When supporting a hold order, you only need to list
                            the territory that is holding (You can leave off the
                            'H').
                        </p>

                        <p className="sample_orders">FI S SI</p>

                        <p>
                            The above order will be understood as Filp
                            supporting a hold order in Simm.
                        </p>

                        <p>
                            Orders can receive support from any number of
                            adjacent units.
                        </p>

                        <p>
                            Note: a support order can succeed and the order it
                            is supporting can still fail. Likewise, a support
                            order can fail, but the order it was supporting
                            could still succeed. In Support Example 2, The OG S
                            RG to SI order was sucessful (support was given)
                            even though the RG to SI order failed.
                        </p>
                    </div>

                    <div className="example">
                        <p className="exmaple_label">Support Example 2</p>

                        <img
                            alt="example support order 2"
                            src="pic/rules/support2.jpg"
                        />
                        <p className="player_name example_red_player">red</p>
                        <p className="good_order">SI H</p>
                        <p className="good_order">FI S SI</p>
                        <p className="player_name example_blue_player">blue</p>
                        <p className="bad_order">RG to SI</p>
                        <p className="good_order">OG S RG to SI</p>
                    </div>
                </div>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            When supporting a move order, the army giving the
                            support must be in a territory adjacent to the
                            location being moved to. It does not need to be
                            adjacent to the source.
                        </p>

                        <p>
                            In Support Example 3, Sogrash is able to support the
                            army in Obgob moving to Betts Frond, because Sogrash
                            is adjacent to Betts Frond. Sogrash would not be
                            able to support a move from Betts Frond to Obgob.
                        </p>
                    </div>

                    <div className="example">
                        <p className="exmaple_label">Support Example 3</p>

                        <img
                            alt="example support order 3"
                            src="pic/rules/support3.jpg"
                        />
                        <p className="player_name example_blue_player">red</p>
                        <p className="good_order">OG to BF</p>
                        <p className="good_order">SG S OG to BF</p>
                    </div>
                </div>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            You can support orders from your own armies as well
                            as other players. Support cannot be refused, even if
                            it is unwanted. When offering support to another
                            player's move order, you'll need to know exactly
                            what they intend to write and match it with your
                            support order.
                        </p>

                        <p>
                            In Support Example 4, Blue fears an attack from Red,
                            and has convinced Green to help them out by having
                            the green army in Aevora support the holding army in
                            Regelix. Maybe they convinced Green that Red is the
                            real threat and that if Red takes Regelix, Green's
                            territory will be next.
                        </p>

                        <p>
                            Note: Although support cannot be refused, a player's
                            army cannot kill another army controlled by the same
                            player no matter how much support it has.
                        </p>
                    </div>

                    <div className="example">
                        <p className="exmaple_label">Support Example 4</p>

                        <img
                            alt="example support 4"
                            src="pic/rules/support4.jpg"
                        />
                        <p className="player_name example_red_player">red</p>
                        <p className="good_order">MA S FI to RG</p>
                        <p className="bad_order">FI to RG</p>
                        <p className="player_name example_blue_player">blue</p>
                        <p className="good_order">RG H</p>
                        <p className="player_name example_green_player">
                            green
                        </p>
                        <p className="good_order">AE S RG</p>
                    </div>
                </div>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            In a standoff (where multiple armies try to move
                            into the same territory), the army with the most
                            support will win and the other armies will bounce,
                            remaining where they started.
                        </p>

                        <p>
                            In Support Example 5, Red and Blue both try to move
                            an army to Regelix. Blue is unsupported and has a
                            strength of 1. Red has support from Margalb and has
                            a strength of 2, winning the standoff.
                        </p>
                    </div>

                    <div className="example">
                        <p className="exmaple_label">Support Example 5</p>

                        <img
                            alt="example support order 12"
                            src="pic/rules/support5.jpg"
                        />
                        <p className="player_name example_red_player">red</p>
                        <p className="good_order">FI to RG</p>
                        <p className="good_order">MA S FI to RG</p>
                        <p className="player_name example_blue_player">blue</p>
                        <p className="bad_order">AE to RG</p>
                    </div>
                </div>

                <h3 id="Cutting Support">Cutting Support</h3>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            Support can be cut by attacking the territory of the
                            army providing support. Even if the attack is not
                            successful, the supporting army will have to defend
                            itself and will hold instead of supporting.
                        </p>

                        <p>
                            In Cut Support Example 1, the Red army in Margalb
                            tries to take Aevora with support from Regelix, but
                            the Blue army in Filp cuts support. Even though the
                            move order from Filp to Regelix was unsuccessful, it
                            was still enough to cut support, forcing the army in
                            Regelix to hold.
                        </p>

                        <p>
                            On the map, cut support is shown as a faded grey
                            hash line.
                        </p>
                    </div>

                    <div className="example">
                        <p className="exmaple_label">Cut Support Example 1</p>

                        <img
                            alt="cut support order example"
                            src="pic/rules/cut_support1.jpg"
                        />
                        <p className="player_name example_red_player">red</p>
                        <p className="bad_order">MA to AE</p>
                        <p className="bad_order">RG S MA to AE</p>
                        <p className="player_name example_blue_player">blue</p>
                        <p className="bad_order">FI to RG</p>
                        <p className="good_order">AE H</p>
                    </div>
                </div>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            The only exception to this is that support will not
                            be cut if an army supporting an attack on another
                            territory is attacked from that territory. (This
                            would effectively allow the defending army to
                            single-handedly repel an attack with 2 strength.)
                        </p>

                        <p>
                            In Cut Support Example 2, the Red army in Margalb
                            attacks Aevora with support from Regelix. The Blue
                            army in Aevora attempts to move to Regelix to cut
                            support, but it fails because Regelix is supporting
                            an attack into Aevora.
                        </p>
                    </div>

                    <div className="example">
                        <p className="exmaple_label">Cut Support Example 2</p>

                        <img
                            alt="cut support order example"
                            src="pic/rules/cut_support2.jpg"
                        />
                        <p className="player_name example_red_player">red</p>
                        <p className="good_order">MA to AE</p>
                        <p className="good_order">RG S MA to AE</p>
                        <p className="player_name example_blue_player">blue</p>
                        <p className="bad_order">AE to RG</p>
                    </div>
                </div>

                <div className="rules_section">
                    <div className="rules_text" id="cut_support_exmaple">
                        <p>
                            You cannot support a support order. Sending
                            support to a territory performing their own support
                            order will be treated as a support hold order. If
                            that army is attacked the support will be cut no
                            matter what, but you can still send support to
                            prevent that army from being killed.
                        </p>

                        <p>
                            In Cut Support Example 3, Red player's army in
                            Aevora tries to support Green's move. Blue Player
                            attacks Aevora with support. Normally this would cut
                            support and kill the Red army there. However, Red
                            used their army in Jilch Nar to support Aevora.
                        </p>
                    </div>

                    <div className="example">
                        <p className="exmaple_label">Cut Support Example 3</p>

                        <img
                            alt="supporting a cut support order"
                            src="pic/rules/cut_support3.jpg"
                        />
                        <p className="player_name example_red_player">blue</p>
                        <p className="bad_order">AE S KH to MA</p>
                        <p className="good_order">JN S AE</p>
                        <p className="player_name example_blue_player">red</p>
                        <p className="bad_order">EH to AE</p>
                        <p className="good_order">BF S EH to AE</p>
                        <p className="player_name example_green_player">
                            green
                        </p>
                        <p className="good_order">KH to MA</p>
                    </div>
                </div>

                <h3 id="Build Orders">Build Orders</h3>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            A build order tells an army to transform the
                            barracks territory they are in into a Farm. Farms
                            can be used to generate VP.{" "}
                            <b>
                                A build order will permanently take away the
                                barracks status of the territory
                            </b>
                            .
                        </p>

                        <p>
                            Farms are identified by the{" "}
                            <img alt="farm icon" src="pic/icons/farm.png" />{" "}
                            icon and by a more ordered version of the player's
                            pattern.
                        </p>

                        <p>
                            A build order is written as the barracks territory
                            where the army is followed by "B" or "Build"
                        </p>

                        <p className="sample_orders">
                            EH B
                            <br />
                            EH Build
                        </p>

                        <p>
                            A build order can only be issued to an army in a
                            barracks territory. If it succeeds the territory
                            will no longer be a barracks. It will be a farm
                            instead.
                        </p>

                        <p>
                            A build order should be carefully considered as
                            losing a barracks sets back your ability create new armies.
                            Furthermore, Farms are not
                            player-specific and can be captured by another
                            player.
                        </p>

                        <p>
                            In Build Example 1, Blue player ordered their army
                            to turn Erenhaz into a farm. It is no longer a
                            barracks and will no longer be counted when
                            determining their{" "}
                            <a href="#Reinforcement">max army size</a>.
                        </p>

                        <p>
                            You can try out submitting build orders in the{" "}
                            <Link to={`/sandbox${params}`} target="_blank">
                                sandbox
                            </Link>
                            .
                        </p>
                    </div>

                    <div className="example">
                        <p className="exmaple_label">Build Example 1</p>

                        <img alt="build order" src="pic/rules/build1.jpg" />
                        <p className="player_name example_red_player">blue</p>
                        <p className="good_order">AE H</p>
                        <p className="player_name example_blue_player">blue</p>
                        <p className="good_order">EH B</p>
                    </div>
                </div>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            Much like support, build orders can be cut. Any army
                            moving into the territory of the building army will
                            force it to hold instead of building (even if the
                            attack fails).
                        </p>

                        <p>
                            Build orders cannot receive support. Any support
                            sent to a farming army will be hold support in the
                            event that they are attacked. This behaves
                            identically to{" "}
                            <a href="#cut_support_exmaple">
                                Cut Support Example 3
                            </a>
                            .
                        </p>
                    </div>

                    <div className="example">
                        <p className="exmaple_label">Build Example 2</p>

                        <img
                            alt="moving an army after a build order"
                            src="pic/rules/build2.jpg"
                        />
                        <p className="player_name example_red_player">red</p>
                        <p className="bad_order">AE to EH</p>
                        <p className="player_name example_blue_player">blue</p>
                        <p className="bad_order">EH B</p>
                    </div>
                </div>

                <h3 id="Farm Orders">Farm Orders</h3>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            A farm order tells the army receiving it to work the
                            farm and gain one VP. An army receiving a farm order
                            must be in a farm territory. A successful farm order
                            is marked by a "+1VP" under the army.
                        </p>
                        <p>
                            A farm order is written as the farm territory where
                            the army is followed by "F" or "Farm"
                        </p>

                        <p className="sample_orders">
                            EH F
                            <br />
                            EH Farm
                        </p>

                        <p>
                            Before you can send a farm order you will have to
                            use a build order to create a farm or capture a farm
                            from another player.
                        </p>

                        <p>
                            In Farm Example 1, Blue earns 1 VP. This is in
                            addition to any other VP they may have earned this
                            turn (for example, from having the most
                            territories).
                        </p>

                        <p>
                            If you control more than one farm territory, you can
                            issue more than one farm order in a turn. You will
                            generate 1 VP for each successful farm order.
                        </p>
                    </div>

                    <div className="example">
                        <p className="exmaple_label">Farm Example 1</p>

                        <img alt="farm order" src="pic/rules/farm1.jpg" />
                        <p className="player_name example_red_player">red</p>
                        <p className="good_order">AE H</p>
                        <p className="player_name example_blue_player">red</p>
                        <p className="good_order">EH F</p>
                    </div>
                </div>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            Much like support and build orders, farm orders can
                            be cut. Any army moving into the territory of the
                            farming army will force it to hold instead of
                            farming (even if the attack fails).
                        </p>

                        <p>
                            Farm orders cannot receive support. Any support sent
                            to a farming army will be hold support in the event
                            that they are attacked. This behaves identically to{" "}
                            <a href="#cut_support_exmaple">
                                Cut Support Example 3
                            </a>
                            .
                        </p>

                        <p>
                            In Farm Example 2, Blue player tries to farm
                            Erenhaz, but their army is forced to hold instead
                            because of a move from Aevora. Even though the move
                            was unsuccessful, the farm order was still cut and
                            no VP is generated.
                        </p>

                        <p>
                            You can try out submitting farm orders in the{" "}
                            <Link to={`/sandbox${params}`} target="_blank">
                                sandbox
                            </Link>
                            .
                        </p>
                    </div>
                    <div className="example">
                        <p className="exmaple_label">Farm Example 2</p>

                        <img alt="cut farm order" src="pic/rules/farm2.jpg" />
                        <p className="player_name example_red_player">red</p>
                        <p className="bad_order">AE to EH</p>
                        <p className="player_name example_blue_player">blue</p>
                        <p className="bad_order">EH F</p>
                    </div>
                </div>

                <h2 id="Losing and Gaining Armies">
                    Losing and Gaining Armies
                </h2>

                <h3>Death</h3>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            If a hold order fails (including a different order
                            that was converted to a hold order because it was
                            interrupted), that army is killed. The victorious army
                            is shown standing over the defeated army.
                        </p>

                        <p>
                            Dead armies are immediately removed from the board.
                            The losing army icon is shown to make it easy to see
                            that there was combat, but this unit is no longer on
                            the board and cannot receive orders. (For those
                            familiar with Diplomacy, there is no retreat phase
                            in this game.)
                        </p>

                        <p>
                            In Dead Army Example, the Red army in Simm was
                            killed by the Blue army moving from Regelix. The
                            victorious Blue army is shown over the defeated Red
                            army. Simm now belongs to Blue player.
                        </p>
                    </div>

                    <div className="example">
                        <p className="exmaple_label">Dead Army Example</p>

                        <img
                            alt="support order example"
                            src="pic/rules/support1.jpg"
                        />
                        <p className="player_name example_red_player">red</p>
                        <p className="bad_order">SI H</p>
                        <p className="good_order">FI H</p>
                        <p className="player_name example_blue_player">blue</p>
                        <p className="good_order">OG S RG to SI</p>
                        <p className="good_order">RG to SI</p>
                    </div>
                </div>

                <h3 id="Reinforcement">Reinforcement Orders</h3>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            You can add armies to the map if you have fewer
                            armies than barracks. To do this, you submit a move
                            order from the Reinforcements (abbreviated as "REF")
                            to a barracks you control. Armies can only be added
                            to barracks territories (marked with a{" "}
                            <img
                                alt="supply point icon"
                                src="pic/icons/supplyPointIdle_trim.png"
                            />{" "}
                            icon).
                        </p>

                        <p>
                            The Reinforcements exist outside of the game.
                            Armies can be moved from this zone to the game
                            board. A reinforcement order is a move order that
                            starts in "REF".
                        </p>

                        <p className="sample_orders">REF to MA</p>

                        <p>If you'd like you can also leave out the "to".</p>

                        <p className="sample_orders">REF MA</p>

                        <p>
                            The above will also be recognized as a reinforment
                            order to MA.
                        </p>

                        <p>
                            In Reinforcement Example 1, Red player controls two
                            barracks (Margalb & Aevora), but has only one
                            army, so they can submit a reinforcement order if
                            they choose to.
                        </p>

                        <p>
                            You can try out submitting reinforcement orders in
                            the{" "}
                            <Link to={`/sandbox${params}`} target="_blank">
                                sandbox
                            </Link>
                            .
                        </p>
                    </div>

                    <div className="example">
                        <p className="exmaple_label">Reinforcement Example 1</p>

                        <img
                            alt="reinforcement order example"
                            src="pic/rules/supply1.jpg"
                        />
                    </div>
                </div>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            Sucessful reinforcement orders are shown as a
                            saluting army icon.
                        </p>

                        <p>
                            A player can only submit one move from REF each
                            turn. For instance, if a player has 2 armies but 4
                            barracks and wants to deploy two new armies, they
                            would have to do it over two turns. They could also
                            choose to do nothing; players are not required to
                            submit reinforcement orders.
                        </p>

                        <p>
                            A reinforcement order must start in REF and end in a
                            barracks controlled by that player. In Reinforcement
                            Example 1, Red could not have moved the
                            reinforcement army into Regelix because Regelix is
                            not a barracks.
                        </p>
                    </div>

                    <div className="example">
                        <p className="exmaple_label">Reinforcement Example 2</p>

                        <img
                            alt="reinforcement order example"
                            src="pic/rules/supply2.jpg"
                        />
                        <p className="player_name example_red_player">red</p>
                        <p className="good_order">AE H</p>
                        <p className="good_order">REF to MA</p>
                    </div>
                </div>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            Although REF is not on the board and cannot be moved
                            to, these orders follow all of the same rules as a
                            standard move order. They can be blocked if the
                            target territory has an army in it, or if another
                            army tries to move into that territory. They can
                            also be supported by armies adjacent to the
                            territory that the reinforcement army is moving
                            into.
                        </p>

                        <p>
                            In Reinforcement Example 3, Red's reinforcement
                            order into Margalb fails because Blue's army in
                            Filp tries to move into the same territory. The Blue
                            order also fails because the two armies are in a
                            standoff.
                        </p>

                        <p>
                            Failed reinforcement orders are shown as a little X
                            next to the barracks icon.
                        </p>

                        <p>
                            <img
                                alt="failed barracks icon"
                                src="pic/icons/supplyPointFail.png"
                            />
                        </p>
                    </div>

                    <div className="example">
                        <p className="exmaple_label">Reinforcement Example 3</p>

                        <img
                            alt="reinforcement order example"
                            src="pic/rules/supply3.jpg"
                        />
                        <p className="player_name example_red_player">red</p>
                        <p className="good_order">AE H</p>
                        <p className="bad_order">REF to MA</p>
                        <p className="player_name example_blue_player">blue</p>
                        <p className="bad_order">FI to MA</p>
                    </div>
                </div>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            In Reinforcement Example 4, Red's reinforcement
                            order into Margalb succeeds because this time it has
                            support from the army in Aevora. The support gives
                            the reinforcement order a strength of 2, beating the
                            strength of 1 from the Blue army in Filp.
                        </p>

                        <p>
                            The only restrictions on a move order from REF are:
                        </p>
                        <ul>
                            <li>
                                The player controls more barracks than armies
                                the turn it was submitted
                            </li>
                            <li>
                                The move target is a barracks controlled by the
                                player making the order
                            </li>
                        </ul>
                    </div>

                    <div className="example">
                        <p className="exmaple_label">Reinforcement Example 4</p>
                        <img
                            alt="reinforcement order example 4"
                            src="pic/rules/supply4.jpg"
                        />
                        <p className="player_name example_red_player">red</p>
                        <p className="good_order">AE S REF to MA</p>
                        <p className="good_order">REF to MA</p>
                        <p className="player_name example_blue_player">blue</p>
                        <p className="bad_order">FI to MA</p>
                    </div>
                </div>

                <h3 id="Building and Reinforcement">Building and Reinforcing on the same turn</h3>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            It may not be immediately obvious what will happen if you submit a build order and a reinforcement order on the same turn
                        </p>
                        <p>
                            The validity of a reinforcement order is determined by how many barracks and armies a player has at the <i>start</i> of order resolution. So if a player has 3 barracks and 2 armies, they can submit a reinforcement order and build order that turn. If all of their orders succeed they will end the turn with 3 armies and 2 barracks.
                        </p>
                    </div>

                    <div className="example">
                        <p className="exmaple_label">Reinforcement Example 5</p>
                        <img
                            alt="reinforcement order example 5"
                            src="pic/rules/ref_build1.jpg"
                        />
                    </div>
                </div>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            In this example, the Red player has 3 barracks and 2 armies. They want to convert Mojack to a farm, but they also want to get a new army while they still have more barracks than armies, so they submit a reinforcement order to Kruff.
                        </p>
                        <p>
                            This is all legal! If no other player interferes both of these orders will go through. They now have more armies than barracks and will not be able to submit more reinforcement orders until they gain barracks or lose armies as described in the next section.
                        </p>
                    </div>

                    <div className="example">
                        <p className="exmaple_label">Reinforcement Example 5 continued</p>
                        <img
                            alt="reinforcement order example 5"
                            src="pic/rules/ref_build2.jpg"
                        />
                        <p className="player_name example_red_player">red</p>
                        <p className="good_order">MJ B</p>
                        <p className="good_order">REF to KU</p>
                    </div>
                </div>

                <h3>Having more armies than barracks</h3>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            In the course of the game, a player may find
                            themselves with more armies than barracks. For
                            instance, they may have moved their forces out and
                            another player moved in and took those barracks
                            without having to kill those armies. This is fine;
                            nothing happens. Players can have more armies than
                            barracks. However, they will not be able to submit
                            reinforcement orders until they capture more
                            barracks, or they suffer enough losses that their
                            number of armies is lower than the number of
                            barracks they still have.
                        </p>
                    </div>
                </div>

                <h2 id="Voting Events">Voting Events</h2>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            On some turns, the commissioner bot will announce a
                            voting event. This means some special effect will affect orders
                            submitted on the following turn. These effects
                            can be positive or negative.{" "}
                            <b>There are no votes in the first few turns</b>, so
                            you don't need to worry about this until the bot
                            announces a vote.
                        </p>

                        <p>
                            <img
                                alt="discord bot posting about voting"
                                src="pic/rules/voting_post.jpg"
                                width="600"
                            />
                        </p>

                        <p>
                            If a vote is happening, it is announced an hour
                            after the turn resolves. The vote will be between
                            two effects that will take effect on the <b>following</b> turn
                            (this delay makes sure that everybody knows exactly how their orders will be affected when they submit).
                            The bot will also announce which effect will be 
                            chosen in the event of a tie.
                        </p>

                        <p>
                            Players have until order resolution to vote. When
                            the turn is resolved the winner of the vote will be
                            announced and that effect will happen on the
                            following turn. This means that when submitting
                            orders, you will always know if they will be
                            affected by a voting event.
                        </p>

                        <p>
                            The effect from a voting event only last a single turn.
                            These effects are not cumulative.
                        </p>

                        <p>
                            <img
                                alt="vote info shown on the game page"
                                src="pic/rules/vote_game_page.jpg"
                                width="600"
                            />
                        </p>
                        <p>
                            In addition to being announced by the
                            skirmish-commissioner bot, the homepage of the game
                            will always list any events being voted on as well
                            as any events that could affect orders for that turn
                            period. You can mouse over the name of the vote to
                            see what it does.
                        </p>
                    </div>
                </div>

                <h3>How To Vote</h3>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            Once a vote has been announced, you can vote for the
                            effect that you want using the <BotCommand>/vote</BotCommand> command. <b>Voting 
                            must be done in the public channel</b> and
                            not over DM. You can choose not to vote, but one
                            of the two effects will happen even if nobody votes.
                        </p>

                        <p>
                            A vote for an effect named "Feast" would look
                            like this:
                        </p>

                        <p className="sample_orders">
                            /vote FEAST
                        </p>

                        <p>
                            <img
                                alt="examples of voting for a player in Discord"
                                src="pic/rules/voting_discord1.jpg"
                                width="600"
                            />
                        </p>

                        <p>
                            Once you vote, the bot will tally the current results.
                        </p>

                        <p>
                            <img
                                alt="examples of voting for a player in Discord"
                                src="pic/rules/voting_discord2.jpg"
                                width="600"
                            />
                        </p>

                        <p>
                            You can only vote once in a given turn. Players must have armies on the
                            board in order to vote.
                        </p>
                    </div>
                </div>

                <h2 id="Joining Forces">Joining Forces</h2>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            As the game progresses you may find that you've fallen behind. If this happens, you have the option of joining up with another player. If you do this, your territories and armies will be combined and you will form a new joint-player that you both control.
                        </p>

                        <p>
                            When two players join forces, the new player will have both of their names separated with a slash. For all gameplay purposes they are a single player. All territories and armies that either player had now belong to the new joint-player. Either one can submit orders to the bot for any army they control. The joint-player will get the Victory points of whichever player had <b>less</b> VP after that turns resolution.
                        </p>

                        <p>
                            During voting events, the joint-player will get a single vote.
                        </p>

                        <p>
                            <img
                                alt="map before and after two players jion forces"
                                src="pic/rules/join_forces_side_by_side.jpg"
                                width="600"
                            />
                            Here the blue and green players joined forces. They are now a single purple player.
                        </p>

                        <p>
                            Remember: joining forces is no replacement for good old fashioned alliances and handshake deals. Nobody can join forces until somebody has taken the lead so don't wait to make friends!
                        </p>

                        <h3>Requirements to Join Forces</h3>

                        <p>
                            Joining forces is a way for players to get back in the game, not a way for the players in the lead to ensure victory. For that reason, there are a few rules that must be satisfied for two players to join forces:
                        </p>

                        <ul>
                            <li>
                                The combined territory count of the two players cannot be greater than the highest territory count among players
                            </li>
                            <li>
                                At least one of the players has 3 less VP than the player in first place
                            </li>
                            <li>
                                Neither player has already joined forces with somebody else
                            </li>
                        </ul>

                        <p>
                            Here's a bit more info about each requirement: 
                        </p>

                        <ul>
                            <li>
                                The combined territory count of the two players cannot be greater than the highest territory count among players
                            </li>
                        </ul>

                        <p>
                            For example imagine Player A has 8 territories, Player B has 6, Player C has 4 and Player D has 2. Player A has the most (regardless of their VP), so 8 is the max size after joining forces. Players B and C cannot join forces because their total would be 10. B and D can join though because they would be exactly at 8. C and D are definitely good!
                        </p>

                        <ul>
                            <li>
                                At least one of the players has 3 less VP than the player in first place
                            </li>
                        </ul>

                        <p>
                            At least one member of the new joint team must be behind on victory points. For example, if the first place player has 5 VP, at least one half of the joint-player must be at 2 VP or less.
                        </p>

                        <p>
                            The reason only one player needs to satisfy this requirement is that when players join forces, the new joint-player will get the Victory Points of whichever player had less VP after that turns resolution. If a player with a lot of VP joins with a player who has 0, the joint-player will have 0.
                        </p>

                        <p>
                            The VP requirement means that nobody can join forces until at least one player has reached 3 VP.
                        </p>

                        <ul>
                            <li>
                                Neither player has already joined forces with somebody else
                            </li>
                        </ul>

                        <p>
                            Joining forces is for keeps and you can only do it once!
                        </p>

                        <p>
                            <b>These requirements are checked only when the join forces request is made.</b> Either player can gain territories or VP that turn and it won't invalidate the request.
                        </p>
                        

                        <h3>Join Forces Requests</h3>

                        <p>
                            To join forces, both players must agree on the same turn that they want to combine their resources. This is done by sending requests in the public channel. Both players must request to join forces with the other for it to work.
                        </p>

                        <p>
                            To join forces with a player named Fictitious Fiona you would use
                        </p>

                        <p className="sample_orders">
                            /join_forces @FictitiousFiona
                        </p>

                        <p>
                            And if Fiona is on board with this plan, she would use
                        </p>

                        <p className="sample_orders">
                            /join_forces @YourName
                        </p>

                        <p>
                            Here's how it looks typing out the command:
                        </p>
                        <p>
                            <img
                                alt="examples of players joinging forces in Discord"
                                src="pic/rules/join_forces_discord1.jpg"
                                width="600"
                            />
                        </p>

                        <p>
                            Here's how it looks in Discord (assuming both players agreed to join forces):
                        </p>
                        <p>
                            <img
                                alt="examples of players joinging forces in Discord"
                                src="pic/rules/join_forces_discord2.jpg"
                                width="600"
                            />
                        </p>


                        <h3>Joining Forces Victory Point Examples</h3>

                        <p>
                            The new joint account will always have the VP of whichever player has less VP after resolution. This means players can potentially earn VP the turn they request to join forces, but only if they both increase their VP (if they started with the same amount) or if the player with less earns VP through farming. Here are some examples of what would happen with different values:
                        </p>
                        <p>
                            If Player A has 4 VP and Player B has 3VP, when they join forces the new Player A/B will have 3 VP.
                        </p>

                        <p>
                            If A and B submit join forces requests and Player B farms for 1 VP that turn, Player A/B will have 4 VP because A and B both have 4VP after resolution.
                        </p>
                        
                        <p>
                            If both A and B farm for 1 VP, Player A/B will still have 4 VP because Player A will end the turn with 5 and B will end the turn with 4. A/B will inherit B's 4VP because it is lower. Player A probably should have done something else with their turn.
                        </p>

                        
                    </div>
                </div>

                <h2 id="Losing The Game">Losing The Game</h2>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            If you control no armies and no barracks
                            territories, you are out of the game.
                        </p>
                        <p>
                            Players with no armies who still control one or more
                            barracks can submit reinforcement orders to try and
                            get back on the board.
                        </p>
                    </div>
                </div>

                <h2 id="Changing Your Look">Changing Your Look</h2>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            You can change the color and pattern used to show
                            your territory and armies. Each one has a command
                            you can send to @skirmish-commissioner either
                            publicly or over DM.
                        </p>
                    </div>
                </div>

                <h3>/color [number]</h3>
                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            If you want to change your color, you can use <BotCommand>/color [number]</BotCommand> where [number] is a value between 1 and 20. 
                        </p>

                        <p>
                            For exmaple, to set your color to purple, you would use <BotCommand>/color 16</BotCommand>.
                        </p>
                            
                        <p>
                            Here are the color choices:
                        </p>
                        <p>
                            <img
                                alt="color guide"
                                src="pic/rules/color_guide.png"
                            />
                        </p>
                    </div>
                </div>

                <h3>/pattern [number]</h3>
                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            You can set what pattern appears on your
                            territories. You must enter a number between 1 and
                            8. For instance, to select pattern 5 you can use <BotCommand>/pattern 5</BotCommand>.
                        </p>

                        <p>
                            <img alt="patterns 1-8" src="pic/rules/patterns.png" />
                        </p>

                        <p>
                            If you have purchased the High Society Account Upgrade you get access to 8 more funky patterns:
                        </p>

                        <p>
                            <img alt="patterns 9-16" src="pic/rules/patterns_premium.png" />
                        </p>
                    </div>
                    
                </div>

                <h2 id="High Society">High Society Account Upgrade</h2>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            If you're enjoying the game, you can upgrade your
                            account with the High Society upgrade.{" "}
                            <b>This provides no in-game advantage</b> but does
                            offer some helpful tools and gives you access to fun aesthetic options.
                        </p>

                        <p>
                            In addition to the new patterns you can select from (9-16 in the image above!),
                            if you upgrade to High Society the bot will automatically
                            check your orders to make sure they're valid. No
                            more finding out at resolution time that you typed
                            something wrong! Doing this requires the backend to
                            load and resolve the board every time an order is sent, which is a heavy
                            operation. The cost of doing this adds up in server fees
                            which is why this is restricted to High Society accounts.
                        </p>

                        <p>
                            Admins with the High Society upgrade will also get access
                            to new <a href="#High Society Admin Commands">admin commands</a>.
                        </p>

                        <p>
                            You can buy a High Society key from the{" "}
                            <a href="/shop">shop</a>. After you purchase you
                            will get an email with your activation key.
                        </p>

                        <p>You can redeem your key using the redeem command.</p>

                        <p className="sample_orders">
                            /redeem [YOUR_KEY]
                        </p>

                        <p>
                            Buying a High Society upgrade helps me cover server
                            costs and supports development of this game. As an indie dev, it really helps!
                        </p>
                    </div>
                </div>

                <h2 id="Other Commands">Other Commands</h2>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            The vast majority of what you send to the bot will
                            be orders, but there are a few other commands you
                            can use besides <BotCommand>/submit</BotCommand>.
                        </p>

                        <p>
                            Unless otherwise stated these commands can be used in the public #skirmish-society or in a DM with the bot.
                        </p>
                    </div>
                </div>

                <h3>/join</h3>
                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            To start playing, use <BotCommand>/join</BotCommand> in
                            the #skirmish-society channel. You'll be prompted to respond over DM, but you must start this process in the public channel so that the bot knows which server you are playing on.
                        </p>
                        <p>
                            Once the game has started, new players will not be able
                            to join.
                        </p>
                    </div>
                </div>

                <h3>/resign</h3>
                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            If you decide that you do not want to keep playing,
                            you can use <BotCommand>/resign</BotCommand> to
                            quit.
                        </p>
                        <p>
                            During the join phase, this command will just remove you from the game. You can still rejoin as long as the game is still in the join phase.
                        </p>
                        <p>
                            Once the game has started, this command will permanently remove you from the game. Your armies will receive hold orders until captured by other players. This will automatically
                            turn off notifications as well. Be careful: This
                            cannot be undone.
                        </p>
                    </div>
                </div>

                {/* <h3>unjoin</h3>
                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            The opposite of join. If the game has not started
                            yet and you realize you want out, you can post <BotCommand>@skirmish-commissioner unjoin</BotCommand> to be removed from
                            the game.
                        </p>
                    </div>
                </div> */}

                <h3>/help</h3>
                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            This posts a link to the current board, the rules, and some info about the current state of the game.
                            Use <BotCommand>/help</BotCommand> and the bot will reply
                            with the links.
                        </p>
                    </div>
                </div>

                <h3>/cheat_sheet</h3>
                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            Prints out a list of the orders with examples.
                        </p>
                        <p>
                            Just use <BotCommand>/cheat_sheet</BotCommand> over DM or in the public channel.
                        </p>
                    </div>
                </div>

                <h3>/broadcast [your message]</h3>
                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            You can have the bot anonymously post a message to
                            the public channel. DM <BotCommand>/broadcast [your message]</BotCommand> to
                            have the skirmish-commissioner send out your
                            message.
                        </p>
                    </div>
                </div>

                <h3>/vote [Your selection]</h3>
                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            This command is used during a voting event. See{" "}
                            <a href="#Voting Events">Voting Events</a> for info
                            on how to use it.
                        </p>
                        <p>
                            ex: <BotCommand>/vote FEAST</BotCommand>
                        </p>
                    </div>
                </div>

                <h3>/notifications [true/false]</h3>
                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            By default the bot will DM you two times per day.
                            Once to tell you resolution will happen soon and
                            once to tell you how your orders resolved. You can
                            disable this by using <BotCommand>/notifications false</BotCommand> or enable it again by
                            using <BotCommand>/notifications true</BotCommand>
                        </p>
                    </div>
                </div>

                <h3>/refresh_name</h3>
                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            If you change your display name for the server, you can 
                            use <BotCommand>/refresh_name</BotCommand> to have your name updated on the map.
                        </p>
                        <p>
                            This will happen automatically if you change your color, change your pattern, vote, or submit an order from the public channel. 
                        </p>
                        <p>
                            This only works in the public channel because the bot cannot see your server nickname over DM.
                        </p>
                    </div>
                </div>

                <h3>/list</h3>
                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            The bot can list the orders you have submitted so
                            far for this turn along with your valid orders from
                            last turn. Although you can use <BotCommand>/list</BotCommand> in the public
                            channel it would be wiser to do it over DM. If you post this publicly,
                            all of your currently submitted orders will be
                            revealed.
                        </p>
                    </div>
                </div>


                <h3>/hint</h3>
                <div className="rules_section">
                    <div className="rules_text">
                        <p>Use <BotCommand>/hint</BotCommand> to get a random hint.</p>
                    </div>
                </div>

                <h3>/call_of_duty_loading_screen</h3>
                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            In case you need some sage advice from tacticians of
                            yesteryear you can use <BotCommand>/call_of_duty_loading_screen</BotCommand>.
                        </p>
                        <p>
                            This is similar to <BotCommand>/hint</BotCommand> except that it is useless.
                        </p>
                    </div>
                </div>

                <h2 id="Admin Commands">Admin Commands</h2>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            You will need at least one admin to play the game.
                            This must be a mod on the server (any user with the
                            Manage Server permission) or a user with the
                            "skirmish society admin" role. It is fine to have
                            multiple admins.
                        </p>

                        <p>
                            In addition to being able to setup & start the game,
                            users with this role have access to additional tools
                            to help them run the game. All of these commands
                            must be used in the public channel.
                        </p>

                        <p>
                            This role does not need any special permissions and
                            can be any color, but it must be named "skirmish
                            society admin" because that is what the bot will be
                            looking for.
                        </p>
                        <p>
                            You do not need to create "skirmish society admin"
                            if the person running the game already has Manage
                            Server permissions on your server. This role is only
                            needed you want to let somebody who is not a server mod run
                            the game.
                        </p>
                        <p>
                            If you have purchased the <a href="/shop">High Society</a> upgrade, you
                            get access to <a href="#High Society Admin Commands">special admin commands</a> to 
                            let you take even
                            more control of how you run the game.
                        </p>
                    </div>
                </div>

                <h3>/setup</h3>
                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            Creates a new game room. This will overwrite any
                            game in progress.
                        </p>
                        <p>
                            Use <BotCommand>/setup</BotCommand> to start the setup.
                            The bot will ask you a few questions and then create a room for you.
                        </p>
                        <p>
                            This process is described in detail in the <a href="/setup-guide">simple setup guide</a>.
                        </p>
                    </div>
                </div>

                <h3>/start</h3>
                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            Once all players are in, this command starts the
                            game!
                        </p>
                    </div>
                </div>

                <h3>/pause</h3>
                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            Pauses the game, preventing turn resolutions until
                            the game is unpaused.
                        </p>
                    </div>
                </div>

                <h3>/unpause</h3>
                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            Resumes a paused game. Resolution will not happen
                            until the normal resolution time.
                        </p>
                    </div>
                </div>

                <h3>/broadcasting [true/false]</h3>
                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            Enables or disables the use of the broadcast command
                            for all players.
                        </p>
                    </div>
                </div>

                <h3>/ban [PLAYER]</h3>
                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            Bans a player from the game, causing the bot to
                            ignore any orders from this player. This command is
                            formatted as <BotCommand>/ban
                            @NAME_OF_PLAYER</BotCommand>.
                        </p>
                        <p>
                            For example, to ban JohnFakeName, you would write <BotCommand>/ban @JohnFakeName</BotCommand>.
                        </p>
                        <p>
                            In the join phase, banning a player will remove them from the game. If unbanned before the game starts, they can use the <BotCommand>/join</BotCommand> command again to rejoin.
                        </p>
                        <p>
                            If the game has started, the bot will ignore orders from a banned player, but they are not automatically resigned. If unbanned, they can continue playing.
                        </p>
                    </div>
                </div>

                <h3>/unban [PLAYER]</h3>
                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            Unbans a player. This command is formatted as <BotCommand>/unban @NAME_OF_PLAYER</BotCommand>.
                        </p>
                    </div>
                </div>

                <h3>/resolution_time [HOUR]</h3>
                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            Changes when the game will resolve. This command is
                            formatted as <BotCommand>/resolution_time
                            [HOUR]</BotCommand>.
                        </p>
                        <p>
                            HOUR can be an integer between 0 and 23 and can
                            optionally include AM or PM at the end. The time
                            will be based on the local time for the game.
                        </p>
                        <p>
                            For example, to have the game resolve at 6AM local time, you could use <BotCommand>/resolution_time 6</BotCommand> or <BotCommand>/resolution_time 6AM</BotCommand>.
                        </p>
                        <p>
                        To have it resolve at 2PM local time, you could use <BotCommand>/resolution_time 14</BotCommand> or <BotCommand>/resolution_time 2PM</BotCommand>.
                        </p>
                    </div>
                </div>

                <h3>/local_time [HOUR]</h3>
                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            Changes the local time zone for the game. This is
                            done by inputting the current hour where you are.
                            This command is formatted as <BotCommand>/local_time [HOUR]</BotCommand>.
                        </p>
                        <p>
                            Just like /resolution_time, HOUR can be an integer between 0 and 23 and can
                            optionally include AM or PM at the end. For example,
                            if it is 6:45AM where you are, put "6" or "6AM". If
                            it is 2:03PM, put "14" or "2PM".
                        </p>
                        <p>
                            After you put in the hour, the bot will ask you to
                            confirm your time zone.
                        </p>
                        <p>
                            Changing the local time zone will not alter the time
                            when the game will resolve.
                        </p>
                        <p>
                            Note: right now this game does not check daylight
                            savings time, so you may want to use this command if
                            the clocks change in your area.
                        </p>
                    </div>
                </div>

                <h3>/kill_room</h3>
                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            This completely deletes the room and removes all players. You will not be able to view the map or anything after using this. Be very careful with this; it cannot be undone.
                        </p>
                        <p>
                            You do not ever need to use this since you can always use <BotCommand>/setup</BotCommand> to start a new game, but it is available if you want to stop a game in progress or bail on a game that is still in the join phase.
                        </p>
                        <p>
                            Players cannot join multiple games of Skirmish Society, so this is one way to free players up if a game has not started yet. Those players can also use the <BotCommand>/resign</BotCommand> command individually.
                        </p>
                    </div>
                </div>

                <h2 id="High Society Admin Commands">High Society Admin Commands</h2>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            If you are in charge of the game and you have the <a href="/shop">High Society</a> upgrade
                            there are a few other powerful admin commands you can use to run your server.
                        </p>

                        <p>
                            These commands will only work if the user submitting them has purchased the High Society
                            upgrade and could otherwise use admin commands (they are a mod on the server 
                            or a user with the "skirmish society admin" role).
                        </p>

                    </div>
                </div>

                <h3>/fast_mode</h3>
                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            Although it is not the intended way to play the game,
                            if you want to play a much faster version of the
                            game you can set the bot to resolve the turn every
                            15 minutes. This can be useful if you want to stream
                            the game or play it in one sitting.
                        </p>

                        <p>
                            This can be turned on or off at any time, so you
                            could also use fast mode to speed through the
                            first few turns of a game before setting it back to
                            every 24 hours. In general though, the gameplay will
                            be better if you let the game play out at the
                            intended, slower pace.
                        </p>

                        <p>
                            You can turn on fast mode with the
                            command <BotCommand>/fast_mode true</BotCommand>.
                        </p>

                        <p>
                            And turn it back off with <BotCommand>/fast_mode false</BotCommand>.
                        </p>

                        <p>
                            The pause command will still work in fast
                            mode in case you want to split the game up between
                            multiple sessions.
                        </p>
                        
                    </div>
                </div>

                <h3>/advanced_setup</h3>
                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            Just like the normal setup command, but with more options so you can better tailor your game!
                        </p>

                        <p>You will need to provide several pieces of information when you enter the command:</p>
                        
                        <p>
                            <ul>
                                <li><b>vp</b>: the amount of VP needed to win (up to 50).</li>
                                <li><b>current_hour</b>: the current time where you are. Formatted like you would for /local_time.</li>
                                <li><b>fast_mode_enabled</b>: True if you want to play in Fast Mode, False if you want a normal game.</li>
                                <li><b>resolution_hour</b>: The hour you want turns to resolve. Formatted like you would for /local_time. (put any value if you are using Fast Mode).</li>
                                <li><b>votes</b>: Voting frequency. Every turn, every other turn, or disabled.</li>
                                <li><b>vote_start_turn</b>: The turn that voting should start. Enter any value if votes have been turned off.</li>
                            </ul>
                        </p>

                        <p>There are some extra options which you can set when using advanced setup</p>  

                        <p>
                            <ul>
                                <li><b>map_type</b>: How much room players have.</li>
                                <ul>
                                    <li><b>Normal</b>: The intended experience.</li>
                                    <li><b>Spacious</b>: There will be more unclaimed territories and slightly more barracks. Note that spacious maps will have much less conflict.</li>
                                </ul>
                                <li><b>voting_mode</b>: How voting will work.</li>
                                <ul>
                                    <li><b>Normal</b>: The intended experience. Voting will work the way it is described in this rules document</li>
                                    <li><b>Same Day</b>: An experimental mode where votes are resolved the same turn they are announced and affect the orders submitted that turn. Votes can be placed in secret and players do not know the outcome when submitting orders.</li>
                                    <li>Same-day votes create much more tactical uncertainty when submitting orders. This setting is for groups that want a game that leans more on the social elements. It is not recomended for new players.</li>
                                </ul>
                                <li><b>hints</b>: True if you want the bot to give hints, False to disable hints.</li>
                            </ul>
                        </p>

                        <p>
                            Once your account has High Society, you can start typing <BotCommand>/advanced_setup</BotCommand> and the bot will prompt you to enter all necessary information.
                        </p>

                        <p>The optional settings can be accessed by clicking the "+3 more" text after filling out the required settings or by pressing tab once you have filled in all the required info. On mobile, they should appear above the text box once you have entered all of the required info.</p>
                        
                    </div>
                </div>

                <h3>/rewind</h3>
                <div className="rules_section">
                    <div className="rules_text">
                        <p>
                            Undoes the most recent turn resolution, rewinding the gamestate to the previous turn.
                        </p>
                        <p>
                            The orders for that turn will be stored as submitted orders (which can be overwritten with new ones). Any votes cast for a voting event will be lost and will need to be resubmitted.
                        </p>
                        <p>
                            Do not do this lightly. The rewind command should be reserved for weird mitigating factors that caused players to miss the turn or to undo an early turn if players greatly misunderstood the rules.
                        </p>
                    </div>
                </div>


                <h2 id="Closing Thoughts">Closing Thoughts</h2>

                <div className="rules_section">
                    <div className="rules_text">
                        <p>Thank you for checking out this game! I hope you have a good time with it!</p>

                        <p>
                            Skirmish Society is being actively developed. I am trying to make it freely
                            available to play but server costs may add up and I may have to charge to start a game.
                            In the meantime, the best way to support the game and its development is to purchase
                            a <a href="/shop">High Society</a> account. It's cheap and gives you access to goodies!
                            If you're enjoying the game, it really helps.
                        </p>
                        
                        <p>
                            Direct questions/feedback/whatever to Andy
                            (andy[at]andymakes[dot]com).
                        </p>

                        <p>
                            You can also join the <a href='https://discord.gg/zBSn8p9qtn'>Skirmish Society Society</a> on Discord. This is the official Discord group for the game. You can use it to talk strategy, report bugs or find people to play with.
                        </p>

                        <p>If you are curious about how the game has changed over time, have a look at the <a href="/changelog">Changelog</a>.</p>

                        <p>Thanks for reading this far!</p>
                    </div>
                </div>

                <h2 id="Credits">Credits</h2>

                <div className="rules_section">
                    <div className="rules_text">
                        <ul>
                        <li>Design & Programming by <a href='http://andymakes.com/'>Andy Wallace</a></li>

                        <li>Frontend Programming by <a href='https://walt.codes/'>Walt Mitchell</a> and <a href='http://robysoft.net/'>Nick Santaniello</a></li>
                        <li>Icons by <a href='https://twitter.com/iscariot83'>Chris Hernandez</a></li>
                        <li>Additional Backend Programming by <a href='http://dustmop.io/'>Dustin Long</a></li>
                        <li>Trailer by <a href='https://trashpandaqc.xyz/'>Trash Panda QC</a></li>
                        <li>Skirmish Commissioner Icon by <a href='https://thenounproject.com/icon/general-688897/'>lastspark</a></li>
                        
                        </ul>

                        <p><a href='/legal'>Licenses and Credits</a></p>
                        
                    </div>
                </div>
            </Wrapper>
        </Page>
    );
};

export default Rules;
