import React, { useCallback } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { mobile } from "../../util/media-query.js";
import useGameState from "../../hooks/useGameState.jsx";
import Tooltip from "../Tooltip/Tooltip.jsx";
import isMobile from "../../util/is-mobile.js";

const TooltipContent = styled.div`
    background-color: white;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    padding: 10px 15px;
    font-size: 18px;
    font-weight: bold;
    color: black;
    z-index: 2;

    ${mobile.css`
        padding: 5px;
        font-size: 12px;
    `}
`;

const PlayerName = styled.div`
    color: ${(props) => props.color};
`;
const OrderSummary = styled.div`
    font-size: 14px;
    max-width: 300px;
    font-weight: normal;
`;

const PlayerTooltip = ({ boardRef }) => {
    const { highlightedPlayer, hoveringCanvas, highlightedTerritory } = useGameState();
    const { name, hue } = highlightedPlayer || {};

    const renderTooltip = useCallback(() => {
        const color = `hsl(${hue}, ${hue < 170 ? "60%" : "90%"}, 50%)`;
        let summary_text = ""
        if (highlightedTerritory != null){
            if (highlightedTerritory.order){
                summary_text = highlightedTerritory.order.summary_text
            }
        }
        return (
            name &&
            hoveringCanvas && (
                <TooltipContent>
                    <PlayerName color={color}>{name}</PlayerName>
                    <OrderSummary>{summary_text}</OrderSummary>
                </TooltipContent>
            )
        );
    }, [name, hue, highlightedTerritory]);

    return boardRef.current ? (
        <Tooltip
            renderTooltip={renderTooltip}
            forceVisible={isMobile()}
            customRef={boardRef}
        />
    ) : null;
};

PlayerTooltip.propTypes = {
    boardRef: PropTypes.object,
};

export default PlayerTooltip;
