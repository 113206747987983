import React from "react";
import querystring from "query-string";
import { Link, useLocation } from "@reach/router";
import styled from "@emotion/styled";

import useSessionState from "../../hooks/useSessionState";
import Button from "../Button/Button";

const Wrapper = styled.nav`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;
    background: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 3;

    @media screen and (min-width: 768px) {
        top: auto;
        left: auto;
        height: 70px;
    }
`;

const LeftContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (min-width: 768px) {
        position: relative;
        justify-content: flex-start;

        &:after {
            content: "";
            display: table;
            clear: both;
        }
    }
`;

const Logo = styled.div`
    padding: 0 20px;

    img {
        max-height: 60px;
        padding-top: 5px;
    }
    a {
        text-decoration: none;
        color: black;
        font-size: 24px;
        font-weight: bold;

        &:hover {
            text-decoration: underline;
        }
    }

    @media screen and (min-width: 768px) {
        padding: 0 25px;

        a {
            font-size: 30px;
        }
    }
`;

const LeftMenu = styled.div`
    display: flex;
    align-items: center;
    padding: 0 15px 0 0;
`;

const RightMenu = styled.div`
    display: flex;
    align-items: center;
    padding: 0 15px 0 0;
`;

const Item = styled.div`
    padding: 0 5px;

    a {
        text-decoration: none;
        font-size: 14px;

        &:hover {
            text-decoration: underline;
        }
    }

    @media screen and (min-width: 768px) {
        a {
            font-size: 16px;
        }
    }
`;

const ItemLink = styled(Link)`
    color: black;
`;

const Nav = () => {
    const { pathname, search } = useLocation();
    const params = querystring.parse(search);
    const { currentRoom } = useSessionState();
    
    return (
        <Wrapper>
            <LeftContainer>
                <Logo>
                    
                    <ItemLink to="/"><img src="/pic/logo/ExportHorz@2x_trim.png"></img></ItemLink>
                </Logo>
                <LeftMenu>
                    <Item>
                        <ItemLink to="/rules">Rules</ItemLink>
                    </Item>
                    <Item>|</Item>
                    {/* <Item>
                        <ItemLink to="/setup-guide">Install</ItemLink>
                    </Item>
                    <Item>|</Item> */}
                    <Item>
                        <ItemLink to="/sandbox">Sandbox</ItemLink>
                    </Item>
                    <Item>|</Item>
                    <Item>
                        <ItemLink to="/shop">Shop</ItemLink>
                    </Item>
                </LeftMenu>
            </LeftContainer>
            {(pathname !== "/play" || !params.room?.length) && (
                <RightMenu>
                    {/* <Item>
                        <ItemLink primary to="/play">
                            Find a Room
                        </ItemLink>
                    </Item> */}
                    {currentRoom?.length > 0 && (
                        <>
                            <Item> </Item>
                            <Item>
                                <Button
                                    primary
                                    to={`/play?room=${currentRoom}`}
                                >
                                    Resume Game
                                </Button>
                            </Item>
                        </>
                    )}
                </RightMenu>
            )}
        </Wrapper>
    );
};

export default Nav;
