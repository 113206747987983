import React from "react";
import styled from "@emotion/styled";

import GameBoard from "../../components/GameBoard/GameBoard";
import GameInstance from "../../components/GameInstance/GameInstance";

const BoardWrapper = styled.div`
    background: white;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const Renderer = () => {
    return (
        <GameInstance>
            <BoardWrapper>
                <GameBoard isRender />
            </BoardWrapper>
        </GameInstance>
    );
};

export default Renderer;
