import draw_territory from "./draw_territory.js";
import hex_manager from "./hex_manager.js"
import sketch from "./sketch.js";

const force_draw_demo_map = false      //shows all locked territories by lock_group

const unclaimed_col = [255, 250, 230];
const ocean_col = [13, 28, 63];//[27, 54, 115];

const player_sat = 42//25;
const player_bri = 75//85;

const player_dead_sat = 5;

function draw_map({is_sandbox, fbos, screenshot_timer, sandbox_resolve_timer, p}) {

    // p.image(fbos.base, 0,0)
    // p.image(fbos.highlight,0,0)
    // p.image(fbos.ui,0,0)

    //3x3 tiling system making sure that nothing entirely off screen gets drawn
    let test_draw_count = 0;
    let tile_dist_padding = 0;
    for (let c=-1; c<=1; c++){
        for (let r=-1; r<=1; r++){

            let draw_pos = {
                x: fbos.base.width * c,
                y: fbos.base.height * r
            }

            let should_draw = true

            let screen_top_left = sketch.map_pos_to_screen_pos(draw_pos);
            let screen_bottom_right = sketch.map_pos_to_screen_pos({x:draw_pos.x+fbos.base.width, y:draw_pos.y+fbos.base.height});

            if (p.frameCount % 10 == 0){
                // console.log(p.width + " , " +p.height)
                // console.log( screen_top_left );
            }
            if (screen_top_left.x > p.width + tile_dist_padding){
                should_draw = false;
            }
            if (screen_top_left.y > p.height + tile_dist_padding){
                should_draw = false;
            }
            if (screen_bottom_right.x < -tile_dist_padding){
                should_draw = false;
            }
            if (screen_bottom_right.y < -tile_dist_padding){
                should_draw = false;
            }
            

            if (should_draw){
                test_draw_count++;
                p.image(fbos.base, draw_pos.x, draw_pos.y)
                p.image(fbos.highlight,  draw_pos.x, draw_pos.y)
                p.image(fbos.ui,  draw_pos.x, draw_pos.y)
            }
        }
    }

    //if(p.frameCount%10==0)  console.log("tiles drawn: "+test_draw_count);
    

    // time for a screenshot? This is for use with APIFlash, which will wait until a div with the id take_screenshot shows up
    // TODO: this should be done via react
    // TODO: even better, stop using APIFlash and do this yourself somehow
    //console.log("screenshot timer "+screenshot_timer)
    if (screenshot_timer == 0) {
        console.log("SCREENSHOT READY!");
        var node = document.createElement("DIV");
        node.id = "take_screenshot";
        document.body.appendChild(node);
    }
}

function create_fbos({map_size, p}){
    let fbos = {}

    // on retina displays, the pixel density will often be 2. This doubles the size of the FBOs
    const original_pixel_density = p.pixelDensity();
    console.log(`original pixel density: ${original_pixel_density}`);
    // setting it to 1 so our FBOs have a 1:1 pixel size
    // this keeps the FBOs from doubling in size
    p.pixelDensity(1);

    const fbo_width = map_size.x ;
    const fbo_height = map_size.y ;

    console.log("fbo size: "+fbo_width+" , "+fbo_height);
    fbos.base = p.createGraphics(fbo_width, fbo_height);
    fbos.highlight = p.createGraphics(fbo_width, fbo_height);
    fbos.ui = p.createGraphics(fbo_width, fbo_height);

    // put it back otherwise everyhting renders blurry
    p.pixelDensity(original_pixel_density);

    return fbos
}

function reset_map_canvas({game_state, app_state, icons, fbos, props, p}) {
    const { showOrders } = app_state;

    let on_render_mode = props.pathname === "/render"

    //if we're on render mode, I want to put name tags in, so we'll keep track of parts of the screen that has info we care about
    let ui_hit_circles = null
    if (on_render_mode){
      ui_hit_circles = [];  
    } 

    // do some quick updates on all of the territories
    game_state.territories.forEach((ter) => {
        set_perim_line(ter);
    });

    if (force_draw_demo_map){
        draw_demo_map(game_state, icons, fbos.base, p)
    }
    else{
        //basic territories
        refresh_base_map({
            territories: game_state.territories,
            players: game_state.players,
            icons: icons,
            fbo:fbos.base,
            p:p
        });

        //player info and orders
        refresh_ui({
            show_orders: showOrders,
            game_state: game_state,
            icons: icons,
            fbo: fbos.ui,
            ui_hit_circles : ui_hit_circles,
            p: p
        });
    }

    if (on_render_mode){
        draw_name_tags({game_state:game_state, ui_hit_circles:ui_hit_circles, fbo:fbos.base, icons:icons, p:p});
    }

    //testing ui hit boxes
    // if (ui_hit_circles != null){
    //     console.log("HIT CIRCLES: "+ui_hit_circles.length)
    //     ui_hit_circles.forEach( (hit) => {
    //         fbos.ui.noFill();
    //         fbos.ui.colorMode(p.RGB);
    //         fbos.ui.stroke(255,0,0);
    //         fbos.ui.strokeWeight(1);
    //         fbos.ui.ellipse(hit.x, hit.y, hit.s, hit.s);
    //     })
    // }
}

function draw_name_tags({game_state, ui_hit_circles, fbo, icons, p}){
    const {territories} = game_state
    const {players} = game_state
    const {orders} = game_state

    if (!players) {
        return false;
    }

    console.log(game_state)

    //set font info here because we need to get the right values when we measure the text
    fbo.strokeJoin(p.ROUND);
    //fbo.textFont(icons.font);     //this font did not support special characters 
    //fbo.textSize(35);
    fbo.textFont("serif");
    fbo.textSize(40);
    fbo.textStyle("bold");
    fbo.textAlign("center", "center");

    players.forEach( player => {
        //go through each hex that player controls
        let possible = []
        let boxes = []

        let hexes_to_check = []
        territories.forEach( ter => {
            if (ter.player == player){
                ter.hexes.forEach( hex => {
                    hexes_to_check.push(hex);

                    //add ocean neighbors
                    hex.neighbors.forEach( neighbor=> {
                        if (neighbor.territory == null){
                            if (!hexes_to_check.includes(neighbor)){
                                hexes_to_check.push(neighbor);
                            }
                        }
                    }) 

                })
            }
        })

        //get the avg position
        let avg_pos = {x:0,y:0};
        hexes_to_check.forEach( hex =>{
            let pnt = hex_manager.get_hex_center(hex)
            avg_pos.x += pnt.x;
            avg_pos.y += pnt.y;
        })
        avg_pos.x /= hexes_to_check.length
        avg_pos.y /= hexes_to_check.length

        hexes_to_check.forEach( hex =>{
            let pnt = hex_manager.get_hex_center(hex)

            if (!hit_any_circle(ui_hit_circles, {x:pnt.x, y:pnt.y, s:10}, p)){

                //give it a score
                let score = 0

                //prefer hexes on the ocean
                let on_ocean = false
                hex.neighbors.forEach( neighbor=> {
                    if (neighbor.territory == null){
                        on_ocean = true
                    }
                }) 
                if (on_ocean)   score += 1;


                //prefer hexes surrounded only by this ter
                let in_center = true
                hex.neighbors.forEach( neighbor=> {
                    if (neighbor.territory != hex.ter){
                        in_center = false
                    }
                }) 
                if (in_center)   score += 1;

                //reduce score for each claimed neighbor
                hex.neighbors.forEach( neighbor=> {
                    if (neighbor.territory != null){
                        if (neighbor.territory.player != player && neighbor.territory.player != null){
                            score -= 1;
                        }
                    }
                }) 

                //give bonus for being close to the center of this player's territory
                let center_dist = p.dist(pnt.x,pnt.y, avg_pos.x, avg_pos.y)
                let dist_prc = Math.max(0, 1.0 - center_dist/400.0);    //arbitrary max dist
                score += dist_prc * 2

                //add it to the list
                pnt.score = score
                possible.push(pnt)
            }
        })

        //sort it so we start checking the best options
        //we maybe aren't using this since it looks like we're just grabbing got all possible that have a possitive score
        possible.sort((a, b) => (a.score > b.score) ? -1 : 1)

        //some info about the name tag box
        let box_w = fbo.textWidth(player.name) + 20;
        let box_h = 34;


        let possible_angles = [0, p.PI/32, -p.PI/32, p.PI/16, -p.PI/16, p.PI/8, -p.PI/8, p.PI/5, -p.PI/5]
        let angle_id = 0

        let at_least_one_box_not_toching_ui = false;    //as soon as we find a box not conflicting with UI, stop saving any boxes that do. We only want to use a box touching UI if no others exist

        for (let angle_id = 0; angle_id<possible_angles.length; angle_id++){
            let angle = possible_angles[angle_id]
        
            for (let pos_id=0; pos_id < possible.length; pos_id++){
                let touching_ui = false
                let tag_pos = possible[pos_id]

                if (possible[pos_id].score > 0){

                    
                    let hits = []

                    //create some hit circles
                    let padding = 15
                    for (let d=-box_w/2+padding; d<box_w/2-padding; d+=15){
                        let hit = {
                            x:tag_pos.x+Math.cos(angle) * d, 
                            y:tag_pos.y+Math.sin(angle) * d, 
                            s:30
                        };
                        hits.push(hit);
                    }

                    //check if any hit
                    if(hit_any_circles(ui_hit_circles, hits, p)){
                        touching_ui = true
                    }

                    //if it is good, store it
                    if (!touching_ui || !at_least_one_box_not_toching_ui){

                        let box = {
                            x:tag_pos.x,
                            y:tag_pos.y,
                            angle:angle,
                            touching_ui: touching_ui,
                            hits:[]
                        }
                        hits.forEach(hit=>{
                            box.hits.push(hit)
                        })
                        boxes.push(box)

                        if (!touching_ui){
                            at_least_one_box_not_toching_ui = true
                        }
                    }

                }
                // else{
                //     is_good = false
                // }

            }
        }

        console.log(player.name+" has "+boxes.length+" valid boxes")

        //go through each box and rate them
        boxes.forEach( box => {
            box.score = 0;

            //if it is touching UI that is a big penalty. Only use this box if nothing else fits
            if (box.touching_ui){
                box.score -= 100;
            }

            //the closer the angle is to 0, the better
            let angle_score = (p.PI/5 - Math.abs(box.angle)) * 7
            box.score += angle_score
            //console.log("angle "+box.angle)
            //console.log("angle score "+angle_score)

            //give bonus for being close to the center of this player's territory
            let center_dist = p.dist(box.x,box.y, avg_pos.x, avg_pos.y)
            let dist_prc = Math.max(0, 1.0 - center_dist/400.0);    //arbitrary max dist
            box.score += dist_prc * 5//15

            //check each hit circle
            box.hits.forEach( hit => {
                let hit_any = false
                hit.val = 0 //this is just for dmeoing values and can be removed. It is not used in any calculation
                //figure out what if any hex this is in
                territories.forEach( ter => {
                    ter.hexes.forEach( hex => {
                        if (hex_manager.pnt_inside_hex([hit.x,hit.y], hex)){
                            hit_any = true
                            if (hex.territory.player == null){
                                //console.log("  neutral, do nothing")
                            }
                            else if (hex.territory.player == player){
                                box.score += 1
                                hit.val = 1
                                //console.log ("  ours, we love it!")
                            }
                            else{
                                box.score -= 4;
                                hit.val = -4
                                //console.log("  somebody else's. we hate it!")
                            }
                        }
                    })
                })

                if (!hit_any){
                    //console.log("  in the ocean")
                    box.score += 0.5
                    hit.val = 0.5
                }
            })


            //console.log("box score: "+box.score)
        })

        //sort the boxes
        boxes.sort((a, b) => (a.score > b.score) ? -1 : 1)

        //draw it
        if (boxes.length > 0){
            let box = boxes[0]

            console.log(player.name+" winning box score: "+box.score)

            for (let c=-1;c<=1;c++){
                for (let r=-1;r<=1;r++){
                    fbo.push();
                    fbo.translate(box.x+fbo.width*c, box.y+fbo.height*r);
                    fbo.rotate(box.angle);
                    
                    fbo.colorMode(p.HSB);
                    
                    fbo.strokeWeight(10)
                    fbo.stroke(player.hue, 5, 100);
                    fbo.fill(player.hue, 75,40)

                    fbo.text(player.name, 0,0);

                    fbo.pop()
                }
            }

            //add the hit circles to ui_hit_circles
            box.hits.forEach(hit => {
                ui_hit_circles.push(hit)

                //demo testing
                // if (hit.val < 0){
                //     fbo.fill(255,0,0)
                // }else{
                //     fbo.fill(0,255,0)
                // }
                // let size = 4 + Math.abs(hit.val) * 4
                // fbo.ellipse(hit.x, hit.y, size)

            })
        }

        //testing
        // fbo.colorMode(p.RGB);
        // possible.forEach( pnt => {
        //     if (pnt.score >= 0){
        //         fbo.fill(255 );
        //     }else{
        //         fbo.fill(255,0,0 );
        //     }

        //     let size = 2 + pnt.score * 3
        //     fbo.ellipse(pnt.x, pnt.y, size,size)
        // })
    })
}

//one test circle
function hit_any_circle(hit_circles, test_circle, p){
    for (let i=0; i<hit_circles.length; i++){
        let hit_circ = hit_circles[i];
        let dist = p.dist(hit_circ.x, hit_circ.y, test_circle.x, test_circle.y);
        if (dist < hit_circ.s/2 + test_circle.s/2){
            return true
        }
    }
    return false
}

//many test circles
function hit_any_circles(hit_circles, test_circles, p){
    for (let i=0; i<hit_circles.length; i++){
        let hit_circ = hit_circles[i];
        for (let k=0; k<test_circles.length; k++){
            let test_circle = test_circles[k];
            let dist = p.dist(hit_circ.x, hit_circ.y, test_circle.x, test_circle.y);
            if (dist < hit_circ.s/2 + test_circle.s/2){
                return true
            }
        }
    }
    return false
}

//for viewing the map with lock groups and all areas revealed
function draw_demo_map(game_state, icons, fbo, p){
    let {territories} = game_state

    //fill in impassable
    territories.forEach( (ter) => {
        if (ter.impassable) {
            draw_territory.draw_impassable_fill(ter, fbo, p);
        }else{
            draw_territory.draw_demo_fill(ter, fbo, p);
        }
    })

    // outlines for all territories
    territories.forEach( (ter) => {
        draw_territory.draw_territory_border(ter, fbo, p);
    })

    //draw overseas connections
    for (let i = 0; i < territories.length; i++) {
        if (territories[i].oversea_hex) {
            draw_territory.draw_overseas_connection(territories[i], fbo, p);
        }
    }

    //territory names and icons
    territories.forEach( (ter) => {
        draw_territory.draw_territory_front({
            ter : ter,
            icons : icons,
            fbo : fbo,
            p: p
        });

        //testing screen wrap stuff
        ter.hexes.forEach((hex)=>{
            if (hex.on_map_border >= 0){
                fbo.fill(0)
                
                fbo.noStroke()
                let center = hex_manager.get_hex_center(hex)
                fbo.ellipse(center.x, center.y, 4,4)

                fbo.stroke(0)
                if(hex.on_map_border == 0)  fbo.line(center.x, center.y, center.x, center.y-30)
                if(hex.on_map_border == 1)  fbo.line(center.x, center.y, center.x+30, center.y)
                if(hex.on_map_border == 2)  fbo.line(center.x, center.y, center.x, center.y+30)
                if(hex.on_map_border == 3)  fbo.line( center.x, center.y,center.x-30, center.y)
            }
        })
    })

}

// set player_to_refresh to null to refresh the whole map
function refresh_base_map({territories, players, icons, fbo, p}) {
    if (p && p._renderer) {
        p.resetMatrix();
    }

    fbo.clear();

    //background/ocean
    fbo.colorMode(p.RGB)
    fbo.fill(ocean_col[0], ocean_col[1], ocean_col[2])
    fbo.noStroke()
    fbo.rect(0,0,fbo.width,fbo.height)

    //fill in locked
    draw_territories_color_fill({
        player:null,
        target_quality: "locked",
        territories: territories,
        fbo: fbo,
        p: p
    });
    //fill in unclaimed
    draw_territories_color_fill({
        player:null,
        target_quality: "unclaimed",
        territories: territories,
        fbo: fbo,
        p: p
    });
    
    //fill in player areas
    if (players && players.length) {
        for (let i = 0; i < players.length; i++) {
            draw_territories_color_fill({
                player:players[i],
                target_quality: "none",
                territories: territories,
                fbo: fbo,
                p: p
            });
        }
    }

    //apply patterns for players and other territories
    draw_stamp_patterns({
        players:players,
        icons: icons,
        fbo: fbo,
        p: p
    })

    //draw farms
    // territories.forEach((ter)=>{
    //     if (ter.is_farm){
    //         draw_territory.draw_farm_fill(ter, fbo, p)
    //     }
    // })

    //draw overseas connections
    for (let i = 0; i < territories.length; i++) {
        if (territories[i].oversea_hex) {
            draw_territory.draw_overseas_connection(territories[i], fbo, p);
        }
    }

    // outlines for all territories
    for (let i = 0; i < territories.length; i++) {
        draw_territory.draw_territory_border(territories[i], fbo, p);
    }
}

function refresh_highlight_fbo({fbo, highlighted_player, territories, p}) {
    fbo.clear();

    if (highlighted_player == null) {
        return;
    }

    for (let i = 0; i < territories.length; i++) {
        if (
            territories[i].player
            && territories[i].player.name == highlighted_player.name
        ) {
            draw_territory.draw_territory_highlight(territories[i], fbo, p);
        }
    }
}

function refresh_ui({show_orders, game_state, icons, fbo, ui_hit_circles, p}) {
    fbo.clear();

    const {territories} = game_state
    const {players} = game_state
    const {orders} = game_state

    
    //orders
    if (show_orders) {
        territories.forEach((ter) => {
            draw_territory.draw_territory_orders({
                ter,
                game_state,
                icons,
                fbo,
                ui_hit_circles,
                p
            })
        })

        //conflict markers
        territories.forEach((ter) => {
            draw_territory.draw_conflict_marker(ter, icons, fbo, ui_hit_circles, p)
        })
    
    }

    //armies
    territories.forEach( (ter) => {
        draw_territory.draw_territory_army(ter, icons, fbo, ui_hit_circles, p);
    })

    //dead armies
    territories.forEach((ter) => {
        draw_territory.draw_territory_failed_hold_orders({
            ter,
            game_state,
            icons,
            fbo,
            ui_hit_circles,
            p
        })
    })

    //territory names and icons
    territories.forEach( (ter) => {
        draw_territory.draw_territory_front({
            ter,
            icons,
            fbo,
            ui_hit_circles,
            p
        });
    })

    //a border to fade it into the background
    // fbo.noStroke();
    // let border_max_alpha = 150;
    // let border_min_size = 15;
    // let border_max_size = 60;
    // let border_range = 20;
    // for (let y=0; y<fbo.height; y++){
    //     fbo.fill(255, Math.random()*border_max_alpha);
    //     let size = border_min_size + Math.random() * border_max_size;
    //     let x = -border_range + Math.random()*border_range
    //     fbo.ellipse(x,y,size,size);
    //     fbo.ellipse(fbo.width-x,y,size,size);
    // }
    // for (let x=0; x<fbo.width; x++){
    //     fbo.fill(255, Math.random()*border_max_alpha);
    //     let size = border_min_size + Math.random() * border_max_size;
    //     let y = -border_range + Math.random()*border_range
    //     fbo.ellipse(x,y,size,size);
    //     fbo.ellipse(x,fbo.height-y,size,size);
    // }

}

//draws fill for all territories belonging to a specific player or posessing a specific quality
function draw_territories_color_fill({player, target_quality, territories, fbo, p}) {
    
    if (target_quality == "unclaimed") {
        fbo.fill(unclaimed_col[0],unclaimed_col[1],unclaimed_col[2]);
    }

    let player_fill_rgb = null
    if (player != null) {
        let this_sat = player_sat;
        if(player.stats.is_dead){
            this_sat = player_dead_sat;
        }

        let hsl_text = 'hsl('+Math.round(player.hue)+', '+this_sat+'%, '+player_bri+'%)'
        player.player_fill_rgb = p.color(hsl_text);

        let hsl_text_farm = 'hsl('+Math.round(player.hue)+', '+this_sat+'%, '+(player_bri-5)+'%)'
        player.player_farm_fill_rgb = p.color(hsl_text_farm);
    }



    fbo.noStroke();
    // go through all territories and get ones that match that player
    territories.forEach((ter)=>{
        const is_unclaimed = ter.player == null
        if (
            (ter.player != null && ter.player == player) ||
            (is_unclaimed && target_quality == "unclaimed")
        ){

            //if this is the player, set the fill based on if this is a farm
            if (player != null){
                fbo.fill(player.player_fill_rgb);
                if (ter.is_farm){
                    fbo.fill(player.player_farm_fill_rgb);
                }
            }

            // fill in the mask with the outline of this area
            fbo.beginShape();

            for (let i = 0; i < ter.perim.length; i++) {
                fbo.vertex(ter.perim[i].a.x, ter.perim[i].a.y);
            }
            fbo.endShape(p.CLOSE);
        }
    })

    // put the color mode back
    fbo.colorMode(p.RGB);
}

//applies player patterns
//TODO: make sure that different players cannot have the same hue
function draw_stamp_patterns({players, icons, fbo, p}){
    if (!players) {
        return false;
    }

    fbo.loadPixels()

    //patterns for players
    players.forEach( (player) =>{
        let pattern = icons.stamp_patterns[ player.pattern ];
        let farm_pattern = icons.stamp_farm_patterns[ player.pattern ];

        let col = player.player_fill_rgb.levels;

        pattern_fill({
            col: col,
            stamp: pattern,
            fbo: fbo,
            p: p
        });

        let farm_col = player.player_farm_fill_rgb.levels;
        pattern_fill({
            col: farm_col,
            stamp: farm_pattern,
            fbo: fbo,
            p: p
        });
    })

    //unclaimed territories 
    pattern_fill({
        col: unclaimed_col,
        stamp: icons.stamp_pattern_unclaimed,
        fbo: fbo,
        p: p
    });

    //ocean
    pattern_fill({
        col: ocean_col,
        stamp: icons.stamp_pattern_ocean,
        fbo: fbo,
        p: p
    });


    //actually refresh the image
    fbo.updatePixels()
}

//col is an array [r,g,b]
function pattern_fill({col, stamp, fbo, p}){
    stamp.loadPixels();
    for (let p=0; p<fbo.pixels.length; p+=4){
        let pixel = [fbo.pixels[p], fbo.pixels[p+1], fbo.pixels[p+2], fbo.pixels[p+3]]

        if (pixel[0]==col[0] && pixel[1]==col[1] && pixel[2]==col[2]){
            let x= (p/4)%fbo.width
            let y= Math.floor((p/4)/fbo.width)
            let x2 = x%stamp.width
            let y2 = y%stamp.height
            let k = (y2*stamp.width + x2)*4;    //array position

            //image should be greyscale, so we only need to check the R channel
            let val = stamp.pixels[k]-128;
            if (val != 0){ //make sure there is alpha in the pattern

                //apply the effect
                for (let i=0; i<3; i++){
                    //if(Math.random()<0.01)  console.log("val "+val)
                    fbo.pixels[p+i] += Math.floor(val);
                }
            }
        }
    }
}

//this doesn't work right now, but I would like it to eventually rpleace APIFlash somehow
function save_map_image(fbos) {
    // TODO: need to boil the FBOs down to one image and make sure it is sized to not have a ton of empty space

    // get the byte data of the image
    // this comes form p5.prototype.saveCanvas = function() on line 17509 of p5.js
    // some other info from p5.prototype.save = function (object, _filename, _options) on line 20400

    console.log(`fbo size: ${fbos.base.width} x ${fbos.base.height}`);

    console.log("saving");
    const mimeType = "image/png";
    const imageData = fbos.base.elt.toDataURL(mimeType);
    console.log("image data:");
    console.log(imageData);

    /*
    $.post(
        "https://us-central1-digiplomacy-8046e.cloudfunctions.net/app/image_test",
        { room_id: _room_id, byte_string: imageData },
        function (data) {
            console.log(data);
        }
    );
    */

    // uncomment this to just save the PNG
    //p.save(fbo_test, "test.png");
}

function set_perim_line(ter) {
    ter.perim = [];

    ter.border_lines = [];

    const unordered = [];
    for (let h = 0; h < ter.hexes.length; h++) {
        const hex = ter.hexes[h];
        for (let i = 0; i < hex.neighbors_full.length; i++) {
            const neighbor = hex.neighbors_full[i];

            if (
                neighbor == null ||
                neighbor.territory == null ||
                neighbor.territory.id != ter.id
            ) {
                const line = {
                    a : hex.points[i],
                    b : hex.points[(i + 1) % hex.points.length]
                }

                unordered.push(line);

                if (ter.owner != "NONE") {
                    let add_border_line = false;
                    if (neighbor == null) {
                        add_border_line = true
                    } else if (neighbor.territory == null) {
                        add_border_line = true
                    } else if (neighbor.territory.owner != ter.owner) {
                        add_border_line = true
                    }

                    if (add_border_line){
                        
                        let temp={
                            a:{
                                x:line.a.x,
                                y:line.a.y
                            },
                            b:{
                                x:line.b.x,
                                y:line.b.y
                            }
                        }

                        const center = hex_manager.get_hex_center(hex)
                        let prc = 0.85
                        temp.a.x = temp.a.x*prc + center.x*(1.0-prc)
                        temp.a.y = temp.a.y*prc + center.y*(1.0-prc)
                        temp.b.x = temp.b.x*prc + center.x*(1.0-prc)
                        temp.b.y = temp.b.y*prc + center.y*(1.0-prc)

                        ter.border_lines.push(temp);
                    }
                }
            }
        }
    }

    // try to put these shits in order
    ter.perim.push(unordered.pop());
    while (unordered.length > 0) {
        const last = ter.perim[ter.perim.length - 1];
        let found = false;
        for (let i = 0; i < unordered.length; i++) {
            if (unordered[i].a == last.b) {
                ter.perim.push(unordered[i]);
                unordered.splice(i, 1);
                found = true;
                break;
            }
        }
        if (!found) {
            console.log(`BAD: we could not find it. ${unordered.length} left`);
            break;
        }
    }

    set_center_hex(ter);
}

function set_center_hex(ter) {
    ter.center_hex = null;
    let max_val = 0;

    for (let i = 0; i < ter.hexes.length; i++) {
        const hex = ter.hexes[i];
        let avg_dist = 0;
        let high_val = 0;
        let low_val = 99;
        for (let d = 0; d < 6; d++) {
            const dist = get_dist_matching(hex, d, 0);
            if (dist > high_val) high_val = dist;
            if (dist < low_val) low_val = dist;
            avg_dist += dist;
        }
        avg_dist /= 6;

        const penalty = (high_val - low_val) * 0.5;
        avg_dist -= penalty;

        if (avg_dist > max_val) {
            ter.center_hex = hex;
            max_val = avg_dist;
        }
    }
}

function get_dist_matching(hex, dir, depth) {
    if (hex.neighbors_full[dir] == null) {
        return depth;
    }
    if (hex.neighbors_full[dir].territory != hex.territory) {
        return depth;
    }
    return get_dist_matching(hex.neighbors_full[dir], dir, depth + 1);
}


export default {
    draw_map,
    create_fbos,
    reset_map_canvas,
    refresh_base_map,
    refresh_highlight_fbo,
    refresh_ui,
    draw_territories_color_fill,
    save_map_image,
    set_perim_line,
    set_center_hex,
    get_dist_matching
}


