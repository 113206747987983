//TODO: This whole file should be removed & replaced with react stuff

import sketch from "./sketch.js";
import ui_bridge from "./ui_bridge.js";

// NOTE: Temporarily commented out because it's demanding a div in the sidebar I'm removing -- Nick
function print_orders({ game_state, mouse_info }) {
    //this function is not used
}

function update_orders_css({ game_state, grid_info, mouse_info, p }) {
    let { 
        // orders, 
        players 
    } = game_state;
    
    const { setHighlightedPlayer } = ui_bridge.getGameState();
    
    let {
        // cur_moused_over_player,
        highlighted_player,
        // prev_highlighted_player,
        // highlighted_territory,
        // prev_highlighted_territory,
    } = mouse_info;

    
}

function should_order_get_highlight(order, highlighted_territory) {
    if (order.territory == highlighted_territory) {
        return true;
    }

    if (order.move_target) {
        if (order.move_target == highlighted_territory) {
            return true;
        }
    }

    if (order.supported_territory) {
        if (order.supported_territory == highlighted_territory) {
            return true;
        }
    }

    if (order.support_source) {
        if (order.support_source == highlighted_territory) {
            return true;
        }
    }

    if (order.support_target) {
        if (order.support_target == highlighted_territory) {
            return true;
        }
    }

    return false;
}

export default {
    print_orders,
    update_orders_css,
    should_order_get_highlight,
};
